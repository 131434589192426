import { useEffect, useState } from "react";

import { useLocation, useNavigate } from "react-router-dom";

import {
	Box,
	Button,
	Checkbox,
	Container,
	Divider,
	FormControl,
	FormControlLabel,
	FormGroup,
	FormLabel,
	Grid2,
	IconButton,
	ListItemText,
	MenuItem,
	OutlinedInput,
	Paper,
	Select,
	Stack,
	TextField,
	ToggleButton,
	ToggleButtonGroup,
	Typography,
} from "@mui/material";

import DataGrid from "../../../components/datagrid";

import api from "../../../services/api";

import CircularProgress from "../../../components/loading/CircularProgress";

import { Icon } from "@iconify/react/dist/iconify.js";

import dayjs from "dayjs";

import Dropdown from "../../../components/ui/Dropdown";

import Loading from "../../../components/ui/Loading";

import { useTranslation } from "react-i18next";

export default function ItemsExport() {
	const { t } = useTranslation();

	const navigate = useNavigate();

	const [loading, setLoading] = useState(false);

	const [loadingProgress, setLoadingProgress] = useState("");

	const props = useLocation();

	const { propsList, propsFilter, infoNamesList = [] } = props?.state || {};

	const [items, setItems] = useState([]);

	const [itemsDetails, setItemsDetails] = useState([]);

	const [selectedRows, setSelectedRows] = useState([]);

	const [quantidadeCategoria, setQuantidadeCategoria] = useState([]);

	// Listas - Filtros
	const [categorias, setCategorias] = useState(propsList?.categorias || []);

	const [niveis1, setNiveis1] = useState(propsList?.niveis1 || []);

	const [niveis2, setNiveis2] = useState(propsList?.niveis2 || []);

	const [niveis3, setNiveis3] = useState(propsList?.niveis3 || []);

	const [niveis4, setNiveis4] = useState(propsList?.niveis4 || []);

	// @ Filtros
	const { categoria, nivel1, nivel2, nivel3, nivel4, parceiro, tag, status, data } = propsFilter || {};

	const [filterByCategoria, setFilterByCategoria] = useState(categoria || "");

	const [filterByNivel1, setFilterByNivel1] = useState(nivel1 || "");

	const [filterByNivel2, setFilterByNivel2] = useState(nivel2 || "");

	const [filterByNivel3, setFilterByNivel3] = useState(nivel3 || "");

	const [filterByNivel4, setFilterByNivel4] = useState(nivel4 || "");

	const [filterByParceiro, setFilterByParceiro] = useState(parceiro || "");

	const [filterByTag, setFilterByTag] = useState(tag || "");

	const [filterByStatus, setFilterByStatus] = useState(status || "");

	const [filterByObservacao, setFilterByObservacao] = useState("");

	const [filterDtCriacaoStart, setFilterDtCriacaoStart] = useState(data?.start || "");

	const [filterDtCriacaoEnd, setFilterDtCriacaoEnd] = useState(data?.end || "");

	// Ordenação
	const [sortOrder, setSortOrder] = useState("1");

	const [sortBy, setSortBy] = useState("");

	// Agrupar
	const [aggroupByCategory, setAggroupByCategory] = useState();

	const [aggroupByCategoryList, setAggroupByCategoryList] = useState([]);

	useEffect(() => {
		getItems();
	}, []);

	async function getItems() {
		setLoading(true);

		setLoadingProgress(10);

		let lista = [];
		let offset = 0;
		const limit = 1000;
		let total = 0;
		let progress = 0;
		try {
			const firstResponse = await api.get(
				`/itensPage/${sessionStorage.getItem("idConta")}/*/${filterByCategoria || "*"}/${filterByTag || "*"}/${filterByStatus || "*"}/*/*/*/*/*/${filterByNivel1 || "*"}/${filterByNivel2 || "*"}/${filterByNivel3 || "*"}/${filterByNivel4 || "*"}/*/${offset}?limit=${limit || 1000}${filterByParceiro ? `&id_parceiro=${filterByParceiro}` : ""}${sortBy ? `&orderField=${sortBy}&order=${sortOrder || "1"}` : ""}${filterDtCriacaoStart ? `&dt_criacao_inicial=${dayjs(filterDtCriacaoStart).format("YYYY-MM-DD")}` : ""}${filterDtCriacaoEnd ? `&dt_criacao_final=${dayjs(filterDtCriacaoEnd).format("YYYY-MM-DD")}` : ""}`
			);
			total = firstResponse.data.find((item) => item.total_reg)?.total_reg || 0;
			lista.push(...firstResponse.data.filter((item) => !item.total_reg));
			offset += limit;
			progress = Math.round((lista.length / total) * 100);
			setLoadingProgress(progress);

			// Contagem lote/quantidade por categoria
			const quantCategoria = firstResponse.data.find((i) => i?.total_lote_categoria);
			setQuantidadeCategoria(quantCategoria?.total_lote_categoria);

			setItemsDetails(firstResponse?.data?.find((i) => i?.total_lote_categoria));

			while (offset < total) {
				const response = await api.get(
					`/itensPage/${sessionStorage.getItem("idConta")}/*/${filterByCategoria || "*"}/${filterByTag || "*"}/${filterByStatus || "*"}/*/*/*/*/*/${filterByNivel1 || "*"}/${filterByNivel2 || "*"}/${filterByNivel3 || "*"}/${filterByNivel4 || "*"}/*/${offset}?limit=${limit || 1000}${filterByParceiro ? `&id_parceiro=${filterByParceiro}` : ""}${sortBy ? `&orderField=${sortBy}&order=${sortOrder || "1"}` : ""}${filterDtCriacaoStart ? `&dt_criacao_inicial=${dayjs(filterDtCriacaoStart).format("YYYY-MM-DD")}` : ""}${filterDtCriacaoEnd ? `&dt_criacao_final=${dayjs(filterDtCriacaoEnd).format("YYYY-MM-DD")}` : ""}`
				);
				lista.push(...response.data.filter((item) => !item.total_reg));
				offset += limit;
				progress = Math.round((lista.length / total) * 100);
				setLoadingProgress(progress);
			}
		} catch (error) {
			console.error("Erro ao buscar lista de exportação", error);
		} finally {
			setLoadingProgress(100);
			setLoading(false);
		}

		if (filterByObservacao) {
			lista = lista.filter((i) => i.observacao.includes(filterByObservacao));
		}

		const rows = lista
			.filter((i) => i?._id)
			.map((list, index) => ({
				...list,
				id: index + 1,
			}));

		setSelectedRows(rows.map((i) => i.id));
		setItems(rows);
	}

	useEffect(() => {
		function categoriaGet() {
			api.get(`/categoria/${sessionStorage.getItem("idConta")}/*/*/*/*`, {}).then((response) => {
				setCategorias(response.data.filter((i) => i?._id));
			});
		}

		if (!categorias?.length) {
			categoriaGet();
		}
	}, []);

	useEffect(() => {
		function nivel1Get() {
			api.get(`/nivel_loc1/${sessionStorage.getItem("idConta")}/*/*/*/*`, {}).then((response) => {
				setNiveis1(response.data);
			});
		}

		if (!niveis1?.length) {
			nivel1Get();
		}
	}, []);

	useEffect(() => {
		function nivel2Get(nivel1) {
			if (nivel1 && nivel1 !== "*") {
				const idConta = sessionStorage.getItem("idConta");
				const nivel1Path = nivel1 || "*";

				api.get(`/nivel_loc2/${idConta}/${nivel1Path}/*/*/*/*`).then((response) => {
					setNiveis2(response.data);
					if (!response?.data.find((item) => item._id === filterByNivel2)) {
						setFilterByNivel2();
						setFilterByNivel3();
						setFilterByNivel4();
					}
				});
			} else {
				setNiveis2([]);
				setFilterByNivel2();
			}
		}

		nivel2Get(filterByNivel1);
	}, [filterByNivel1]);

	useEffect(() => {
		function nivel3Get(nivel2) {
			if (nivel2 && nivel2 !== "*") {
				const idConta = sessionStorage.getItem("idConta");
				const nivel2Path = nivel2 || "*";

				api
					.get(`/nivel_loc3/${idConta}/${nivel2Path}/*/*/*/*`)
					.then((response) => {
						setNiveis3(response.data);
						if (!response?.data.find((item) => item._id == filterByNivel2)) {
							setFilterByNivel3();
							setFilterByNivel4();
						}
					})
					.catch((error) => {
						console.error("Error fetching data:", error);
						// Optional: handle error state, show user feedback, etc.
					});
			} else {
				setNiveis3([]);
				setFilterByNivel3();
			}
		}

		nivel3Get(filterByNivel2);
	}, [filterByNivel2]);

	useEffect(() => {
		function nivel4Get(nivel3) {
			if (nivel3 && nivel3 !== "*") {
				const idConta = sessionStorage.getItem("idConta");
				const nivel3Path = nivel3 || "*";

				api.get(`/nivel_loc4/${idConta}/${nivel3Path}/*/*/*/*`).then((response) => {
					setNiveis4(response.data);
				});
			} else {
				setNiveis4([]);
				setFilterByNivel4();
			}
		}

		nivel4Get(filterByNivel3);
	}, [filterByNivel3]);

	useEffect(() => {
		function handleSort() {
			setLoading(true);
			try {
				if (sortBy?.includes("nivel")) {
					const sortedList = [...items].sort((a, b) =>
						sortOrder == "1"
							? a[sortBy]?.descricao.localeCompare(b[sortBy]?.descricao)
							: b[sortBy]?.descricao.localeCompare(a[sortBy]?.descricao)
					);
					setItems(sortedList);
				} else {
					getItems();
				}
			} finally {
				setLoading(false);
			}
		}

		if (items?.length) {
			handleSort();
		}
	}, [sortOrder, sortBy]);

	useEffect(() => {
		async function handleAggroup() {
			let groupedItems = items.map((item, i) => {
				const categoria = item?.id_categoria?.descricao;

				return {
					...item,
					qtde_itens: quantidadeCategoria?.find((cat) => cat?.categoria === categoria)?.qtde_itens || 0,
					qtde_por_categoria: quantidadeCategoria?.find((cat) => cat?.categoria === categoria)?.qtde_por_categoria || 0,
				};
			});

			groupedItems = groupedItems.reduce((acc, item) => {
				const categoria = item?.id_categoria?.descricao;
				if (!acc[categoria]) {
					acc[categoria] = [];
				}
				acc[categoria] = item;
				return acc;
			}, {});

			const formatedItems = Object.values(groupedItems);

			setAggroupByCategoryList(formatedItems.map((items, index) => ({ ...items, id: index + 1 })));
		}

		if (aggroupByCategory) {
			handleAggroup();
		}
	}, [aggroupByCategory]);

	const headersCSV = [
		"ID INOVA",
		"TAG_VINC",
		"COD_EXTERNO",
		"STATUS",
		"TAG",
		"CATEGORIA",
		"EAN",
		(infoNamesList[0] && infoNamesList[0] != "" ? infoNamesList[0] : "Inf. Complentar 1").toUpperCase(),
		(infoNamesList[1] && infoNamesList[1] != "" ? infoNamesList[1] : "Inf. Complentar 2").toUpperCase(),
		(infoNamesList[2] && infoNamesList[2] != "" ? infoNamesList[2] : "Inf. Complentar 3").toUpperCase(),
		(infoNamesList[3] && infoNamesList[3] != "" ? infoNamesList[3] : "Inf. Complentar 4").toUpperCase(),
		(infoNamesList[4] && infoNamesList[4] != "" ? infoNamesList[4] : "Inf. Complentar 5").toUpperCase(),
		(infoNamesList[5] && infoNamesList[5] != "" ? infoNamesList[5] : "Inf. Complentar 6").toUpperCase(),
		(infoNamesList[6] && infoNamesList[6] != "" ? infoNamesList[6] : "Inf. Complentar 7").toUpperCase(),
		(infoNamesList[7] && infoNamesList[7] != "" ? infoNamesList[7] : "Inf. Complentar 8").toUpperCase(),
		(infoNamesList[8] && infoNamesList[8] != "" ? infoNamesList[8] : "Inf. Complentar 9").toUpperCase(),
		(infoNamesList[9] && infoNamesList[9] != "" ? infoNamesList[9] : "Inf. Complentar 10").toUpperCase(),
		"PARCEIRO",
		"NIVEL_1",
		"NIVEL_2",
		"NIVEL_3",
		"NIVEL_4",
		"OBSERVAÇÃO",
		"VALOR",
		"UP_1",
		"UP_2",
		"UP_3",
		"UP_4",
		"UP_5",
		"DT_CRIAÇÃO",
		"DT_ALTERAÇÃO",
		"USUÁRIO",
		"FOTO",
		"MARCA",
		"MODELO",
		"QUANTIDADE DE ITENS POR CATEGORIA",
		"TOTAL (LOTE)",
		"ÚLTIMO REGISTRO",
	];

	function handleExport(format) {
		const selectedItems = aggroupByCategory ? aggroupByCategoryList : items;
		const list = selectedItems
			.filter((_, i) => {
				return selectedRows.includes(i + 1);
			})
			.map((item) => {
				const modelo = item?.id_modelo
					? item?.id_marca?.modelo.find((modelo) => modelo?._id === item?.id_modelo)?.descricao
					: "";

				const categoria = item?.id_categoria?.descricao ?? "";
				const categoriaDetails = quantidadeCategoria?.find((cat) => cat?.categoria === categoria) ?? {};
				const { qtde_itens = 0, qtde_por_categoria = 0 } = categoriaDetails;

				return [
					item?._id,
					item?.tag_vinculado || "",
					item?.id_externo || "",
					item?.ativo || "",
					item?.tag || "",
					item?.id_categoria?.descricao || "",
					item?.ean || "",
					item?.inf_compl_1 || "",
					item?.inf_compl_2 || "",
					item?.inf_compl_3 || "",
					item?.inf_compl_4 || "",
					item?.inf_compl_5 || "",
					item?.inf_compl_6 || "",
					item?.inf_compl_7 || "",
					item?.inf_compl_8 || "",
					item?.inf_compl_9 || "",
					item?.inf_compl_10 || "",
					item?.id_parceiro?.nome || "",
					item?.id_nivel_loc_1?.descricao || "",
					item?.id_nivel_loc_2?.descricao || "",
					item?.id_nivel_loc_3?.descricao || "",
					item?.id_nivel_loc_4?.descricao || "",
					item?.observacao || "",
					item?.valor?.toString() || "0",
					item?.up_1 || "",
					item?.up_2 || "",
					item?.up_3 || "",
					item?.up_4 || "",
					item?.up_5 || "",
					item?.dt_criacao ? dayjs(item?.dt_criacao).format("DD/MM/YYYY HH:mm") : "",
					item?.dt_alteracao || "",
					item?.id_usuario?.nome || "",
					item?.foto ? `${process.env.REACT_APP_API_URL}/${item?.foto}` : "Sem foto",
					item?.id_marca?.marca || "",
					modelo || "",
					qtde_itens ? qtde_itens.toString() : "",
					qtde_por_categoria ? qtde_por_categoria.toString() : "",
					item.id_registro ? dayjs(item?.id_registro?.dt_registro).format("DD/MM/YYYY HH:mm") : "",
				];
			});

		const data = { headers: headersCSV, list: list, module: "itens", type: format, redirectTo: "/items" };
		navigate("/export", { state: { info: data } });
	}

	const columns = [
		{ field: "id", headerName: "ID" },
		{
			field: "tag",
			headerName: "Item",
			width: 300,
			renderCell: (params) => (
				<>
					<Stack direction="column">
						<Typography sx={{ fontWeight: 600 }}>{params.row?.id_categoria?.descricao}</Typography>
						<Typography variant="subtitle">{params.row?.tag}</Typography>
					</Stack>
				</>
			),
		},
		{
			field: "inf_compl_1",
			headerName: t("common.info"),
			width: 400,
			renderCell: (params) => (
				<>
					<Stack direction="column">
						<Typography>{params.row?.inf_compl_1}</Typography>
						<Typography>{params.row?.inf_compl_2}</Typography>
						<Typography>{params.row?.inf_compl_3}</Typography>
						<Typography>{params.row?.inf_compl_4}</Typography>
						<Typography>{params.row?.inf_compl_5}</Typography>
						<Typography>{params.row?.inf_compl_6}</Typography>
						<Typography>{params.row?.inf_compl_7}</Typography>
						<Typography>{params.row?.inf_compl_8}</Typography>
						<Typography>{params.row?.inf_compl_9}</Typography>
						<Typography>{params.row?.inf_compl_10}</Typography>
					</Stack>
				</>
			),
		},
		{
			field: "observacao",
			headerName: t("common.observation"),
			width: 110,
		},
		{
			field: "id_nivel_loc_1",
			headerName: t("common.level") + " 1",

			valueGetter: (value, row) => row?.id_nivel_loc_1?.descricao,
		},
		{
			field: "id_nivel_loc_2",
			headerName: t("common.level") + " 2",
			valueGetter: (value, row) => row?.id_nivel_loc_2?.descricao,
		},
		{
			field: "id_nivel_loc_3",
			headerName: t("common.level") + " 3",
			valueGetter: (value, row) => row?.id_nivel_loc_3?.descricao,
		},
		{
			field: "id_nivel_loc_4",
			headerName: t("common.level") + " 4",
			valueGetter: (value, row) => row?.id_nivel_loc_4?.descricao,
		},
	];

	return (
		<>
			<Container>
				<Stack spacing={3}>
					<Stack direction={{ xs: "column", md: "row" }} justifyContent="space-between" spacing={3}>
						<Typography variant="h1" textAlign="start">
							{t("actions.export")}
						</Typography>

						<Stack direction={{ xs: "column", md: "row" }} spacing={1}>
							<Button
								variant="outlined"
								color="dark"
								startIcon={<Icon icon="vscode-icons:file-type-pdf2" />}
								onClick={() => {
									handleExport("PDF");
								}}
							>
								{t("actions.export")} PDF
							</Button>
							<Button
								variant="outlined"
								color="dark"
								startIcon={<Icon icon="vscode-icons:file-type-excel" />}
								onClick={() => {
									handleExport("CSV");
								}}
							>
								{t("actions.export")} CSV
							</Button>
							<Button
								variant="outlined"
								color="dark"
								onClick={() => {
									navigate("/items");
								}}
							>
								{t("actions.exit")}
							</Button>
						</Stack>
					</Stack>

					<Stack as={Paper} spacing={3} sx={{ borderRadius: 3, p: 3 }}>
						<Grid2 container spacing={3} sx={{ p: 3, borderRadius: 3, backgroundColor: "primary.lighter" }}>
							<Grid2 size={{ xs: 12, md: 3 }}>
								<FormControl fullWidth>
									<FormLabel>Tag</FormLabel>
									<TextField
										size="small"
										value={filterByTag}
										onChange={(e) => {
											setFilterByTag(e.target.value);
										}}
									/>
								</FormControl>
							</Grid2>
							<Grid2 size={{ xs: 12, md: 3 }}>
								<FormControl fullWidth>
									<FormLabel>Status</FormLabel>
									<Select
										size="small"
										value={filterByStatus}
										onChange={(e) => {
											setFilterByStatus(e.target.value);
										}}
									>
										<MenuItem value={null}>--</MenuItem>
										<MenuItem value={"0"}>{t("common.inactive")}</MenuItem>
										<MenuItem value={"1"}>{t("common.active")}</MenuItem>
										<MenuItem value={"2"}>{t("common.disposal")}</MenuItem>
										<MenuItem value={"3"}>{t("common.deprecated")}</MenuItem>
									</Select>
								</FormControl>
							</Grid2>
							<Grid2 size={{ xs: 12, md: 3 }}>
								<FormControl fullWidth>
									<FormLabel>{t("common.categories")}</FormLabel>
									<Select
										size="small"
										value={filterByCategoria}
										onChange={(e) => {
											setFilterByCategoria(e.target.value);
										}}
									>
										{categorias.map((c) => (
											<MenuItem value={c?._id}>{c.descricao}</MenuItem>
										))}
									</Select>
								</FormControl>
							</Grid2>

							<Grid2 size={{ xs: 12, md: 3 }}>
								<FormControl fullWidth>
									<FormLabel>{t("common.observation")}</FormLabel>
									<TextField
										size="small"
										value={filterByObservacao}
										onChange={(e) => {
											setFilterByObservacao(e.target.value);
										}}
									/>
								</FormControl>
							</Grid2>
							<Grid2 size={{ xs: 12, md: 3 }}>
								<FormControl fullWidth>
									<FormLabel>Nivel 1</FormLabel>
									<Select
										size="small"
										value={filterByNivel1}
										onChange={(e) => {
											setFilterByNivel1(e.target.value);
										}}
									>
										<MenuItem value={null}>--</MenuItem>
										{niveis1.map((item) => (
											<MenuItem value={item?._id}>{item.descricao}</MenuItem>
										))}
									</Select>
								</FormControl>
							</Grid2>
							<Grid2 size={{ xs: 12, md: 3 }}>
								<FormControl fullWidth>
									<FormLabel>Nivel 2</FormLabel>
									<Select
										size="small"
										value={filterByNivel2}
										onChange={(e) => {
											setFilterByNivel2(e.target.value);
										}}
									>
										<MenuItem value={null}>--</MenuItem>
										{niveis2.map((item) => (
											<MenuItem value={item?._id}>{item.descricao}</MenuItem>
										))}
									</Select>
								</FormControl>
							</Grid2>
							<Grid2 size={{ xs: 12, md: 3 }}>
								<FormControl fullWidth>
									<FormLabel>Nivel 3</FormLabel>
									<Select
										size="small"
										value={filterByNivel3}
										onChange={(e) => {
											setFilterByNivel3(e.target.value);
										}}
									>
										<MenuItem value={null}>--</MenuItem>
										{niveis3.map((item) => (
											<MenuItem value={item?._id}>{item.descricao}</MenuItem>
										))}
									</Select>
								</FormControl>
							</Grid2>
							<Grid2 size={{ xs: 12, md: 3 }}>
								<FormControl fullWidth>
									<FormLabel>Nivel 4</FormLabel>
									<Select
										size="small"
										value={filterByNivel4}
										onChange={(e) => {
											setFilterByNivel4(e.target.value);
										}}
									>
										<MenuItem value={null}>--</MenuItem>
										{niveis4.map((item) => (
											<MenuItem value={item?._id}>{item.descricao}</MenuItem>
										))}
									</Select>
								</FormControl>
							</Grid2>
							<Grid2 size={12}>
								<FormLabel>{t("common.created_at")}</FormLabel>
								<Stack direction="row" alignItems="center" spacing={1}>
									<TextField
										size="small"
										type="date"
										value={filterDtCriacaoStart || ""}
										onChange={(e) => {
											setFilterDtCriacaoStart(e.target.value);
										}}
										max={dayjs(filterDtCriacaoEnd).format("YYYY-MM-DD") || ""}
									/>
									<div>-</div>
									<TextField
										size="small"
										type="date"
										value={filterDtCriacaoEnd || ""}
										onChange={(e) => {
											setFilterDtCriacaoEnd(e.target.value);
										}}
										min={dayjs(filterDtCriacaoStart).format("YYYY-MM-DD") || ""}
									/>
								</Stack>
							</Grid2>
							<Grid2 size={6}>
								<FormGroup>
									<FormControlLabel
										control={
											<Checkbox
												checked={aggroupByCategory}
												onChange={(event) => {
													setAggroupByCategory(event.target.checked);
												}}
												inputProps={{ "aria-label": "controlled" }}
											/>
										}
										label="Agrupar por categoria"
									/>
								</FormGroup>
							</Grid2>

							<Grid2 size={6}>
								<Stack direction="row" justifyContent="end">
									<Button
										variant="contained"
										onClick={() => {
											getItems();
										}}
									>
										{t("actions.search")}
									</Button>
								</Stack>
							</Grid2>
						</Grid2>
						{loadingProgress && loading && !items.length ? (
							<Stack alignItems="center" justifyContent="center">
								<CircularProgress progress={loadingProgress || 0} />
							</Stack>
						) : (
							<>
								<Stack direction="row" justifyContent="space-between" alignItems="center" spacing={1}>
									<Stack
										direction="row"
										spacing={0.5}
										sx={{ backgroundColor: "grey.300", color: "grey.600", p: 1, borderRadius: 3 }}
									>
										<Typography variant="subtitle2">
											{aggroupByCategory ? aggroupByCategoryList?.length : itemsDetails?.total_reg}
										</Typography>
										<Typography variant="subtitle2">{t("common.items")}</Typography>
									</Stack>
									<Dropdown text={t("actions.sort")} color="dark" startIcon="gg:sort-az">
										<Stack sx={{ minWidth: 300, p: 3 }} spacing={1}>
											<Typography variant="h6">{t("actions.sort")}</Typography>
											<Divider sx={{ color: "divider" }} />
											<ToggleButtonGroup
												color="standard"
												size="small"
												exclusive
												aria-label="sortOrder"
												value={sortOrder}
												onChange={(e) => {
													setSortOrder(e.target.value);
												}}
											>
												<ToggleButton value="1">
													<Icon icon="bi:sort-down" style={{ marginRight: 8 }} /> Crescente
												</ToggleButton>
												<ToggleButton value="-1">
													<Icon icon="bi:sort-up" style={{ marginRight: 8 }} />
													Decrescente
												</ToggleButton>
											</ToggleButtonGroup>
											<Typography variant="subtitle">{t("actions.sort_by")}</Typography>
											<ToggleButtonGroup
												color="primary"
												size="small"
												orientation="vertical"
												value={sortBy}
												exclusive
												onChange={(e) => {
													setSortBy(e.target.value);
												}}
											>
												<ToggleButton value="id_categoria" sx={{ textAlign: "center" }}>
													{t("common.categories")}
												</ToggleButton>
												<ToggleButton value="observacao" sx={{ textAlign: "center" }}>
													{t("common.observation")}
												</ToggleButton>
												<ToggleButton value="id_nivel_loc_1" sx={{ textAlign: "center" }}>
													{t("common.level")} 1
												</ToggleButton>
												<ToggleButton value="id_nivel_loc_2" sx={{ textAlign: "center" }}>
													{t("common.level")} 2
												</ToggleButton>
												<ToggleButton value="id_nivel_loc_3" sx={{ textAlign: "center" }}>
													{t("common.level")} 3
												</ToggleButton>
												<ToggleButton value="id_nivel_loc_4" sx={{ textAlign: "center" }}>
													{t("common.level")} 4
												</ToggleButton>
												<ToggleButton value="dt_criacao" sx={{ textAlign: "center" }}>
													{t("common.created_at")}
												</ToggleButton>
												<ToggleButton value="dt_alteracao" sx={{ textAlign: "center" }}>
													{t("common.edited_at")}
												</ToggleButton>
											</ToggleButtonGroup>
										</Stack>
									</Dropdown>
								</Stack>
								<Box>
									<DataGrid
										header={columns}
										tableData={aggroupByCategory ? aggroupByCategoryList : items}
										checkboxSelection={true}
										onClick={(e) => {
											//navigate(`details/${e.id_conta}`, { state: { usuario: e } });
										}}
										defaultSelected={true}
										selected={selectedRows}
										onSelect={(index) => {
											setSelectedRows(index);
										}}
									/>
								</Box>
							</>
						)}
					</Stack>
				</Stack>
			</Container>
		</>
	);
}
