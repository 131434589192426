import { Backdrop, CircularProgress, Stack, Typography } from "@mui/material";

//----------------------------------------------------------------

export default function Loading({ show, text }) {
	return (
		<>
			{show ? (
				<>
					<Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={show}>
						<Stack direction="column" alignItems="center" spacing={3}>
							<CircularProgress color="inherit" />
							{text && <Typography>{text}</Typography>}
						</Stack>
					</Backdrop>
				</>
			) : (
				""
			)}
		</>
	);
}
