import { Icon } from "@iconify/react/dist/iconify.js";
import { GoogleMap, useLoadScript, Marker } from "@react-google-maps/api";
import { useState, useEffect } from "react"; // Adicionado para lidar com redimensionamento

const libraries = ["places"];

export default function Maps({ latitude, longitude, items = [], width, height, zoom, icon }) {
	const [markerZoom, setMarkerZoom] = useState(zoom);
	const [mapSize, setMapSize] = useState({
		width: width || "100%",
		height: height || "60vh", // Usando viewport height para ser responsivo
	});

	const { isLoaded, loadError } = useLoadScript({
		googleMapsApiKey: process.env.REACT_APP_API_G_KEY,
		libraries,
	});

	// Ajustar tamanho do mapa quando a janela for redimensionada
	useEffect(() => {
		const handleResize = () => {
			setMapSize({
				width: width || "100%",
				height: height || "60vh",
			});
		};

		window.addEventListener("resize", handleResize);
		return () => window.removeEventListener("resize", handleResize);
	}, [width, height]);

	if (loadError) return <p>Erro ao carregar o mapa</p>;
	if (!isLoaded) return <p>Carregando mapa...</p>;

	const renderItems = items?.length ? items : [{ latitude, longitude }];

	console.log(renderItems);
	return (
		<div style={{ width: "100%", overflow: "hidden" }}>
			<GoogleMap
				className="mapStyles"
				mapContainerStyle={mapSize}
				zoom={markerZoom || 18}
				center={{ lat: Number(renderItems[0]?.latitude), lng: Number(renderItems[0]?.longitude) }}
				options={{
					gestureHandling: "cooperative", // Melhora a usabilidade em mobile
				}}
			>
				{renderItems
					.filter((item) => item.longitude && item.latitude)
					.map((item, index) => (
						<Marker
							key={index}
							position={{
								lat: Number(item.latitude),
								lng: Number(item.longitude),
							}}
							onClick={() => {
								setMarkerZoom(20);
							}}
							icon={
								icon
									? {
											url: icon,
											scaledSize: new window.google.maps.Size(32, 30),
										}
									: undefined
							}
						/>
					))}
			</GoogleMap>
		</div>
	);
}
