import { useEffect, useState } from "react";

import {
	Button,
	Chip,
	Container,
	FormControl,
	FormLabel,
	Grid2,
	InputLabel,
	MenuItem,
	Paper,
	Select,
	Stack,
	Typography,
} from "@mui/material";

import Import from "../../../components/Import/Import";

import Datagrid from "../../../components/datagrid";

import { t } from "i18next";

import api from "../../../services/api";
import { green, grey, red } from "@mui/material/colors";
import Loading from "../../../components/ui/Loading";
import Alert from "../../../components/ui/Alert";

const columns = [
	{
		field: "id",
		headerName: " ",
		width: 30,
	},
	{
		field: "tag",
		headerName: "Item",
		width: 300,
		renderCell: (params) => (
			<Stack>
				<Typography variant="subtitle2">{params?.row?.descricao}</Typography>
				<Typography variant="subtitle">{params?.row?.tag}</Typography>
			</Stack>
		),
	},
	{
		field: "status",
		headerName: "Status",
		width: 200,
		renderCell: (params) => (
			<Button
				color={
					params.row?.status.includes("Erro")
						? "error"
						: params.row?.status == "found"
							? "success"
							: params.row?.status == "excess"
								? "inherit"
								: params.row?.status == "other_locate"
									? "warning"
									: ""
				}
			>
				{t("common." + params.row?.status)}
			</Button>
		),
	},
	{
		field: "leitor",
		headerName: "Leitor",
		width: 100,
	},
];

export default function InventoriesImport() {
	const [loading, setLoading] = useState(false);

	const [message, setMessage] = useState();

	const [rows, setRows] = useState([]);

	const [items, setItems] = useState([]);

	const [importCount, setImportCount] = useState([]);

	const [file, setFile] = useState();

	// Filtragem
	const [nivel1, setNivel1] = useState("");

	const [nivel2, setNivel2] = useState("");

	const [nivel3, setNivel3] = useState("");

	const [nivel4, setNivel4] = useState("");

	const [movNivel1, setMovNivel1] = useState("");

	const [movNivel2, setMovNivel2] = useState("");

	const [movNivel3, setMovNivel3] = useState("");

	const [movNivel4, setMovNivel4] = useState("");

	// Lista
	const [nivel1List, setNivel1List] = useState([]);

	const [nivel2List, setNivel2List] = useState([]);

	const [nivel3List, setNivel3List] = useState([]);

	const [nivel4List, setNivel4List] = useState([]);

	const [movNivel1List, setMovNivel1List] = useState([]);

	const [movNivel2List, setMovNivel2List] = useState([]);

	const [movNivel3List, setMovNivel3List] = useState([]);

	const [movNivel4List, setMovNivel4List] = useState([]);

	useEffect(() => {
		function getItems() {
			setLoading(true);

			api
				.get(`/item/${sessionStorage.getItem("idConta")}/*/*/*/*/*/*/*/*/*/*/*/*/*`, {})
				.then((response) => {
					setItems(response.data);
				})
				.catch(() => {
					setMessage({
						type: "error",
						message: t("messages.errors.500"),
						title: t("messages.errors.error"),
					});
				})
				.finally(() => {
					setLoading(false);
				});
		}

		getItems();
	}, []);

	useEffect(() => {
		function nivel1Get() {
			api.get(`/nivel_loc1/${sessionStorage.getItem("idConta")}/*/*/*/*`).then((response) => {
				const nivel1 = response.data;
				setNivel1List(nivel1);
				setMovNivel1List(nivel1);
			});
		}

		nivel1Get();
	}, []);

	//Get Nivel List
	useEffect(() => {
		nivelGet(2, nivel1, setNivel2List, nivel2, setNivel2);
	}, [nivel1]);

	useEffect(() => {
		nivelGet(3, nivel2, setNivel3List, nivel3, setNivel3);
	}, [nivel2]);

	useEffect(() => {
		nivelGet(4, nivel3, setNivel4List, nivel4, setNivel4);
	}, [nivel3]);

	//Get Destino List
	useEffect(() => {
		nivelGet(2, movNivel1, setMovNivel2List, movNivel2, setMovNivel2);
	}, [movNivel1]);

	useEffect(() => {
		nivelGet(3, movNivel2, setMovNivel3List, movNivel3, setMovNivel3);
	}, [movNivel2]);

	useEffect(() => {
		nivelGet(4, movNivel3, setMovNivel4List, movNivel4, setMovNivel4);
	}, [movNivel3]);

	useEffect(() => {
		if (file) {
			handleUpload(file);
		}
	}, [nivel1, nivel2, nivel3, nivel4]);

	async function nivelGet(preNivel, id_pre_nivel, setListFunction, currentValue, setNivelFunction) {
		if (id_pre_nivel && id_pre_nivel !== "*") {
			await api
				.get(`/nivel_loc${preNivel}/${sessionStorage.getItem("idConta")}/${id_pre_nivel || "*"}/*/*/*/*`)
				.then((response) => {
					const nivel = response.data;
					setListFunction(nivel);
					if (!nivel.find((item) => item._id === currentValue)) {
						setNivelFunction("");
					}
				});
		} else {
			setListFunction([]);
			if (currentValue) {
				setNivelFunction("");
			}
		}
	}

	function getStatus(tag, item) {
		if (!/^[0-9a-fA-F]{24}$/.test(tag)) {
			return {
				item_status: null,
				label: `Erro: ${t("messages.error_epc_invalid")}`,
			};
		}

		if (!item) {
			return {
				item_status: "excedente",
				label: "excess",
			};
		}

		const levels = { nivel4, nivel3, nivel2, nivel1 };

		for (const [key, nivel] of Object.entries(levels)) {
			if (nivel && item[`id_nivel_loc_${key.replace("nivel", "")}`]?._id !== nivel) {
				return {
					item_status: "excedente",
					label: "other_locate",
				};
			}
		}

		return {
			item_status: "encontrado",
			label: "found",
		};
	}

	async function handleUpload(uploadFile) {
		setFile(uploadFile);
		try {
			const upItems = [];

			const processedTags = new Set();

			for (let i = 1; i <= uploadFile.length; i++) {
				if (!uploadFile[i]) continue;

				const { tag = "", status, leitor } = uploadFile[i];

				const formatTag = tag ? String(tag).padStart(24, "0") : "0".padStart(24, "0");
				if (processedTags.has(formatTag)) continue;
				processedTags.add(formatTag);

				const getItem = items.find((i) => i?.tag == formatTag); // Verifica se existe o item na base

				const descricao = getItem?.id_categoria?.descricao || "SEM DESCRIÇÃO";

				const { label, item_status } = getStatus(formatTag, getItem) || {};

				const item = {
					id: i,
					tag: formatTag,
					descricao: descricao,
					leitor: leitor,
					id_item: getItem?._id || null,
					status: label || status?.toLowerCase(),
					[item_status || status?.toLowerCase()]: 1,
				};
				if (formatTag) upItems.push(item);
			}
			setRows(upItems);

			setImportCount([
				{
					color: green[600],
					label: "Novos Itens",
					icon: "lets-icons:check-ring-duotone",
					value: upItems.filter((item) => item.status.includes("Correto"))?.length,
				},
				{
					color: grey[500],
					label: "Já cadastrados",
					icon: "lets-icons:check-ring-duotone",
					value: upItems.filter((item) => item.status.includes("Registrado"))?.length,
				},
				{
					color: red[300],
					label: "Tag repetidas",
					icon: "lets-icons:close-ring-duotone",
					value: upItems.filter((item) => item.status.includes("Duplicada"))?.length,
				},
				{
					color: red[300],
					label: "Itens incorretos",
					icon: "lets-icons:close-ring-duotone",
					value: upItems.filter((item) => item.status.includes("Erro"))?.length,
				},
				{
					color: grey[500],
					label: "Itens importados",
					icon: "lets-icons:folder-check-duotone",
					value: upItems.filter((item) => !item.status.includes("Erro"))?.length,
				},
				{
					color: grey[500],
					label: "Total de itens",
					icon: "lets-icons:folder-up-duotone",
					value: upItems?.length,
				},
			]);
		} finally {
			setLoading(false);
		}
	}

	function handleSave() {
		setLoading(true);
		const _id = getID();
		const inventario = {
			id_registro: _id,
			id_conta: sessionStorage.getItem("idConta"),
			id_usuario: sessionStorage.getItem("idUser"),
			modo: "il",
			status: "1",
			id_nivel_loc_1: nivel1 || "",
			id_nivel_loc_2: nivel2 || "",
			id_nivel_loc_3: nivel3 || "",
			id_nivel_loc_4: nivel4 || "",
			qtd_excedente: rows.filter((i) => i.status == "excess")?.length,
			qtd_encontrado: rows.filter((i) => i.status == "found")?.length,
			qtd_outro_ambiente: rows.filter((i) => i.status == "other_locate")?.length,
		};
		let option = {
			headers: { "Content-Type": ["application/json"] },
		};

		api.post("/registro", [inventario], option).then((response) => {
			const id_registro = response.data.find((i) => i?._id);
			handleSaveTags(id_registro);
		});
	}

	function handleSaveTags(id_registro) {
		const option = {
			headers: { "Content-Type": ["application/json"] },
		};
		const reg = rows.map((tag) => ({ ...tag, id_registro }));

		api
			.post("/registro_tag_picking", reg, option)
			.then((response) => {
				setMessage({
					type: "success",
					message: t("messages.success_save"),
					title: t("messages.success"),
				});
			})
			.catch(() => {
				setMessage({
					type: "error",
					message: t("messages.errors.500"),
					title: t("messages.errors.error"),
				});
			})
			.finally(() => {
				setLoading(false);
			});
	}

	function getID() {
		var characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
		var id = "";
		for (var i = 0; i < 6; i++) {
			var randomIndex = Math.floor(Math.random() * characters.length);
			id += characters[randomIndex];
		}
		return id;
	}

	return (
		<>
			{message ? (
				<Alert
					type={message.type}
					title={message.title}
					message={message.message}
					onClose={(e) => {
						setMessage(null);
					}}
				/>
			) : (
				""
			)}
			<Container>
				<Stack spacing={3}>
					<Stack direction="row" justifyContent="space-between">
						<Typography variant="h1">{t("actions.import")}</Typography>
						<Button
							variant="contained"
							color="error"
							onClick={() => {
								handleSave();
							}}
						>
							{t("actions.save")}
						</Button>
					</Stack>

					<Stack as={Paper} sx={{ p: 3, borderRadius: 3 }}>
						<Stack spacing={3}>
							<Typography variant="h2">Configurar Inventário</Typography>

							<Grid2 container spacing={1}>
								{/* FILTROS POR NIVEL */}
								<Grid2 size={{ xs: 12 }}>
									<Typography variant="h6">Filtragem (opcional)</Typography>
								</Grid2>
								<Grid2 size={{ xs: 12, md: 3 }}>
									<FormControl fullWidth>
										<FormLabel>Nivel 1</FormLabel>
										<Select
											size="small"
											value={nivel1}
											onChange={(e) => {
												setNivel1(e.target.value);
											}}
										>
											{nivel1List.map((nivel) => (
												<MenuItem value={nivel?._id}>{nivel?.descricao}</MenuItem>
											))}
										</Select>
									</FormControl>
								</Grid2>
								<Grid2 size={{ xs: 12, md: 3 }}>
									<FormControl fullWidth>
										<FormLabel>Nivel 2</FormLabel>
										<Select
											size="small"
											value={nivel2}
											onChange={(e) => {
												setNivel2(e.target.value);
											}}
										>
											{nivel2List.map((nivel) => (
												<MenuItem value={nivel?._id}>{nivel?.descricao}</MenuItem>
											))}
										</Select>
									</FormControl>
								</Grid2>
								<Grid2 size={{ xs: 12, md: 3 }}>
									<FormControl fullWidth>
										<FormLabel>Nivel 3</FormLabel>
										<Select
											size="small"
											value={nivel3}
											onChange={(e) => {
												setNivel3(e.target.value);
											}}
										>
											{nivel3List.map((nivel) => (
												<MenuItem value={nivel?._id}>{nivel?.descricao}</MenuItem>
											))}
										</Select>
									</FormControl>
								</Grid2>
								<Grid2 size={{ xs: 12, md: 3 }}>
									<FormControl fullWidth>
										<FormLabel>Nivel 4</FormLabel>
										<Select
											size="small"
											value={nivel4}
											onChange={(e) => {
												setNivel4(e.target.value);
											}}
										>
											{nivel4List.map((nivel) => (
												<MenuItem value={nivel?._id}>{nivel?.descricao}</MenuItem>
											))}
										</Select>
									</FormControl>
								</Grid2>
							</Grid2>
						</Stack>

						<Import
							module="itens"
							format="csv"
							fields={["tag", "leitor", "status"]}
							onUpload={(e) => {
								console.log(e);
								handleUpload(e);
							}}
						/>

						<Datagrid header={columns} tableData={rows} />
					</Stack>
				</Stack>
			</Container>

			<Loading show={loading} />
		</>
	);
}
