import { useEffect, useState } from "react";

import {
	Button,
	Container,
	FormControl,
	FormLabel,
	Grid2,
	Paper,
	Stack,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	TextField,
	Typography,
} from "@mui/material";

import Loading from "../../../components/ui/Loading";

import { t } from "i18next";

import { Icon } from "@iconify/react/dist/iconify.js";

import dayjs from "dayjs";

import { useNavigate } from "react-router-dom";

import api from "../../../services/api";

import axios from "axios";

import "dayjs/locale/pt-br";

export default function FormList() {
	dayjs.locale("pt-br");

	const navigate = useNavigate();

	const [loading, setLoading] = useState(false);

	const [filterCod, setFilterCod] = useState("");

	const [filterTitle, setFilterTitle] = useState("");

	const [forms, setForms] = useState([]);

	useEffect(() => {
		getForms();
	}, []);

	function getForms() {
		setLoading(true);
		api
			.get(`/forms/${filterCod || "*"}/${sessionStorage.getItem("idConta")}/*`)
			.then((response) => {
				setLoading(false);
				var formList = response.data.filter((item) => item._id);
				if (filterTitle) {
					formList = formList.filter(
						(item) => item.title.toLowerCase().includes(filterTitle.toLowerCase()) // Filtra pelo campo "name"
					);
				}
				setForms(formList || []);
			})
			.catch(setLoading(false));
	}

	return (
		<>
			<Stack sx={{ p: 3 }} spacing={3}>
				<Stack sx={{ backgroundColor: "primary.lighter", p: 3 }} spacing={3}>
					<Typography variant="h6">{t("common.filter")}</Typography>
					<Grid2 container spacing={3}>
						<Grid2 item size={{ xs: 12, md: 3 }}>
							<FormControl fullWidth>
								<FormLabel>Nome</FormLabel>
								<TextField
									size="small"
									value={filterTitle}
									onChange={(e) => {
										setFilterTitle(e.target.value);
									}}
								/>
							</FormControl>
						</Grid2>
						<Grid2 item size={{ xs: 12, md: 3 }}>
							<FormControl fullWidth>
								<FormLabel>Cód.</FormLabel>
								<TextField
									size="small"
									value={filterCod}
									onChange={(e) => {
										setFilterCod(e.target.value);
									}}
								/>
							</FormControl>
						</Grid2>
					</Grid2>
					<Stack alignItems="end">
						<Button
							variant="contained"
							onClick={() => {
								getForms();
							}}
						>
							{t("actions.search")}
						</Button>
					</Stack>
				</Stack>

				<TableContainer component={Paper}>
					<Table sx={{ minWidth: 650 }} aria-label="simple table">
						<TableHead>
							<TableRow>
								<TableCell></TableCell>
								<TableCell sx={{ flex: 1 }}>NOME</TableCell>
								<TableCell align="right">CÓDIGO</TableCell>
								<TableCell align="right">DATA</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{forms.map((form, index) => (
								<TableRow
									key={index}
									sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
									onClick={() => {
										navigate("/form/detail", { state: { form: form } });
									}}
								>
									<TableCell component="th" scope="form">
										<Icon icon="fluent:form-sparkle-20-regular" width={24} />
									</TableCell>
									<TableCell sx={{ flex: 1 }}>
										<Typography sx={{ fontWeight: "bold" }}>{form?.title}</Typography>
									</TableCell>
									<TableCell align="right" sx={{ textTransform: "uppercase" }}>
										{form?.cod}
									</TableCell>
									<TableCell align="right" sx={{ textTransform: "capitalize" }}>
										{dayjs(new Date(form?.createdAt)).format("DD MMMM, YYYY HH:MM")}
									</TableCell>
								</TableRow>
							))}
						</TableBody>
					</Table>
				</TableContainer>
			</Stack>

			<Loading show={loading} />
		</>
	);
}
