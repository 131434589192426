import React from "react";
import { Navigate, useRoutes } from "react-router-dom";
import dayjs from "dayjs";
import secureLocalStorage from "react-secure-storage";

// Layout
import Layout from "./layout";
import Redirect from "./components/Redirect";
import Export from "./components/Export";

// Auth Pages
import Login from "./pages/Auth/LoginPage";
import Register from "./pages/Auth/RegisterPage";
import PBC_Login from "./pages/Auth/PBC/LoginPage";
import PBC_Register from "./pages/Auth/PBC/RegisterPage";

// Main Pages
import Home from "./pages/Home";
import Dashboard from "./pages/Dashboard";
import Logs from "./pages/Logs";
import BalanceSheet from "./pages/BalanceSheet";
import BI from "./pages/BI";
import Settings from "./pages/settings";
import Account from "./pages/Account";
import UserGuide from "./pages/Guide";
import PrivacyPolicy from "./pages/Terms/privacyPolicy";
import TermsAndConditions from "./pages/Terms/termsConditions";

// Inventory Pages
import Collects from "./pages/Collects";
import Inventories from "./pages/Inventories";
import CreateInventory from "./pages/Inventories/CreateInventory";
import OrderInventory from "./pages/Inventories/CreateInventory/Monitoring/Checagem";
import InventoriesImport from "./pages/Inventories/InventoriesImport";
import MonitoringInventory from "./pages/Inventories/CreateInventory/Monitoring/TagPicking";
import Almoxarifado from "./pages/Inventories/CreateInventory/Monitoring/Almoxarifado";
import ExternInventories from "./pages/ExternInventories";
import EILink from "./pages/EILink";
import Monitoring from "./pages/Monitoring";

// Item Pages
import Items from "./pages/Items";
import ItemsCopy from "./pages/Items/ItemsCopy";
import ItemsDetails from "./pages/Items/ItemsDetails";
import ItemsTimeline from "./pages/Items/ItemsDetails/ItemsTimeline";
import ItemsExport from "./pages/Items/Export/ItemsExport";
import ImportItems from "./pages/Items/Import/ImportItems";
import ItemsImportConfig from "./pages/Items/Import/ItemsImportConfig";
import ItemsMap from "./pages/Items/ItemsMap";
import ItemsActivity from "./pages/ItemsActivity";
import ItemsExternView from "./pages/Items/ItemsDetails/ItemsExternView";

// Partner/Reader Pages
import Partners from "./pages/Partners";
import PartnerDetails from "./pages/Partners/details";
import Readers from "./pages/Readers";
import ReaderDetails from "./pages/Readers/ReaderDetails";
import GroupList from "./pages/Readers/GroupReaders/GroupList";
import GroupDetails from "./pages/Readers/GroupReaders/GroupDetails";

// Location/Category Pages
import Categories from "./pages/Categories";
import Locations from "./pages/Locations";
import LocationsImport from "./pages/Locations/LocationsImport";
import LocationsImportConfig from "./pages/Locations/LocationsImport/ImportConfig";
import Types from "./pages/Types";

// Print Pages
import Print from "./pages/Print";
import PrintOrder from "./pages/PrintOrder";
import PrintAdress from "./pages/PrintAdress";

// Import/Export
import ImportConfig from "./pages/Import/ImportConfig";
import ExportActivity from "./pages/Activities/export-page";

// Order Pages
import Orders from "./pages/Orders";
import OrderImport from "./pages/Orders/OrderImport";
import OrderDetails from "./pages/Orders/OrderDetails";

// Form Pages
import FormsCreate from "./pages/Forms/Form/FormCreate";
import Forms from "./pages/Forms";
import FormView from "./pages/Forms/Form/FormView";
import FormGroupCreate from "./pages/Forms/Group/GroupCreate";
import FormGroupView from "./pages/Forms/Group/GroupView";

// QR Code Pages
import QRCode from "./pages/QRCode";
import Registers from "./pages/Registers";
import RegistersTimeline from "./pages/Registers/RegistersTimeline";

// Map Pages
import Maps from "./pages/Maps";
import MapsC from "./pages/MapsContelurb";

// Transport Pages
import TransportLogin from "./pages/Transport/TransportLogin";
import Transport from "./pages/Transport";
import ScanQRCode from "./pages/Transport/ScanQRCode";
import UploadPhoto from "./pages/Transport/UploadPhoto";
import SendData from "./pages/Transport/SendData";

// NTI Pages
import NTILogin from "./pages/NTI/NTILogin";
import NTI from "./pages/NTI";
import NTIScanQRCode from "./pages/NTI/NTIScanQRCode";
import NTIUploadPhoto from "./pages/NTI/NTIUploadPhoto";
import NTISendData from "./pages/NTI/NTISendData";

function isAuthenticated() {
	const hasToken = sessionStorage.getItem("idConta") ? true : false;

	if (sessionStorage.getItem("disconnect")) {
		const disconnectDate = new Date(sessionStorage.getItem("disconnect"));
		if (dayjs().isSame(disconnectDate) || dayjs().isAfter(disconnectDate)) {
			secureLocalStorage.clear();
			sessionStorage.clear();
		}
	}

	return hasToken;
}

function PrivateRoute({ element }) {
	return isAuthenticated() ? element : <Redirect />;
}

export default function Router() {
	const routes = useRoutes([
		{
			path: "/",
			element: <Layout />,
			children: [
				// Default redirect
				{ index: true, element: <PrivateRoute element={<Navigate to="/home" />} /> },

				// Dashboard & Home
				{ path: "home", element: <PrivateRoute element={<Home />} /> },
				{ path: "dashboard", element: <PrivateRoute element={<Dashboard />} /> },

				// Monitoring & BI
				{ path: "monitoring", element: <PrivateRoute element={<Monitoring />} /> },
				{ path: "BI", element: <PrivateRoute element={<BI />} /> },
				{ path: "logs", element: <PrivateRoute element={<Logs />} /> },
				{ path: "balance-sheet", element: <PrivateRoute element={<BalanceSheet />} /> },

				// Collections
				{ path: "collections/collects", element: <PrivateRoute element={<Collects />} /> },
				{ path: "collections/inventories/:identificador?", element: <PrivateRoute element={<Inventories />} /> },
				{ path: "collections/create/:id_modelo?", element: <PrivateRoute element={<CreateInventory />} /> },
				{ path: "collections/extern-inventories/:identificador", element: <PrivateRoute element={<EILink />} /> },
				{ path: "collections/extern-inventories", element: <PrivateRoute element={<ExternInventories />} /> },
				{ path: "collections/import", element: <PrivateRoute element={<InventoriesImport />} /> },

				// Items
				{ path: "items/details", element: <PrivateRoute element={<ItemsDetails />} /> },
				{ path: "items/log", element: <PrivateRoute element={<ItemsTimeline />} /> },
				{ path: "item/activity", element: <PrivateRoute element={<ItemsActivity />} /> },
				{ path: "items/export", element: <PrivateRoute element={<ItemsExport />} /> },
				{ path: "items/V2", element: <PrivateRoute element={<ItemsCopy />} /> },
				{ path: "items/:params_tag?", element: <PrivateRoute element={<Items />} /> },
				{ path: "items/map", element: <PrivateRoute element={<ItemsMap />} /> },
				{ path: "items/activity/:tag?", element: <PrivateRoute element={<ItemsActivity />} /> },
				{ path: "items/import/:format", element: <PrivateRoute element={<ImportItems />} /> },
				{ path: "items/import/config/:file", element: <PrivateRoute element={<ItemsImportConfig />} /> },

				// Partners & Readers
				{ path: "partners/details/:id_parceiro", element: <PrivateRoute element={<PartnerDetails />} /> },
				{ path: "partners", element: <PrivateRoute element={<Partners />} /> },
				{ path: "readers", element: <PrivateRoute element={<Readers />} /> },
				{ path: "readers/details/:id?", element: <PrivateRoute element={<ReaderDetails />} /> },
				{ path: "readers/groups", element: <PrivateRoute element={<GroupList />} /> },
				{ path: "readers/groups/details/:id?", element: <PrivateRoute element={<GroupDetails />} /> },

				// Locations & Categories
				{ path: "categories", element: <PrivateRoute element={<Categories />} /> },
				{ path: "locations", element: <PrivateRoute element={<Locations />} /> },
				{ path: "locations/import", element: <PrivateRoute element={<LocationsImport />} /> },
				{ path: "locations/import/config", element: <PrivateRoute element={<LocationsImportConfig />} /> },
				{ path: "types", element: <PrivateRoute element={<Types />} /> },

				// Print
				{ path: "print", element: <PrivateRoute element={<Print />} /> },
				{ path: "print-adress", element: <PrivateRoute element={<PrintAdress />} /> },
				{ path: "print-order", element: <PrivateRoute element={<PrintOrder />} /> },

				// Account & Settings
				{ path: "account/:page?", element: <PrivateRoute element={<Settings />} /> },
				{ path: "v2/account/:page?", element: <PrivateRoute element={<Account />} /> },

				// Guides & Help
				{ path: "guides/user_guide", element: <PrivateRoute element={<UserGuide />} /> },

				// Import/Export
				{ path: "config", element: <PrivateRoute element={<ImportConfig />} /> },
				{ path: "export", element: <PrivateRoute element={<Export />} /> },
				{ path: "activityexport", element: <PrivateRoute element={<ExportActivity />} /> },

				// Orders
				{ path: "orders", element: <PrivateRoute element={<Orders />} /> },
				{ path: "orders/details/:id_registro?", element: <PrivateRoute element={<OrderDetails />} /> },
				{ path: "orders/upload/:format?", element: <PrivateRoute element={<OrderImport />} /> },

				// Forms
				{ path: "form", element: <PrivateRoute element={<Forms />} /> },
				{ path: "form/detail", element: <PrivateRoute element={<FormsCreate />} /> },
				{ path: "form-group/detail", element: <PrivateRoute element={<FormGroupCreate />} /> },

				// Registers
				{ path: "registers", element: <PrivateRoute element={<Registers />} /> },
				{ path: "registers/:id_item", element: <PrivateRoute element={<RegistersTimeline />} /> },

				// Maps
				{ path: "maps", element: <PrivateRoute element={<Maps />} /> },
				{ path: "mapsc/:conta", element: <PrivateRoute element={<MapsC />} /> },
			],
		},

		// Inventory Monitoring
		{ path: "/collections/create/checagem/:id", element: <PrivateRoute element={<OrderInventory />} /> },
		{ path: "/collections/create/tag_picking/:id", element: <PrivateRoute element={<MonitoringInventory />} /> },
		{ path: "/collections/create/almoxarifado/:id", element: <PrivateRoute element={<Almoxarifado />} /> },

		// Auth Routes
		{ path: "/login/:id_conta?/:token?", element: <Login /> },
		{ path: "/register", element: <Register /> },
		{ path: "/PBC/login", element: <PBC_Login /> },
		{ path: "/PBC/register", element: <PBC_Register /> },
		{ path: "/privacy_policy", element: <PrivacyPolicy /> },
		{ path: "/terms-and-conditions", element: <TermsAndConditions /> },

		// Transport
		{
			path: "/transport",
			element: <Transport />,
			children: [
				{ index: true, element: <Navigate to="qrcode" /> },
				{ path: "login", element: <TransportLogin /> },
				{ path: "qrcode/:id_conta?/:tag?", element: <ScanQRCode /> },
				{ path: "upload-foto", element: <UploadPhoto /> },
				{ path: "confirm-data", element: <SendData /> },
			],
		},

		// NTI
		{
			path: "/nti",
			element: <NTI />,
			children: [
				{ index: true, element: <Navigate to="qrcode" /> },
				{ path: "login", element: <NTILogin /> },
				{ path: "qrcode/:id_conta?/:tag?", element: <NTIScanQRCode /> },
				{ path: "upload-foto", element: <NTIUploadPhoto /> },
				{ path: "confirm-data", element: <NTISendData /> },
			],
		},

		// Forms
		{ path: "/form/:cod?/:epc?", element: <FormView /> },
		{ path: "/form-group/:cod?/:epc?", element: <FormGroupView /> },

		// QR Code
		{ path: "/qrcode", element: <QRCode /> },

		// External Items
		{ path: "/items/extern/:id_item", element: <ItemsExternView /> },

		// Catch-all route
		{ path: "*", element: <PrivateRoute element={<Navigate to="/home" />} /> },
	]);

	return routes;
}
