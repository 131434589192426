import { useEffect, useState } from "react";

import { useParams } from "react-router-dom";

import {
	Box,
	Container,
	Divider,
	Grid2,
	Paper,
	Stack,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Typography,
} from "@mui/material";

import api from "../../../services/api";

import { t } from "i18next";

import { Icon } from "@iconify/react/dist/iconify.js";

import Loading from "../../../components/loading";

// ------------------------------------------------

const camposDefault = [
	"id_categoria",
	"id_marca",
	"id_modelo",
	"ean",
	"observacao",
	"foto_compl",
	"foto",
	"valor",
	"inf_compl_1",
	"inf_compl_2",
];

export default function ItemsExternView() {
	const { id_item } = useParams();

	// Utils
	const [loading, setLoading] = useState(false);
	const [isViewAllowed, setIsViewAllowed] = useState(true);
	const [fotoOnFocus, setFotoOnFocus] = useState();
	const [camposVisiveis, setCamposVisiveis] = useState(camposDefault);

	// Data
	const [itemsData, setItemsData] = useState([]);
	const [item, setItem] = useState();
	const [table, setTable] = useState([]);

	// Requisição do Item
	useEffect(() => {
		function handleVisibility(itemVisility, contaVisibility) {
			let visibility = itemVisility;

			if (!contaVisibility === "all") {
				visibility = true;
			}

			if (!contaVisibility === "none") {
				visibility = false;
			}

			setIsViewAllowed(visibility);
		}

		const fetchData = async () => {
			setLoading(true);

			try {
				// Fetch item data
				const itemResponse = await api.get(`/itensPage/*/${id_item}/*/*/*/*/*/*/*/*/*/*/*/*/*/0`);
				const itemData = itemResponse?.data?.find((i) => i?._id);

				// Check if item is public
				if (!itemData?.publico) {
					setIsViewAllowed(false);
					setLoading(false);
					return;
				}

				// Transform item data
				const modelo = itemData?.id_marca?.modelo.find((i) => i?._id === itemData?.id_modelo)?.descricao;
				const transformedItemData = {
					...itemData,
					ean: itemData?.id_categoria?.ean,
					id_categoria: itemData?.id_categoria?.descricao,
					id_marca: itemData?.id_marca?.marca,
					id_modelo: modelo,
				};

				setItemsData(transformedItemData);

				// Fetch and process account config
				const id_conta = transformedItemData?.id_conta;
				if (!id_conta) return;

				const configResponse = await api.get(`/conta_config/${id_conta}`);
				const config = configResponse?.data?.find((i) => i?._id) || {};

				handleVisibility(itemData?.publico, config?.visibilidade_externa);

				// Process visible fields
				const filtroVisiveis = config?.campos_visiveis || camposDefault;
				setCamposVisiveis(filtroVisiveis);

				// Create table info structure
				const infoCompl = Array.from({ length: 10 }, (_, index) => ({
					value: `inf_compl_${index + 1}`,
					label: config[`info_compl_${index + 1}`] || "",
				}));

				const tableInfos = filtroVisiveis
					.filter((i) => !i?.includes("foto"))
					.map((i) => {
						const infoItem = infoCompl.find((item) => item.value === i); // Correção aqui
						console.log(infoItem);
						const label =
							i?.includes("inf_compl_") && infoItem?.label
								? infoItem.label
								: i?.replace("id_", "").replaceAll("_", " ");

						return {
							value: i,
							label: label,
						};
					});

				setTable(tableInfos);

				// Filter item data based on visible fields
				const filteredItem = {};
				filtroVisiveis.forEach((campo) => {
					if (transformedItemData?.hasOwnProperty(campo)) {
						filteredItem[campo] = transformedItemData[campo];
					}
				});
				setItem(filteredItem);

				// Process photo
				const resFoto = filtroVisiveis.includes("foto")
					? filteredItem?.foto
						? `https://api.inovacode.app.br/${filteredItem.foto}`
						: filteredItem?.foto_compl?.length
							? filteredItem.foto_compl[0]
							: ""
					: "";
				setFotoOnFocus(resFoto);
			} catch (error) {
				console.error("Error fetching data:", error);
				// Consider adding error state handling here
			} finally {
				setLoading(false);
			}
		};

		fetchData();
	}, [id_item]);

	if (loading) {
		return (
			<>
				<Loading show={loading} />
			</>
		);
	}

	if (!isViewAllowed) {
		return (
			<>
				<Stack justifyContent="center" alignItems="center" sx={{ width: "100vw", height: "100vh" }}>
					<Stack alignItems="center">
						<Stack sx={{ color: "grey.400" }}>
							<Icon icon="garden:document-search-fill-16" width={64} />
						</Stack>
						<Typography textAlign="center" sx={{ fontSize: "5rem", fontWeight: 800 }}>
							404
						</Typography>
						<Typography variant="h1" textAlign="center">
							{t("messages.error")} 404
						</Typography>
						<Typography textAlign="center" sx={{ width: { xs: "80%", md: "60%" } }}>
							{t("messages.items.404")}
						</Typography>
					</Stack>
				</Stack>
			</>
		);
	}

	return (
		<>
			<Container sx={{ my: 5 }}>
				<Stack spacing={5}>
					<Grid2 container spacing={3}>
						{fotoOnFocus && (
							<Grid2 size={{ xs: 12, md: 6 }}>
								<Stack alignItems="center" spacing={3}>
									<Box
										component="img"
										src={fotoOnFocus}
										sx={{ height: "auto", width: { xs: "100%", md: "80%" }, borderRadius: 3 }}
									/>
									<Stack
										direction="row"
										spacing={1}
										sx={{
											maxWidth: { xs: "100%", md: "60%" },
											overflowX: "auto",
											whiteSpace: "nowrap",
											"&::-webkit-scrollbar": {
												height: "8px",
											},
											padding: 1,
										}}
									>
										<Box
											component="img"
											src={`https://api.inovacode.app.br/` + item?.foto}
											sx={{
												height: 64,
												width: 64,
												borderRadius: 3,
												opacity: fotoOnFocus === `https://api.inovacode.app.br/` + item?.foto ? "1" : "0.4",
												cursor: "pointer",
											}}
											onClick={() => {
												setFotoOnFocus(`https://api.inovacode.app.br/` + item?.foto);
											}}
										/>
										{item?.foto_compl?.map((foto) => (
											<Box
												component="img"
												src={foto}
												sx={{
													height: 64,
													width: 64,
													borderRadius: 3,
													opacity: fotoOnFocus === foto ? "1" : "0.4",
													cursor: "pointer",
												}}
												onClick={() => {
													setFotoOnFocus(foto);
												}}
											/>
										))}
									</Stack>
								</Stack>
							</Grid2>
						)}
						<Grid2 size="grow">
							<Stack spacing={3}>
								<div>
									<Typography variant="h1" textAlign="left">
										{item?.id_categoria}
									</Typography>
									<Stack
										direction="row"
										alignItems="center"
										spacing={1}
										divider={<Divider orientation="vertical" flexItem />}
									>
										<Typography variant="subtitle">{item?.id_marca}</Typography>
										<Typography variant="subtitle">{item?.id_modelo}</Typography>
									</Stack>
								</div>
								<Typography variant="h3">R$ {item?.valor?.toFixed(2)}</Typography>
								<Typography variant="subtitle">{item?.observacao}</Typography>
							</Stack>
						</Grid2>
					</Grid2>

					<Stack spacing={3}>
						<Typography variant="h3">Especificações</Typography>
						<TableContainer component={Paper}>
							<Table>
								<TableHead>
									<TableBody>
										{table.map((i) => {
											if (!item?.[i?.value]) return <></>;

											return (
												<TableRow>
													<TableCell sx={{ textTransform: "capitalize", fontWeight: 600 }}>{i?.label}</TableCell>
													<TableCell sx={{ width: "100%" }}>{item?.[i?.value]}</TableCell>
												</TableRow>
											);
										})}
									</TableBody>
								</TableHead>
							</Table>
						</TableContainer>
					</Stack>
				</Stack>
			</Container>
		</>
	);
}
