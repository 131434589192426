import { useLocation, useNavigate } from "react-router-dom";

import React, { useState, useEffect } from "react";

import {
	Container,
	Grid2,
	InputLabel,
	MenuItem,
	Paper,
	Select,
	Stack,
	Typography,
	Button,
	TextField,
	Table,
	TableHead,
	TableRow,
	TableCell,
	TableBody,
	FormControlLabel,
	Checkbox,
} from "@mui/material";

import { Icon } from "@iconify/react/dist/iconify.js";

import { t } from "i18next";

import Dropdown from "../../components/ui/Dropdown";

import api from "../../services/api";

import Loading from "../../components/ui/Loading";

import Alert from "../../components/ui/Alert";

export default function ReaderDetails() {
	const navigate = useNavigate();

	const location = useLocation();

	const [loading, setLoading] = useState(false);

	const [message, setMessage] = useState("");

	// Fields
	const [details, setDetails] = useState([]);

	const [id, setID] = useState("");

	const [name, setName] = useState("");

	const [serial, setSerial] = useState("");

	const [ativo, setAtivo] = useState("1");

	const [periodo, setPeriodo] = useState("");

	const [infoCompl1, setInfoCompl1] = useState("");

	const [infoCompl2, setInfoCompl2] = useState("");

	const [modelo, setModelo] = useState("");

	const [potencia, setPotencia] = useState("");

	const [seccao, setSeccao] = useState("");

	const [estado, setEstado] = useState("");

	const [impressora, setImpressora] = useState("");

	const [padrao, setPadrao] = useState("");

	const [verificadorInicial, setVerificadorInicial] = useState("");

	const [posicao, setPosicao] = useState("");

	const [comprimento, setComprimento] = useState("");

	const [observacao, setObservacao] = useState("");

	const [finalidade, setFinalidade] = useState("1");

	const [disableItems, setDisableItems] = useState(false);

	const [regInventario, setRegInventario] = useState(false);

	const [parceiro, setParceiro] = useState("");

	const [reverso, setReverso] = useState("0");

	const [locNivel1, setLocNivel1] = useState("");

	const [locNivel2, setLocNivel2] = useState("");

	const [locNivel3, setLocNivel3] = useState("");

	const [locNivel4, setLocNivel4] = useState("");

	const [destinoNivel1, setDestinoNivel1] = useState("");

	const [destinoNivel2, setDestinoNivel2] = useState("");

	const [destinoNivel3, setDestinoNivel3] = useState("");

	const [destinoNivel4, setDestinoNivel4] = useState("");

	const [saidaNivel1, setSaidaNivel1] = useState("");

	const [saidaNivel2, setSaidaNivel2] = useState("");

	const [saidaNivel3, setSaidaNivel3] = useState("");

	const [saidaNivel4, setSaidaNivel4] = useState("");

	const [leituraInv, setLeituraInv] = useState(false);

	// Listas
	const [nivel1List, setNivel1List] = useState([]);

	const [nivel2List, setNivel2List] = useState([]);

	const [nivel3List, setNivel3List] = useState([]);

	const [nivel4List, setNivel4List] = useState([]);

	const [destNivel1List, setDestNivel1List] = useState([]);

	const [destNivel2List, setDestNivel2List] = useState([]);

	const [destNivel3List, setDestNivel3List] = useState([]);

	const [destNivel4List, setDestNivel4List] = useState([]);

	const [saidaNivel1List, setsaidaNivel1List] = useState([]);

	const [saidaNivel2List, setsaidaNivel2List] = useState([]);

	const [saidaNivel3List, setsaidaNivel3List] = useState([]);

	const [saidaNivel4List, setsaidaNivel4List] = useState([]);

	useEffect(() => {
		function fetchDetails() {
			const reader = location.state?.reader;
			if (reader) {
				setDetails(reader);

				const {
					_id,
					nm_leitor,
					serial,
					ativo,
					periodo,
					inf_compl_1,
					inf_compl_2,
					reg_coleta,
					inativar_itens,
					reg_inventario,
					observacao,
					leitor_modelo,
					leitor_serial,
					leitor_potencia,
					leitor_secao,
					leitor_estado,
					impressora_serial,
					item_padrao,
					item_verif_inicial,
					item_posicao,
					item_comprimento,
					id_nivel_loc_1,
					id_nivel_loc_2,
					id_nivel_loc_3,
					id_nivel_loc_4,
					dest_id_nivel_loc_1,
					dest_id_nivel_loc_2,
					dest_id_nivel_loc_3,
					dest_id_nivel_loc_4,
					saida_dest_id_nivel_loc_1,
					saida_dest_id_nivel_loc_2,
					saida_dest_id_nivel_loc_3,
					saida_dest_id_nivel_loc_4,
					leitura_tipo_inventario,
				} = reader;

				setID(_id || 0);
				setName(nm_leitor || "");
				setSerial(serial || "");
				setAtivo(ativo || "");
				setPeriodo(periodo || "");
				setInfoCompl1(inf_compl_1 || "");
				setInfoCompl2(inf_compl_2 || "");
				setObservacao(observacao || "");
				setFinalidade(reg_coleta || "");
				setDisableItems(inativar_itens || false);
				setRegInventario(reg_inventario);
				setModelo(leitor_modelo || "");
				setSerial(leitor_serial || "");
				setPotencia(leitor_potencia || "");
				setSeccao(leitor_secao || "");
				setEstado(leitor_estado || "");
				setImpressora(impressora_serial || "");
				setPadrao(item_padrao || "");
				setVerificadorInicial(item_verif_inicial || "");
				setPosicao(item_posicao || "");
				setComprimento(item_comprimento || "");
				setLocNivel1(id_nivel_loc_1 || "");
				setLocNivel2(id_nivel_loc_2 || "");
				setLocNivel3(id_nivel_loc_3 || "");
				setLocNivel4(id_nivel_loc_4 || "");
				setDestinoNivel1(dest_id_nivel_loc_1);
				setDestinoNivel2(dest_id_nivel_loc_2);
				setDestinoNivel3(dest_id_nivel_loc_3);
				setDestinoNivel4(dest_id_nivel_loc_4);
				setSaidaNivel1(saida_dest_id_nivel_loc_1);
				setSaidaNivel2(saida_dest_id_nivel_loc_2);
				setSaidaNivel3(saida_dest_id_nivel_loc_3);
				setSaidaNivel4(saida_dest_id_nivel_loc_4);
				setLeituraInv(leitura_tipo_inventario || false);
			}
		}

		fetchDetails();
	}, []);

	useEffect(() => {
		function nivel1Get() {
			api.get(`/nivel_loc1/${sessionStorage.getItem("idConta")}/*/*/*/*`).then((response) => {
				const nivel1 = response.data;
				setNivel1List(nivel1);
				setDestNivel1List(nivel1);
				setsaidaNivel1List(nivel1);
			});
		}

		nivel1Get();
	}, []);

	//Get Loc List
	useEffect(() => {
		nivelGet(2, locNivel1, setNivel2List, locNivel2, setLocNivel2);
	}, [locNivel1]);

	useEffect(() => {
		nivelGet(3, locNivel2, setNivel3List, locNivel3, setLocNivel3);
	}, [locNivel2]);

	useEffect(() => {
		nivelGet(4, locNivel3, setNivel4List, locNivel4, setLocNivel4);
	}, [locNivel3]);

	//Get Saida List
	useEffect(() => {
		nivelGet(2, saidaNivel1, setsaidaNivel2List, saidaNivel2, setSaidaNivel2);
	}, [saidaNivel1]);

	useEffect(() => {
		nivelGet(3, saidaNivel2, setsaidaNivel3List, saidaNivel3, setSaidaNivel3);
	}, [saidaNivel2]);

	useEffect(() => {
		nivelGet(4, saidaNivel3, setsaidaNivel4List, saidaNivel4, setSaidaNivel4);
	}, [saidaNivel3]);

	//Get Destino List
	useEffect(() => {
		nivelGet(2, destinoNivel1, setDestNivel2List, destinoNivel2, setDestinoNivel2);
	}, [destinoNivel1]);

	useEffect(() => {
		nivelGet(3, destinoNivel2, setDestNivel3List, destinoNivel3, setDestinoNivel3);
	}, [destinoNivel2]);

	useEffect(() => {
		nivelGet(4, destinoNivel3, setDestNivel4List, destinoNivel4, setDestinoNivel4);
	}, [destinoNivel3]);

	async function nivelGet(preNivel, id_pre_nivel, setListFunction, currentValue, setNivelFunction) {
		if (id_pre_nivel && id_pre_nivel !== "*") {
			await api
				.get(`/nivel_loc${preNivel}/${sessionStorage.getItem("idConta")}/${id_pre_nivel || "*"}/*/*/*/*`)
				.then((response) => {
					const nivel = response.data;
					setListFunction(nivel);
					if (!nivel.find((item) => item._id === currentValue)) {
						setNivelFunction("");
					}
				});
		} else {
			setListFunction([]);
			if (currentValue) {
				setNivelFunction("");
			}
		}
	}

	function getID() {
		return "xxxxxxxx-yxxx".replace(/[xy]/g, function (c) {
			var r = (Math.random() * 16) | 0,
				v = c === "x" ? r : (r & 0x3) | 0x8;
			return v.toString(16);
		});
	}

	function handleSave() {
		const newid = id || getID();
		const newOrder = [
			{
				...details,
				_id: newid,
				id_conta: sessionStorage.getItem("idConta"),
				ativo: ativo,
				id_parceiro: parceiro,
				inventario_reverso: reverso,
				nm_leitor: name,
				observacao: observacao,
				reg_coleta: finalidade,
				inativar_itens: disableItems,
				reg_inventario: regInventario,
				serial: serial,
				periodo: periodo,
				inf_compl_1: infoCompl1,
				inf_compl_2: infoCompl2,
				leitor_modelo: modelo,
				leitor_serial: serial,
				leitor_potencia: potencia,
				leitor_secao: seccao,
				leitor_estado: estado,
				impressora_serial: impressora,
				item_padrao: padrao,
				item_verif_inicial: verificadorInicial,
				item_posicao: posicao,
				item_comprimento: comprimento,
				id_nivel_loc_1: locNivel1,
				id_nivel_loc_2: locNivel2,
				id_nivel_loc_3: locNivel3,
				id_nivel_loc_4: locNivel4,
				dest_id_nivel_loc_1: finalidade == "0" ? "" : destinoNivel1,
				dest_id_nivel_loc_2: finalidade == "0" ? "" : destinoNivel2,
				dest_id_nivel_loc_3: finalidade == "0" ? "" : destinoNivel3,
				dest_id_nivel_loc_4: finalidade == "0" ? "" : destinoNivel4,
				saida_dest_id_nivel_loc_1: saidaNivel1 ?? "",
				saida_dest_id_nivel_loc_2: saidaNivel2 ?? "",
				saida_dest_id_nivel_loc_3: saidaNivel3 ?? "",
				saida_dest_id_nivel_loc_4: saidaNivel4 ?? "",
				leitura_tipo_inventario: leituraInv,
			},
		];
		let option = {
			headers: { "Content-Type": ["application/json"] },
		};
		let canUpload = true;
		api
			.get(`/leitor/*/*/*/${serial}/*`)
			.then((response) => {
				const registerList = response.data;
				if (registerList.length) {
					const registeredReader = registerList.find((item) => item.serial === serial);
					if (registeredReader.id_conta !== sessionStorage.getItem("idConta")) {
						api.get(`/conta/${registeredReader.id_conta}/*/*/*`).then((response) => {
							setMessage({
								type: "error",
								message: `O serial deste leitor já foi registrado em uma conta...\nConsultar o email: ${response.data[0].email}`,
								title: t("messages.errors.error"),
							});
						});
						canUpload = false;
					}
				}
			})
			.finally((response) => {
				if (canUpload) {
					api.post("/leitor", newOrder, option).then(
						(response) => {
							setMessage({
								type: "success",
								message: t("messages.success_save"),
								title: t("messages.success"),
							});
						},
						(response) => {
							setMessage({
								type: "error",
								message: t("messages.errors.500"),
								title: t("messages.errors.error"),
							});
						}
					);
				}
			});
	}

	function handleDelete() {
		let option = {
			headers: { "Content-Type": ["application/json"] },
		};
		api.delete(`/leitor/${id}`, option).then(
			(response) => {
				setMessage({
					type: "success",
					message: t("messages.success_delete"),
					title: t("messages.success"),
					redirect: "/readers",
				});
			},
			(error) => {
				setMessage({
					type: "error",
					message: t("messages.errors.500"),
					title: t("messages.errors.error"),
				});
			}
		);
	}

	return (
		<>
			<Container maxWidth="lg">
				<Stack spacing={3}>
					<Stack direction="row" justifyContent="space-between" alignItems="center">
						<Typography variant="h1">{t("common.readers")}</Typography>
						<Stack direction="row" spacing={1}>
							<Button
								variant="outlined"
								color="dark"
								onClick={() => {
									navigate("/readers");
								}}
							>
								{t("actions.exit")}
							</Button>
							{id && (
								<Button
									variant="outlined"
									color="dark"
									onClick={() => {
										handleDelete();
									}}
									startIcon={<Icon icon="solar:trash-bin-minimalistic-linear" />}
								>
									{t("actions.delete")}
								</Button>
							)}
							<Button
								variant="contained"
								color="error"
								onClick={() => {
									handleSave();
								}}
								startIcon={<Icon icon="mingcute:save-2-line" />}
							>
								{t("actions.save")}
							</Button>
						</Stack>
					</Stack>

					<Stack spacing={3} as={Paper} sx={{ borderRadius: 3, p: 3 }}>
						<Grid2 container spacing={2}>
							<Grid2 size={{ xs: 12, md: 6 }}>
								<Typography>{t("common.name")}</Typography>
								<TextField
									id="name_reader"
									size="small"
									fullWidth
									value={name}
									onChange={(e) => {
										setName(e.target.value);
									}}
								/>
							</Grid2>
							<Grid2 size={{ xs: 12, md: 6 }}>
								<Typography>Serial</Typography>
								<TextField
									id="serial"
									size="small"
									fullWidth
									value={serial}
									onChange={(e) => {
										setSerial(e.target.value);
									}}
								/>
							</Grid2>
							<Grid2 size={{ xs: 12, md: 6 }}>
								<Typography>{t("common.active")}</Typography>
								<Select
									value={ativo}
									onChange={(e) => {
										setAtivo(e.target.value);
									}}
									size="small"
									fullWidth
								>
									<MenuItem value="0">Não</MenuItem>
									<MenuItem value="1">Sim</MenuItem>
								</Select>
							</Grid2>
							<Grid2 size={{ xs: 12, md: 6 }}>
								<Typography>Periodo (dias)</Typography>
								<TextField
									size="small"
									fullWidth
									value={periodo}
									onChange={(e) => {
										setPeriodo(e.target.value);
									}}
								/>
							</Grid2>
							<Grid2 size={{ xs: 12, md: 6 }}>
								<Typography>{t("common.info_label")} 1</Typography>
								<TextField
									size="small"
									fullWidth
									value={infoCompl1}
									onChange={(e) => {
										setInfoCompl1(e.target.value);
									}}
								/>
							</Grid2>
							<Grid2 size={{ xs: 12, md: 6 }}>
								<Typography>{t("common.info_label")} 2</Typography>
								<TextField
									size="small"
									fullWidth
									value={infoCompl2}
									onChange={(e) => {
										setInfoCompl2(e.target.value);
									}}
								/>
							</Grid2>
							<Grid2 size={{ xs: 12, md: 6 }}>
								<Typography>{t("export.model")}</Typography>
								<Select
									size="small"
									fullWidth
									value={modelo}
									onChange={(e) => {
										setModelo(e.target.value);
									}}
								>
									<MenuItem value=""></MenuItem>
									<MenuItem value="blu">Bluebird</MenuItem>
									<MenuItem value="dot">Dot 900</MenuItem>
									<MenuItem value="301">i300</MenuItem>
									<MenuItem value="bu1">Clock B1</MenuItem>
									<MenuItem value="tsl">Tsl 1128</MenuItem>
									<MenuItem value="imp">Impinj</MenuItem>
									<MenuItem value="inn">InovaCode</MenuItem>
									<MenuItem value="mdl">Midleware</MenuItem>
									<MenuItem value="nfc">NFC</MenuItem>
									<MenuItem value="ebc">eBeacon</MenuItem>
									<MenuItem value="ter">Terminal</MenuItem>
									<MenuItem value="csv">CSV</MenuItem>
								</Select>
							</Grid2>
							<Grid2 size={{ xs: 12, md: 6 }}>
								<Typography>Potencia</Typography>
								<Select
									size="small"
									fullWidth
									value={potencia}
									onChange={(e) => {
										setPotencia(e.target.value);
									}}
								>
									<MenuItem value=""></MenuItem>
									<MenuItem value="5">Baixa</MenuItem>
									<MenuItem value="20">Média</MenuItem>
									<MenuItem value="30">Alta</MenuItem>
								</Select>
							</Grid2>
							<Grid2 size={{ xs: 12, md: 6 }}>
								<Typography>Seção</Typography>
								<Select
									size="small"
									fullWidth
									value={seccao}
									onChange={(e) => {
										setSeccao(e.target.value);
									}}
								>
									<MenuItem value=""></MenuItem>
									<MenuItem value="0">Seção 0</MenuItem>
									<MenuItem value="1">Seção 1</MenuItem>
									<MenuItem value="2">Seção 2</MenuItem>
									<MenuItem value="3">Seção 3</MenuItem>
								</Select>
							</Grid2>
							<Grid2 size={{ xs: 12, md: 6 }}>
								<Typography>Estado</Typography>
								<Select
									size="small"
									fullWidth
									value={estado}
									onChange={(e) => {
										setEstado(e.target.value);
									}}
								>
									<MenuItem value=""></MenuItem>
									<MenuItem value="0">A</MenuItem>
									<MenuItem value="1">A~B</MenuItem>
									<MenuItem value="2">B</MenuItem>
								</Select>
							</Grid2>
							<Grid2 size={12}>
								<Typography>{t("common.printer")}</Typography>
								<TextField
									size="small"
									fullWidth
									value={impressora}
									onChange={(e) => {
										setImpressora(e.target.value);
									}}
								/>
							</Grid2>
							<Grid2 size={{ xs: 12, md: 6 }}>
								<Typography>Padrão</Typography>
								<Select
									size="small"
									fullWidth
									value={padrao}
									onChange={(e) => {
										setPadrao(e.target.value);
									}}
								>
									<MenuItem value=""></MenuItem>
									<MenuItem value="manual">Manual</MenuItem>
									<MenuItem value="gtin">GTIN</MenuItem>
									<MenuItem value="sgtin">sGTIN</MenuItem>
									<MenuItem value="one">InovaOne</MenuItem>
									<MenuItem value="epc">EPC API</MenuItem>
								</Select>
							</Grid2>
							<Grid2 size={{ xs: 12, md: 6 }}>
								<Typography>Verificador Inicial</Typography>
								<TextField
									size="small"
									fullWidth
									value={verificadorInicial}
									onChange={(e) => {
										setVerificadorInicial(e.target.value);
									}}
								/>
							</Grid2>
							<Grid2 size={{ xs: 12, md: 6 }}>
								<Typography>Posição</Typography>
								<TextField
									size="small"
									fullWidth
									value={posicao}
									onChange={(e) => {
										setPosicao(e.target.value);
									}}
								/>
							</Grid2>
							<Grid2 size={{ xs: 12, md: 6 }}>
								<Typography>Comprimento</Typography>
								<TextField
									size="small"
									fullWidth
									value={comprimento}
									onChange={(e) => {
										setComprimento(e.target.value);
									}}
								/>
							</Grid2>
							<Grid2 size={{ xs: 12, md: 6 }}>
								<Typography>Finalidade</Typography>
								<Select
									size="small"
									fullWidth
									value={finalidade}
									onChange={(e) => {
										setFinalidade(e.target.value);
									}}
								>
									<MenuItem value="1">Coletas</MenuItem>
									<MenuItem value="0">Inventários</MenuItem>
								</Select>
							</Grid2>
							<Grid2 size={{ xs: 12, md: 6 }}>
								<Typography>Desabilitar Items</Typography>
								<Select
									size="small"
									fullWidth
									value={disableItems}
									onChange={(e) => {
										setDisableItems(e.target.value);
									}}
								>
									<MenuItem value={true}>Sim</MenuItem>
									<MenuItem value={false}>Não</MenuItem>
								</Select>
							</Grid2>
							<Grid2 size={{ xs: 12, md: 6 }}>
								<FormControlLabel
									control={
										<Checkbox
											value={leituraInv}
											onClick={(e) => {
												setLeituraInv(e.target.checked);
											}}
										/>
									}
									label="Leitura tipo inventário"
								/>
							</Grid2>
							<Grid2 size={12}>
								<Typography variant="h6">Local do leitor</Typography>
							</Grid2>
							<Grid2 size={{ xs: 12, md: 6, lg: "grow" }}>
								<Typography>{t("common.level")} 1</Typography>
								<Select
									value={locNivel1}
									onChange={(e) => {
										setLocNivel1(e.target.value);
									}}
									size="small"
									fullWidth
								>
									<MenuItem value="">--</MenuItem>
									{nivel1List.map((nivel, index) => (
										<MenuItem key={index} value={nivel._id}>
											{nivel.descricao}
										</MenuItem>
									))}
								</Select>
							</Grid2>
							<Grid2 size={{ xs: 12, md: 6, lg: "grow" }}>
								<Typography>{t("common.level")} 2</Typography>
								<Select
									value={locNivel2}
									onChange={(e) => {
										setLocNivel2(e.target.value);
									}}
									size="small"
									fullWidth
								>
									<MenuItem value="">--</MenuItem>
									{nivel2List.map((nivel, index) => (
										<MenuItem key={index} value={nivel._id}>
											{nivel.descricao}
										</MenuItem>
									))}
								</Select>
							</Grid2>
							<Grid2 size={{ xs: 12, md: 6, lg: "grow" }}>
								<Typography>{t("common.level")} 3</Typography>
								<Select
									value={locNivel3}
									onChange={(e) => {
										setLocNivel3(e.target.value);
									}}
									size="small"
									fullWidth
								>
									<MenuItem value="">--</MenuItem>
									{nivel3List.map((nivel, index) => (
										<MenuItem key={index} value={nivel._id}>
											{nivel.descricao}
										</MenuItem>
									))}
								</Select>
							</Grid2>
							<Grid2 size={{ xs: 12, md: 6, lg: "grow" }}>
								<Typography>{t("common.level")} 4</Typography>
								<Select
									value={locNivel4}
									onChange={(e) => {
										setLocNivel4(e.target.value);
									}}
									size="small"
									fullWidth
								>
									<MenuItem value="">--</MenuItem>
									{nivel4List.map((nivel, index) => (
										<MenuItem key={index} value={nivel._id}>
											{nivel.descricao}
										</MenuItem>
									))}
								</Select>
							</Grid2>
							{finalidade == 1 ? (
								<>
									<Grid2 size={12}>
										<Typography variant="h6">Destino das leituras</Typography>
									</Grid2>
									<Grid2 size={{ xs: 12, md: 6, lg: "grow" }}>
										<Typography>{t("common.level")} 1</Typography>
										<Select
											value={destinoNivel1}
											onChange={(e) => {
												setDestinoNivel1(e.target.value);
											}}
											size="small"
											fullWidth
										>
											<MenuItem value="">--</MenuItem>
											{destNivel1List.map((nivel, index) => (
												<MenuItem key={index} value={nivel._id}>
													{nivel.descricao}
												</MenuItem>
											))}
										</Select>
									</Grid2>
									<Grid2 size={{ xs: 12, md: 6, lg: "grow" }}>
										<Typography>{t("common.level")} 2</Typography>
										<Select
											value={destinoNivel2}
											onChange={(e) => {
												setDestinoNivel2(e.target.value);
											}}
											size="small"
											fullWidth
										>
											<MenuItem value="">--</MenuItem>
											{destNivel2List.map((nivel, index) => (
												<MenuItem key={index} value={nivel._id}>
													{nivel.descricao}
												</MenuItem>
											))}
										</Select>
									</Grid2>
									<Grid2 size={{ xs: 12, md: 6, lg: "grow" }}>
										<Typography>{t("common.level")} 3</Typography>
										<Select
											value={destinoNivel3}
											onChange={(e) => {
												setDestinoNivel3(e.target.value);
											}}
											size="small"
											fullWidth
										>
											<MenuItem value="">--</MenuItem>
											{destNivel3List.map((nivel, index) => (
												<MenuItem key={index} value={nivel._id}>
													{nivel.descricao}
												</MenuItem>
											))}
										</Select>
									</Grid2>
									<Grid2 size={{ xs: 12, md: 6, lg: "grow" }}>
										<Typography>{t("common.level")} 4</Typography>
										<Select
											value={destinoNivel4}
											onChange={(e) => {
												setDestinoNivel4(e.target.value);
											}}
											size="small"
											fullWidth
										>
											<MenuItem value="">--</MenuItem>
											{destNivel4List.map((nivel, index) => (
												<MenuItem key={index} value={nivel._id}>
													{nivel.descricao}
												</MenuItem>
											))}
										</Select>
									</Grid2>
								</>
							) : (
								<>
									<Grid2 size={{ xs: 12 }}>
										<Typography>Reverso</Typography>
										<Select
											size="small"
											fullWidth
											value={reverso}
											onChange={(e) => {
												setReverso(e.target.value);
											}}
										>
											<MenuItem value="0">{t("common.no")}</MenuItem>
											<MenuItem value="1">{t("common.yes")}</MenuItem>
										</Select>
									</Grid2>
								</>
							)}
							<Grid2 size={12}>
								<Typography variant="h6">Saída</Typography>
							</Grid2>
							<Grid2 size={{ xs: 12, md: 6, lg: "grow" }}>
								<Typography>{t("common.level")} 1</Typography>
								<Select
									value={saidaNivel1}
									onChange={(e) => {
										setSaidaNivel1(e.target.value);
									}}
									size="small"
									fullWidth
								>
									<MenuItem value="">--</MenuItem>
									{saidaNivel1List.map((nivel, index) => (
										<MenuItem key={index} value={nivel._id}>
											{nivel.descricao}
										</MenuItem>
									))}
								</Select>
							</Grid2>
							<Grid2 size={{ xs: 12, md: 6, lg: "grow" }}>
								<Typography>{t("common.level")} 2</Typography>
								<Select
									value={saidaNivel2}
									onChange={(e) => {
										setSaidaNivel2(e.target.value);
									}}
									size="small"
									fullWidth
								>
									<MenuItem value="">--</MenuItem>
									{saidaNivel2List.map((nivel, index) => (
										<MenuItem key={index} value={nivel._id}>
											{nivel.descricao}
										</MenuItem>
									))}
								</Select>
							</Grid2>
							<Grid2 size={{ xs: 12, md: 6, lg: "grow" }}>
								<Typography>{t("common.level")} 3</Typography>
								<Select
									value={saidaNivel3}
									onChange={(e) => {
										setSaidaNivel3(e.target.value);
									}}
									size="small"
									fullWidth
								>
									<MenuItem value="">--</MenuItem>
									{saidaNivel3List.map((nivel, index) => (
										<MenuItem key={index} value={nivel._id}>
											{nivel.descricao}
										</MenuItem>
									))}
								</Select>
							</Grid2>
							<Grid2 size={{ xs: 12, md: 6, lg: "grow" }}>
								<Typography>{t("common.level")} 4</Typography>
								<Select
									value={saidaNivel4}
									onChange={(e) => {
										setSaidaNivel4(e.target.value);
									}}
									size="small"
									fullWidth
								>
									<MenuItem value="">--</MenuItem>
									{saidaNivel4List.map((nivel, index) => (
										<MenuItem key={index} value={nivel._id}>
											{nivel.descricao}
										</MenuItem>
									))}
								</Select>
							</Grid2>
							<Grid2 size={12}>
								<Typography>{t("common.observation")}</Typography>
								<TextField
									size="small"
									fullWidth
									multiline
									value={observacao}
									onChange={(e) => {
										setObservacao(e.target.value);
									}}
								/>
							</Grid2>
						</Grid2>
					</Stack>
				</Stack>
			</Container>

			{message ? (
				<Alert
					type={message.type}
					title={message.title}
					message={message.message}
					onClose={(e) => {
						message?.redirect ? navigate("/readers") : setMessage(e);
					}}
				/>
			) : (
				""
			)}
		</>
	);
}
