import React, { useState, useCallback } from "react";
import {
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Button,
	Typography,
	TextField,
	Stack,
	IconButton,
	Radio,
	RadioGroup,
	FormControlLabel,
	FormControl,
	FormLabel,
	InputAdornment,
	Select,
	MenuItem,
	InputLabel,
	Checkbox,
	Chip, // Adicionando o componente Chip para as tags
	Box, // Adicionando o componente Box para layout
} from "@mui/material";

import { styled } from "@mui/material/styles";
import { t } from "i18next";
import { Icon } from "@iconify/react/dist/iconify.js";

const StyledDialog = styled(Dialog)(({ theme }) => ({
	"& .MuiDialog-paper": {
		minWidth: 400,
		borderRadius: 24,
	},
	"& .MuiDialogContent-root": {
		padding: theme.spacing(3),
	},
	"& .MuiDialogActions-root": {
		padding: theme.spacing(1),
	},
}));

const AlertDialog = () => {
	const [open, setOpen] = useState(false);
	const [options, setOptions] = useState({});
	const [fields, setFields] = useState({}); // Para armazenar os valores dos campos

	const handleFieldChange = (name, value) => {
		setFields((prevFields) => ({ ...prevFields, [name]: value }));
	};

	const handleConfirm = () => {
		setOpen(false);
		if (options.onConfirm) {
			options.onConfirm(fields); // Envia os valores dos campos no callback
		}
	};

	const fire = useCallback((opts) => {
		setOptions(opts);
		// Inicializa os campos com os valores padrão (defaultValue)
		const initialFields = opts.fields
			? opts.fields.reduce(
					(acc, field) => ({
						...acc,
						[field.name]: field.defaultValue || (field.type === "multiselect" ? [] : ""), // Inicializa como array para multiselect
					}),
					{}
				)
			: {};
		setFields(initialFields);
		setOpen(true);
	}, []);

	const handleClose = () => {
		setOpen(false);
		if (options.onCancel) {
			options.onCancel();
		}
	};

	React.useEffect(() => {
		const handleEvent = (event) => {
			fire(event.detail);
		};

		window.addEventListener("alert-dialog-fire", handleEvent);

		return () => {
			window.removeEventListener("alert-dialog-fire", handleEvent);
		};
	}, [fire]);

	// Função para renderizar as tags abaixo do input
	const renderTags = (selected, field) => {
		return (
			<Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5, mt: 1 }}>
				{selected.map((value) => {
					const selectedOption = field.options.find((option) => option.value === value);
					return (
						<Chip
							key={value}
							label={selectedOption ? selectedOption.label : value}
							onDelete={() => {
								const newValue = selected.filter((v) => v !== value);
								handleFieldChange(field.name, newValue);
							}}
						/>
					);
				})}
			</Box>
		);
	};

	return (
		<StyledDialog open={open} onClose={handleClose}>
			{options.title && (
				<Stack direction="row" justifyContent="space-between">
					<Stack direction="row" spacing={1}>
						<DialogTitle>
							{options?.titleIcon && <Icon icon={options?.titleIcon} style={{ marginRight: 8 }} />}
							{options.title}
						</DialogTitle>
					</Stack>
					<IconButton
						aria-label="close"
						onClick={handleClose}
						sx={{
							position: "absolute",
							right: 8,
							top: 8,
							color: (theme) => theme.palette.grey[500],
						}}
					>
						<Icon icon="line-md:close" />
					</IconButton>
				</Stack>
			)}
			<DialogContent dividers>
				{options.preComponent && options.preComponent}
				<Typography variant="subtitle">{options.message}</Typography>
				{options.fields &&
					options.fields.map((field) => {
						if (field.type === "radio") {
							return (
								<FormControl key={field.name} margin="normal" fullWidth>
									<FormLabel>{field.label}</FormLabel>
									<RadioGroup
										row={field.row || false}
										value={fields[field.name] || ""}
										onChange={(e) => handleFieldChange(field.name, e.target.value)}
									>
										{field.options.map((option) => (
											<FormControlLabel
												key={option.value}
												value={option.value}
												control={<Radio />}
												label={option.label}
											/>
										))}
									</RadioGroup>
								</FormControl>
							);
						} else if (field.type === "select") {
							return (
								<FormControl key={field.name} margin="normal" fullWidth>
									<InputLabel>{field.label}</InputLabel>
									<Select
										value={fields[field.name] || ""}
										onChange={(e) => handleFieldChange(field.name, e.target.value)}
										label={field.label}
										fullWidth
									>
										{field.options.map((option) => (
											<MenuItem key={option.value} value={option.value}>
												{option.label}
											</MenuItem>
										))}
									</Select>
								</FormControl>
							);
						} else if (field.type === "multiselect") {
							return (
								<FormControl key={field.name} margin="normal" fullWidth>
									<InputLabel>{field.label}</InputLabel>
									<Select
										multiple // Habilita seleção múltipla
										value={fields[field.name] || []} // Valor é um array
										onChange={(e) => handleFieldChange(field.name, e.target.value)}
										label={field.label}
										fullWidth
										renderValue={(selected) => {
											// Exibe as labels dos valores selecionados
											const selectedLabels = selected.map((value) => {
												const selectedOption = field.options.find((option) => option.value === value);
												return selectedOption ? selectedOption.label : value;
											});
											return selectedLabels.join(", ");
										}}
									>
										{field.options.map((option) => (
											<MenuItem key={option.value} value={option.value}>
												<Checkbox
													checked={fields[field.name]?.indexOf(option.value) > -1} // Verifica se o valor está selecionado
												/>
												{option.label}
											</MenuItem>
										))}
									</Select>
									{/* Renderiza as tags abaixo do input */}
									{renderTags(fields[field.name] || [], field)}
								</FormControl>
							);
						} else {
							return (
								<FormControl key={field.name} margin="normal" fullWidth>
									{field.formLabel && <FormLabel>{field.formLabel}</FormLabel>}
									<TextField
										label={field.label}
										type={field.type || "text"}
										value={fields[field.name] || ""}
										onChange={(e) => handleFieldChange(field.name, e.target.value)}
										fullWidth
										size={field?.size || "small"}
										InputProps={{
											startAdornment: field?.startIcon ? (
												<InputAdornment position="start">
													<Icon icon={field.startIcon} />
												</InputAdornment>
											) : null,
										}}
									/>
								</FormControl>
							);
						}
					})}
				{options.postComponent && options.postComponent}
			</DialogContent>
			<DialogActions>
				{options.cancelText && (
					<Button variant="outlined" color="dark" onClick={handleClose}>
						{options.cancelText}
					</Button>
				)}
				{options.confirmText && (
					<Button variant="contained" color={options?.confirmTextColor || "error"} onClick={handleConfirm}>
						{options.confirmText}
					</Button>
				)}
			</DialogActions>
		</StyledDialog>
	);
};

// Expondo o método fire
AlertDialog.fire = (options) => {
	const event = new CustomEvent("alert-dialog-fire", { detail: options });
	window.dispatchEvent(event);
};

export default AlertDialog;
