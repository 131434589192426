import { useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";

import { t } from "i18next";

import {
	Button,
	Container,
	FormControl,
	Grid2,
	IconButton,
	MenuItem,
	Paper,
	Select,
	Stack,
	Tooltip,
	Typography,
} from "@mui/material";

import api from "../../services/api";

import { FormLabel } from "react-bootstrap";

import LocationForm from "./LocationForm";

import Loading from "../../components/loading";

import { Icon } from "@iconify/react/dist/iconify.js";

import Dropdown from "../../components/ui/Dropdown";

import Alert from "../../components/ui/Alert";

import AlertDialog from "../../components/AlertDialog";

//----------------------------------------

export default function Locations() {
	const navigate = useNavigate();

	const [labels, setLabels] = useState({});

	const [contaID, setContaID] = useState("");

	const [message, setMessage] = useState(); // Armazena mensagens e erros

	const [loading, setLoading] = useState(false);

	const [locNew, setLocNew] = useState(false);

	const [locNivel1, setLocNivel1] = useState("");

	const [locNivel2, setLocNivel2] = useState("");

	const [locNivel3, setLocNivel3] = useState("");

	const [locNivel4, setLocNivel4] = useState("");

	// Listas de niveis
	const [nivel1List, setNivel1List] = useState([]);

	const [nivel2List, setNivel2List] = useState([]);

	const [nivel3List, setNivel3List] = useState([]);

	const [nivel4List, setNivel4List] = useState([]);

	useEffect(() => {
		getModels();
	}, []);

	function getModels() {
		setLoading(true);
		api.get(`/conta_config/${sessionStorage.getItem("idConta")}`).then((response) => {
			let data = response.data[0] ? response.data[0] : [];
			setContaID(data?._id);
			const labels = {
				nivel_1: data?.nivel_1 || `${t("common.level")} 1`,
				nivel_2: data?.nivel_2 || `${t("common.level")} 2`,
				nivel_3: data?.nivel_3 || `${t("common.level")} 3`,
				nivel_4: data?.nivel_4 || `${t("common.level")} 4`,
			};
			setLabels(labels);
			setLoading(false);
		});
	}

	useEffect(() => {
		nivel1Get();
	}, []);

	//Get Loc List
	useEffect(() => {
		nivelGet(2, locNivel1, setNivel2List, locNivel2, setLocNivel2);
	}, [locNivel1]);

	useEffect(() => {
		nivelGet(3, locNivel2, setNivel3List, locNivel3, setLocNivel3);
	}, [locNivel2]);

	useEffect(() => {
		nivelGet(4, locNivel3, setNivel4List, locNivel4, setLocNivel4);
	}, [locNivel3]);

	function nivel1Get() {
		setLoading(true);
		api
			.get(`/nivel_loc1/${sessionStorage.getItem("idConta")}/*/*/*/*`)
			.then((response) => {
				const nivel1 = response.data;
				setNivel1List(nivel1);
			})
			.finally(() => {
				setLoading(false);
			});
	}

	async function nivelGet(preNivel, id_pre_nivel, setListFunction, currentValue, setNivelFunction) {
		if (id_pre_nivel && id_pre_nivel !== "*") {
			await api
				.get(`/nivel_loc${preNivel}/${sessionStorage.getItem("idConta")}/${id_pre_nivel || "*"}/*/*/*/*`)
				.then((response) => {
					const nivel = response.data;
					setListFunction(nivel);
					if (!nivel.find((item) => item._id === currentValue)) {
						setNivelFunction("");
					}
				});
		} else {
			setListFunction([]);
			if (currentValue) {
				setNivelFunction("");
			}
		}
	}

	async function handleExport(type) {
		const niveis = [];
		const id_conta = sessionStorage.getItem("idConta");
		const urls = [
			`/nivel_loc1/${id_conta}/*/*/*/*`,
			`/nivel_loc2/${id_conta}/*/*/*/*/*`,
			`/nivel_loc3/${id_conta}/*/*/*/*/*`,
			`/nivel_loc4/${id_conta}/*/*/*/*/*`,
		];

		try {
			for (const [index, url] of urls.entries()) {
				const response = await api.get(url);
				const data = response?.data
					.filter((item) => item._id)
					.map((item) => {
						const id_nivel_loc = item[`id_nivel_loc${index}`] || "";
						return [
							item?.descricao || "",
							item?.observacao || "",
							item?.ativo || "",
							item?.tag || "",
							item?.cor || "",
							id_nivel_loc,
						];
					});
				niveis.push(...data);
			}

			const info = {
				headers: ["DESCRIÇÃO", "OBSERVAÇÃO", "ATIVO", "TAG", "COR", "NIVEL VINCULADO"],
				list: niveis,
				module: "niveis",
				type,
				redirectTo: "/locations",
			};

			navigate("/export", { state: { info } });
		} catch (error) {
			console.error("Error fetching niveis:", error);
			setMessage({
				type: "error",
				title: t("messages.errors.error"),
				message: t("messages.errors.500"),
			});
		}
	}

	function handleLabel(index) {
		AlertDialog.fire({
			title: `${t("actions.edit")} ${t("common.level")}`,
			message: `${t("settings.change_label_description.nivel")}`,
			confirmText: t("actions.continue"),
			cancelText: t("actions.cancel"),
			fields: [{ name: "label", formLabel: t("common.level"), type: "text" }],
			onConfirm: (data) => {
				if (data?.label && contaID) {
					let reg = {
						_id: contaID,
						id_conta: sessionStorage.getItem("idConta"),
					};
					reg[`nivel_${index}`] = data?.label;
					setLoading(true);
					api
						.post(`/conta_config`, [reg])
						.then(
							(response) => {
								getModels();
								setMessage({
									type: "success",
									message: t("messages.success_save"),
									title: t("messages.success"),
								});
							},
							(error) => {
								setMessage({
									type: "error",
									message: t("messages.errors.500"),
									title: t("messages.errors.error"),
								});
							}
						)
						.finally(() => {
							setLoading(false);
						});
				} else {
					setMessage({
						type: "error",
						message: t("messages.errors.500"),
						title: t("messages.errors.error"),
					});
				}
			},
			onCancel: () => console.log("Formulário cancelado"),
		});
	}

	return (
		<>
			<Stack spacing={3}>
				<Stack direction="row" justifyContent="space-between">
					<Typography variant="h1">{t("common.levels")}</Typography>
					<Stack direction="row" spacing={1}>
						<Dropdown color="dark" text={`${t("actions.export")}`}>
							<MenuItem
								onClick={(e) => {
									handleExport("PDF");
								}}
							>
								PDF
							</MenuItem>
							<MenuItem
								onClick={(e) => {
									handleExport("CSV");
								}}
							>
								CSV
							</MenuItem>
						</Dropdown>
						<Button
							variant="outlined"
							color="dark"
							onClick={() => {
								navigate("/locations/import");
							}}
						>
							{t("actions.import")}
						</Button>
					</Stack>
				</Stack>

				<Grid2 container direction="row" justifyContent="center" spacing={3} columns={{ xs: 3, md: 6, lg: 12 }}>
					{/* Nivel 1 */}
					<Grid2 item size={3} as={Paper} sx={{ borderRadius: 3 }}>
						<Stack
							direction="row"
							justifyContent="space-between"
							alignItems="center"
							sx={{
								p: 3,
								border: 0,
								borderBottom: 1,
								borderColor: "divider",
								borderStyle: "dashed",
							}}
						>
							<Typography variant="h5">{labels?.nivel_1 ?? t("common.level") + " 1"}</Typography>
							<Tooltip title={t("settings.change_label_description.nivel")}>
								<IconButton
									onClick={() => {
										handleLabel(1);
									}}
								>
									<Icon icon="mage:edit" />
								</IconButton>
							</Tooltip>
						</Stack>
						<Stack spacing={3} sx={{ p: 3 }}>
							<FormControl>
								<FormLabel>{labels?.nivel_1 ?? t("common.level") + " 1"}</FormLabel>
								<Select
									size="small"
									value={locNivel1}
									onChange={(e) => {
										setLocNivel1(e.target.value);
									}}
								>
									<MenuItem value={null}>--</MenuItem>
									{nivel1List.map((nivel) => (
										<MenuItem key={nivel._id} value={nivel._id}>
											{nivel.descricao}
										</MenuItem>
									))}
								</Select>
							</FormControl>
							{locNivel1 || locNew == 1 ? (
								<LocationForm
									level="1"
									nivel={nivel1List.find((nivel) => nivel._id == locNivel1)}
									onUpdate={() => {
										setLocNew(false);
										setLocNivel1(null);
										nivel1Get();
									}}
								/>
							) : (
								<Button
									variant="contained"
									startIcon={<Icon icon="mage:plus" />}
									onClick={() => {
										setLocNew("1");
									}}
								>
									{t("actions.new")}
								</Button>
							)}
						</Stack>
					</Grid2>

					{/* Nivel 2 */}
					<Grid2 item size={3} as={Paper} sx={{ borderRadius: 3 }}>
						<Stack
							direction="row"
							justifyContent="space-between"
							alignItems="center"
							sx={{
								p: 3,
								border: 0,
								borderBottom: 1,
								borderColor: "divider",
								borderStyle: "dashed",
							}}
						>
							<Typography variant="h5">{labels?.nivel_2 ?? t("common.level") + " 2"}</Typography>
							<Tooltip title={t("settings.change_label_description.nivel")}>
								<IconButton
									onClick={() => {
										handleLabel(2);
									}}
								>
									<Icon icon="mage:edit" />
								</IconButton>
							</Tooltip>
						</Stack>
						<Stack spacing={3} sx={{ p: 3 }}>
							<FormControl>
								<FormLabel>{labels?.nivel_2 ?? t("common.level") + " 2"}</FormLabel>
								<Select
									size="small"
									value={locNivel2}
									onChange={(e) => {
										setLocNivel2(e.target.value);
									}}
								>
									<MenuItem value={null}>--</MenuItem>
									{nivel2List.map((nivel) => (
										<MenuItem key={nivel._id} value={nivel._id}>
											{nivel.descricao}
										</MenuItem>
									))}
								</Select>
							</FormControl>

							{locNivel2 || locNew == 2 ? (
								<LocationForm
									level="2"
									preValue={{ id_nivel_loc1: locNivel1 }}
									nivel={nivel2List.find((nivel) => nivel._id == locNivel2)}
									onUpdate={() => {
										setLocNew(false);
										setLocNivel2(null);
										nivelGet(2, locNivel1, setNivel2List, locNivel2, setLocNivel2);
									}}
								/>
							) : (
								<Button
									variant="contained"
									disabled={!locNivel1}
									startIcon={<Icon icon="mage:plus" />}
									onClick={() => {
										setLocNew(2);
									}}
								>
									{t("actions.new")}
								</Button>
							)}
						</Stack>
					</Grid2>

					{/* Nivel 3 */}
					<Grid2 item size={3} as={Paper} sx={{ borderRadius: 3 }}>
						<Stack
							direction="row"
							justifyContent="space-between"
							alignItems="center"
							sx={{
								p: 3,
								border: 0,
								borderBottom: 1,
								borderColor: "divider",
								borderStyle: "dashed",
							}}
						>
							<Typography variant="h5">{labels?.nivel_3 ?? t("common.level") + " 3"}</Typography>
							<Tooltip title={t("settings.change_label_description.nivel")}>
								<IconButton
									onClick={() => {
										handleLabel(3);
									}}
								>
									<Icon icon="mage:edit" />
								</IconButton>
							</Tooltip>
						</Stack>
						<Stack spacing={3} sx={{ p: 3 }}>
							<FormControl>
								<FormLabel>{labels?.nivel_3 ?? t("common.level") + " 3"}</FormLabel>
								<Select
									size="small"
									value={locNivel3}
									onChange={(e) => {
										setLocNivel3(e.target.value);
									}}
								>
									<MenuItem value={null}>--</MenuItem>
									{nivel3List.map((nivel) => (
										<MenuItem key={nivel._id} value={nivel._id}>
											{nivel.descricao}
										</MenuItem>
									))}
								</Select>
							</FormControl>
							{locNivel3 || locNew == 3 ? (
								<LocationForm
									level="3"
									preValue={{ id_nivel_loc2: locNivel2 }}
									nivel={nivel3List.find((nivel) => nivel._id == locNivel3)}
									onUpdate={() => {
										setLocNew(false);
										setLocNivel3(null);
										nivelGet(3, locNivel2, setNivel3List, locNivel3, setLocNivel3);
									}}
								/>
							) : (
								<Button
									variant="contained"
									startIcon={<Icon icon="mage:plus" />}
									onClick={() => {
										setLocNew(3);
									}}
									disabled={!locNivel2}
								>
									{t("actions.new")}
								</Button>
							)}
						</Stack>
					</Grid2>

					{/* Nivel 4 */}
					<Grid2 item size={3} as={Paper} sx={{ borderRadius: 3 }}>
						<Stack
							direction="row"
							justifyContent="space-between"
							alignItems="center"
							sx={{
								p: 3,
								border: 0,
								borderBottom: 1,
								borderColor: "divider",
								borderStyle: "dashed",
							}}
						>
							<Typography variant="h5">{labels?.nivel_4 ?? t("common.level") + " 4"}</Typography>
							<Tooltip title={t("settings.change_label_description.nivel")}>
								<IconButton
									onClick={() => {
										handleLabel(4);
									}}
								>
									<Icon icon="mage:edit" />
								</IconButton>
							</Tooltip>
						</Stack>
						<Stack spacing={3} sx={{ p: 3 }}>
							<FormControl>
								<FormLabel>{labels?.nivel_4 ?? t("common.level") + " 4"}</FormLabel>
								<Select
									size="small"
									value={locNivel4}
									onChange={(e) => {
										setLocNivel4(e.target.value);
									}}
								>
									<MenuItem value={null}>--</MenuItem>
									{nivel4List.map((nivel) => (
										<MenuItem key={nivel._id} value={nivel._id}>
											{nivel.descricao}
										</MenuItem>
									))}
								</Select>
							</FormControl>
							{locNivel4 || locNew == 4 ? (
								<LocationForm
									level="4"
									preValue={{ id_nivel_loc3: locNivel3 }}
									nivel={nivel4List.find((nivel) => nivel._id == locNivel4)}
									onUpdate={() => {
										setLocNew(false);
										setLocNivel4(null);
										nivelGet(4, locNivel3, setNivel4List, locNivel4, setLocNivel4);
									}}
								/>
							) : (
								<Button
									variant="contained"
									startIcon={<Icon icon="mage:plus" />}
									onClick={() => {
										setLocNew(4);
									}}
									disabled={!locNivel3}
								>
									{t("actions.new")}
								</Button>
							)}
						</Stack>
					</Grid2>
				</Grid2>
			</Stack>

			<Loading show={loading} />

			{message ? (
				<Alert
					type={message.type}
					title={message.title}
					message={message.message}
					onClose={(e) => {
						setMessage(e);
					}}
				/>
			) : (
				""
			)}
		</>
	);
}
