import { useEffect, useState } from "react";

import HeatMap from "../../components/ReCharts/HeatMap";

import dayjs from "dayjs";

import { FormControl, Grid2, InputLabel, MenuItem, Select, Stack } from "@mui/material";

import { t } from "i18next";

//------------------------------------------------------

export default function ItemsHeatmap({ data }) {
	const [acoes, setAcoes] = useState([]);
	const [filterByMonth, setFilterByMonth] = useState(dayjs().month());
	const [filterByYear, setFilterByYear] = useState(dayjs().year());

	// Gerar categorias no formato dd/mm
	const generateCategories = (month, year) => {
		const daysInMonth = new Date(year, month + 1, 0).getDate();
		const categories = [];

		for (let day = 1; day <= daysInMonth; day++) {
			categories.push(`${String(day)}`);
		}

		return categories;
	};

	const handleData = () => {
		const start = dayjs().year(filterByYear).month(filterByMonth).startOf("month").toDate();
		const end = dayjs().year(filterByYear).month(filterByMonth).endOf("month").toDate();

		// Filtrar os dados para o intervalo selecionado
		const filteredData = data?.filter((item) => {
			const itemDate = new Date(item.data);
			return itemDate >= start && itemDate <= end;
		});

		// Gerar categorias dinamicamente
		const categories = generateCategories(filterByMonth, filterByYear);

		const formattedResult = [];

		data?.forEach((item) => {
			const user = item?.usuario;

			// Inicializar um array para armazenar as ações por dia
			const actionsPerDay = Array(categories.length).fill(0);

			categories.forEach((date, index) => {
				const day = index + 1; // Ajuste para indexação baseada em 1
				actionsPerDay[index] = filteredData?.filter(
					(filteredItem) =>
						filteredItem.usuario === user &&
						new Date(filteredItem.data).toLocaleDateString("pt-BR") ===
							new Date(filterByYear, filterByMonth, day).toLocaleDateString("pt-BR")
				).length;
			});

			formattedResult[user] = {
				name: user,
				data: actionsPerDay,
			};
		});

		setAcoes(Object.values(formattedResult));
	};

	const generateYears = () => {
		const currentYear = new Date().getFullYear();
		const years = [];
		for (let year = 2023; year <= currentYear; year++) {
			years.push(year);
		}
		return years;
	};

	useEffect(() => {
		handleData();
	}, [filterByMonth, filterByYear]);

	return (
		<>
			<Stack spacing={3}>
				<Grid2 container spacing={1}>
					<Grid2 size={{ xs: 12, md: 3 }}>
						<FormControl fullWidth>
							<InputLabel>{t("common.month")}</InputLabel>
							<Select
								labelId="month-label"
								id="month"
								value={filterByMonth}
								label={t("common.month")}
								onChange={(e) => {
									setFilterByMonth(e.target.value);
								}}
								sx={{ textTransform: "capitalize" }}
							>
								{Array(12)
									.fill(0)
									.map((month, index) => (
										<MenuItem key={index} value={index} sx={{ textTransform: "capitalize" }}>
											{dayjs().set("month", index).format("MMM")}
										</MenuItem>
									))}
							</Select>
						</FormControl>
					</Grid2>
					<Grid2 size={{ xs: 12, md: "auto" }}>
						<FormControl fullWidth>
							<InputLabel>{t("common.year")}</InputLabel>
							<Select
								labelId="year-label"
								id="year"
								value={filterByYear}
								label={t("common.year")}
								onChange={(e) => {
									setFilterByYear(e.target.value);
								}}
								sx={{ textTransform: "capitalize" }}
							>
								{generateYears().map((year) => (
									<MenuItem key={year} value={year}>
										{year}
									</MenuItem>
								))}
							</Select>
						</FormControl>
					</Grid2>
				</Grid2>
				<HeatMap data={acoes} categories={generateCategories(filterByMonth, filterByYear)} />
			</Stack>
		</>
	);
}
