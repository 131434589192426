import { useState } from "react";

import { Button, Grid2, InputAdornment, Menu, MenuItem, TextField } from "@mui/material";
import { Icon } from "@iconify/react/dist/iconify.js";
import { t } from "i18next";
import axios from "axios";

export default function ImportERP({ onUpload = () => {} }) {
	const [loading, setLoading] = useState(false);

	const [message, setMessage] = useState();

	const [URL, setURL] = useState();

	const [types, setTypes] = useState({
		prefix: process.env.REACT_APP_API_URL + "/",
		label: "InovaOne",
		value: "one",
	});

	const [anchorEl, setAnchorEl] = useState(null);

	const openTypes = Boolean(anchorEl);

	async function handleSearch() {
		axios
			.get((types?.prefix || "") + URL)
			.then((res) => {
				const data = res?.data;
				onUpload(data);
			})
			.catch((err) => {
				setMessage({
					type: "error",
					title: t("messages.errors.error"),
					message: t("messages.errors.500"),
				});
			})
			.finally(() => {
				setLoading(false);
			});
	}

	return (
		<>
			<Grid2 container alignItems="center" spacing={1}>
				<Grid2 size={5}>
					<TextField
						size="small"
						variant="outlined"
						value={URL}
						onChange={(e) => {
							setURL(e.target.value);
						}}
						fullWidth
						InputProps={{
							startAdornment: <InputAdornment position="start">{types?.prefix}</InputAdornment>,
							endAdornment: (
								<InputAdornment position="end" sx={{ py: 1, m: 0 }}>
									<Button
										size="small"
										color="dark"
										id="basic-button"
										aria-controls={openTypes ? "basic-menu" : undefined}
										aria-haspopup="true"
										aria-expanded={openTypes ? "true" : undefined}
										onClick={(event) => {
											setAnchorEl(event.currentTarget);
										}}
										startIcon={<Icon icon="mage:link" />}
										endIcon={<Icon icon="iconamoon:arrow-down-2-bold" />}
										sx={{ backgroundColor: "grey.200" }}
									>
										{types?.label}
									</Button>
								</InputAdornment>
							),
						}}
					/>
					<Menu
						id="basic-menu"
						anchorEl={anchorEl}
						open={openTypes}
						onClose={() => {
							setAnchorEl(null);
						}}
						MenuListProps={{
							"aria-labelledby": "basic-button",
						}}
						transformOrigin={{ horizontal: "right", vertical: "top" }}
						anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
					>
						<MenuItem
							onClick={() => {
								setTypes({
									prefix: process.env.REACT_APP_API_URL + "/",
									label: "InovaOne",
									value: "one",
								});
							}}
						>
							InovaOne
						</MenuItem>
						<MenuItem
							onClick={() => {
								setTypes({
									prefix: `https://inovabo.herokuapp.com/`,
									label: "InovaCenter",
									value: "center",
								});
							}}
						>
							InovaCenter
						</MenuItem>
						<MenuItem
							onClick={() => {
								setTypes({
									prefix: "",
									label: "Outra API",
									value: "url",
								});
							}}
						>
							Outra API
						</MenuItem>
					</Menu>
				</Grid2>
				<Grid2 size={1}>
					<Button
						color="primary"
						variant="outlined"
						startIcon={<Icon icon="mage:search" />}
						onClick={() => handleSearch()}
					>
						{t("actions.search")}
					</Button>
				</Grid2>
			</Grid2>
		</>
	);
}
