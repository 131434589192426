import { useState } from "react";

import { t } from "i18next";

import { Icon } from "@iconify/react";

import CustomModal from "../../components/ui/CustomModal";

import { Backdrop, Box, Button, CircularProgress, Stack, Typography } from "@mui/material";

import api from "../../services/api";

import Alert from "../../components/ui/Alert";
import AlertDialog from "../../components/AlertDialog";

// ----------------------------------------------------------------

export default function CleanBase({ email, haveAuthenticator }) {
	const [loading, setLoading] = useState(false);

	const [confirmModal, setConfirmModal] = useState(false); // mostrar o modal pedindo confirmação

	const [message, setMessage] = useState();

	const [requestEmail, setRequestEmail] = useState();

	function handleConfirm() {
		setConfirmModal(false);
		if (haveAuthenticator) {
			handleAuthModal();
		} else {
			setLoading(true);

			let option = {
				headers: { "Content-Type": ["application/json"] },
			};

			api
				.post(`/create2fa/${sessionStorage.getItem("idConta")}?emailSend=${email}`, option)
				.then((response) => {
					setLoading(false);
					setRequestEmail({
						icon: "flat-color-icons:ok",
						title: t("messages.sucess_email"),
						message: `
                            <>
                                <Typography>${t("settings.clean_base.send_email_1")}</Typography>
                                <Typography>${email}</Typography>
                                <Typography>${t("settings.clean_base.send_email_2")}</Typography>
                            </>
                        `,
					});
				})
				.catch((error) => {
					setLoading(false);
					if (error.response.status === 401) {
						handleAuthModal();
					} else {
						setMessage({
							type: "error",
							title: t("messages.errors.error"),
							message: t("messages.errors.500"),
						});
					}
				});
		}
	}

	function handleAuth(cod) {
		setLoading(true);

		let option = {
			headers: { TOKENKEY: [cod] },
		};
		api
			.delete(`conta/${sessionStorage.getItem("idConta")}`, option)
			.then((response) => {
				setMessage({
					type: "success",
					title: t("messages.success"),
					message: t("settings.clean_base.success_message"),
				});
			})
			.catch((error) => {
				if (error.response.status === 401) {
					setMessage({
						type: "error",
						title: t("messages.errors.error"),
						message: t("settings.clean_base.code_invalid"),
					});
				} else {
					setMessage({
						type: "error",
						title: t("messages.errors.error"),
						message: t("messages.errors.500"),
					});
				}
			})
			.finally(() => {
				setLoading(false);
			});
	}

	function handleAuthModal() {
		AlertDialog.fire({
			title: t("settings.clean_base.clean_base"),
			titleIcon: "ic:round-warning",
			confirmText: t("actions.continue"),
			confirmTextColor: "primary",
			cancelText: t("actions.cancel"),
			preComponent: (
				<>
					<Stack alignItems="center" sx={{ my: 3 }}>
						<Box
							component="img"
							sx={{
								height: 120,
								width: 120,
							}}
							alt="logomarca Google authenticator"
							src={"/assets/img/google-authenticator.svg"}
						/>
					</Stack>

					<Typography variant="h1">{t("settings.clean_base.authenticator")}</Typography>
				</>
			),
			fields: [
				{
					name: "cod",
					formLabel: t("settings.clean_base.code_request"),
					type: "text",
					startIcon: "mingcute:lock-fill",
					size: "small",
				},
			],
			onConfirm: (data) => {
				handleAuth(data?.cod);
			},
			onCancel: () => console.log("Formulário cancelado"),
		});
	}

	return (
		<>
			<Button
				variant="outlined"
				color="dark"
				onClick={(e) => {
					setConfirmModal(!confirmModal);
				}}
				startIcon={<Icon icon="mi:delete" />}
			>
				{t("settings.clean_base.clean_base")}
			</Button>

			{confirmModal ? (
				<CustomModal
					title={t("settings.clean_base.clean_base")}
					onConfirm={(e) => {
						handleConfirm();
					}}
					onDeny={(e) => {
						setConfirmModal(false);
					}}
				>
					<Typography variant="h1" sx={{ mb: 2 }}>
						{t("messages.attention")}
					</Typography>
					<Typography variant="subtitle">{t("settings.clean_base.description")}</Typography>
					<Typography color="error" variant="subtitle" paragraph sx={{ my: 1 }}>
						{t("settings.clean_base.warning")}
					</Typography>
					<Typography variant="bold">{t("messages.confirm.ask_confirm")}</Typography>
				</CustomModal>
			) : (
				""
			)}

			{message ? (
				<Alert
					type={message.type}
					title={message.title}
					message={message.message}
					onClose={(e) => {
						setMessage(null);
					}}
				/>
			) : (
				""
			)}

			{requestEmail ? (
				<CustomModal
					icon={requestEmail.icon}
					title={requestEmail.title}
					onDeny={(e) => {
						requestEmail(null);
					}}
					onConfirm={(e) => {
						handleAuthModal();
					}}
				>
					<Typography variant="subtitle">{t("settings.clean_base.send_email_1")}</Typography>
					<Typography variant="subtitle" color="primary">
						{email}
					</Typography>
					<Typography variant="subtitle"> {t("settings.clean_base.send_email_2")}</Typography>
				</CustomModal>
			) : (
				""
			)}

			<Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading}>
				<CircularProgress color="inherit" />
			</Backdrop>
		</>
	);
}
