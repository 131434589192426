import secureLocalStorage from "react-secure-storage";

const custom_menu = secureLocalStorage.getItem("custom-menu") ?? null;

const linkList = [
	{
		title: "home",
		path: "/home",
		icon: "ant-design:home-filled",
		show: true,
	},
	{
		title: "bi",
		path: "/bi",
		icon: "mage:dashboard-bar",
		show: custom_menu ? (custom_menu["bi"] ?? false) : true,
	},
	{
		title: "inventories",
		path: "/collections/inventories",
		icon: "solar:clipboard-list-linear",
		show: custom_menu ? (custom_menu["inventarios"] ?? false) : true,
	},
	{
		title: "inventories_extern",
		path: "/collections/extern-inventories",
		icon: "solar:clipboard-list-broken",
		show: custom_menu ? (custom_menu["inventarios_externo"] ?? false) : true,
	},
	{
		title: "registers",
		path: "/registers",
		icon: "fluent:qr-code-28-regular",
		show: custom_menu ? (custom_menu["registros"] ?? false) : true,
	},
	{
		title: "collections",
		path: "/collections/collects",
		icon: "fluent:box-edit-20-regular",
		show: custom_menu ? (custom_menu["coletas"] ?? false) : true,
	},
	{
		title: "items",
		path: "/items",
		icon: "fluent:tag-multiple-24-regular",
		show: custom_menu ? (custom_menu["itens"] ?? false) : true,
	},
	{
		title: "categories",
		path: "/categories",
		icon: "mingcute:list-check-3-line",
		show: custom_menu ? (custom_menu["categorias"] ?? false) : true,
	},
	{
		title: "locate",
		path: "/locations",
		icon: "uiw:map",
		show: custom_menu ? (custom_menu["localizacoes"] ?? false) : true,
	},
	{
		title: "form",
		path: "/form",
		icon: "fluent:content-settings-24-regular",
		show: custom_menu ? (custom_menu["formularios"] ?? false) : true,
	},
	{
		title: "order",
		path: "/orders",
		icon: "hugeicons:file-dollar",
		show: custom_menu ? (custom_menu["ordem_fabricacao"] ?? false) : true,
	},
	{
		title: "print",
		path: "/print",
		icon: "fluent:document-print-48-regular",
		show: custom_menu ? (custom_menu["impressoes"] ?? false) : true,
	},
	{
		title: "print-order",
		path: "/print-order",
		icon: "fluent:print-48-regular",
		show: custom_menu ? (custom_menu["ordem_impressao"] ?? false) : true,
	},
	{
		title: "printers",
		path: "/print",
		icon: "fluent:print-add-24-regular",
		show: custom_menu ? (custom_menu["impressoras"] ?? false) : true,
	},
	{
		title: "partners",
		path: "/partners",
		icon: "heroicons:user-group-20-solid",
		show: true,
	},
	{
		title: "readers",
		path: "/readers",
		icon: "material-symbols-light:barcode-reader-outline",
		show: custom_menu ? (custom_menu["leitores"] ?? false) : true,
	},
	{
		title: "register",
		path: "/types",
		icon: "fluent-mdl2:document-management",
		show: custom_menu ? (custom_menu["tipos_registros"] ?? false) : true,
	},
	{
		title: "balance-sheet",
		path: "/balance-sheet",
		icon: "majesticons:clipboard-line",
		show: custom_menu ? (custom_menu["balancete"] ?? false) : true,
	},
	{
		title: "maps",
		path: "/maps",
		icon: "mdi:map-search-outline",
		show: custom_menu ? (custom_menu["mapas"] ?? false) : true,
	},
	{
		title: "monitoring",
		path: "/monitoring",
		icon: "fluent:document-search-16-regular",
		show: custom_menu ? (custom_menu["monitoramento"] ?? false) : true,
	},
	{
		title: "logs",
		path: "/logs",
		icon: "majesticons:clipboard-line",
		show: custom_menu ? (custom_menu["logs"] ?? false) : true,
	},
	{
		title: "history",
		path: "/items/activity",
		icon: "fluent-mdl2:date-time",
		show: sessionStorage.getItem("perfil") === "admin" ?? false,
	},
	{
		title: "account",
		path: "/account",
		icon: "solar:settings-linear",
		show: sessionStorage.getItem("perfil") === "admin" ?? false,
	},
];

export default linkList;
