import { useEffect, useState } from "react";

import { t } from "i18next";

import Loading from "../../components/ui/Loading";

import api from "../../services/api";

import {
	Box,
	Button,
	FormControl,
	IconButton,
	InputLabel,
	MenuItem,
	Modal,
	Paper,
	Select,
	Stack,
	styled,
	Typography,
} from "@mui/material";

import { Icon } from "@iconify/react/dist/iconify.js";

import { DataGrid } from "@mui/x-data-grid";

import { ptBR } from "@mui/x-data-grid/locales";

import { useNavigate, useParams } from "react-router-dom";

import Alert from "../../components/ui/Alert";

import ImportXML from "../../components/Import/ImportXML.js";

import ConfigImport from "../../components/Import/ConfigImport.js";

import ImportCSV from "../../components/Import/ImportCSV.js";

// ----------------------------------------------------------

const VisuallyHiddenInput = styled("input")({
	clip: "rect(0 0 0 0)",
	clipPath: "inset(50%)",
	height: "100%",
	overflow: "hidden",
	position: "absolute",
	bottom: 0,
	left: 0,
	whiteSpace: "nowrap",
	width: "100%",
});

const columns = [
	{
		field: "id",
		headerName: "ID",
		width: 90,
	},
	{
		field: "categoria",
		headerName: "Categoria",
		width: 200,
	},
	{
		field: "ean",
		headerName: "EAN",
		width: 200,
	},
	{
		field: "quantidade",
		headerName: "Quantidade",
		editable: true,
		width: 200,
	},
	{
		field: "quantidade_lida",
		headerName: "Quantidade lida",
		editable: true,
		width: 200,
	},
	{
		field: "quantidade_auditada",
		headerName: "Quantidade auditada",
		editable: true,
		width: 200,
	},
	{
		field: "observacao",
		headerName: "Observacao",
		width: 300,
		editable: true,
	},
	{
		field: "isValid",
		headerName: "Categoria cadastrada",
		width: 300,
		renderCell: (params) => (
			<Icon
				color={params.row.isValid ? "#4caf50" : "#f44336"}
				icon={params.row.isValid ? "pajamas:check-xs" : "pajamas:close"}
			/>
		),
	},
];

const orderFields = [
	{ label: "Categoria", value: "id_categoria" },
	{ label: "EAN", value: "ean" },
	{ label: "Quantidade", value: "quantidade" },
	{ label: "Quantidade Lida", value: "quantidade_lida" },
	{ label: "Quantidade Auditada", value: "quantidade_auditada" },
	{ label: "Observação", value: "observacao" },
];

export default function OrderImport() {
	const navigate = useNavigate();

	const { format } = useParams();

	const [loading, setLoading] = useState(false);

	const [openConfig, setOpenConfig] = useState(false);

	const [expandModal, setExpandModal] = useState(false);

	const [categorias, setCategorias] = useState([]);

	const [upcomingCategorias, setUpcomingCategorias] = useState([]);

	const [orders, setOrders] = useState([]);

	const [message, setMessage] = useState();

	const [configImport, setConfigImport] = useState();

	const [models, setModels] = useState([]);

	useEffect(() => {
		function getModels() {
			api.get(`/conta_config/${sessionStorage.getItem("idConta")}`).then((response) => {
				let data = response.data.find((i) => i?._id);
				const modules = data?.import?.pedidos;

				if (modules && modules.length) {
					setModels(modules);
					//setConfigImport(formatedModules[0]);
				}
			});
		}
		getModels();
	}, []);

	useEffect(() => {
		function getcategorias() {
			setLoading(true);
			api
				.get(`/categoria/${sessionStorage.getItem("idConta")}/*/*/*/1`, {})
				.then((response) => {
					setCategorias(response.data.slice(0, -1));
				})
				.finally(() => {
					setLoading(false);
				});
		}

		getcategorias();
	}, []);

	function getID() {
		return "xxxxxxxx-yxxx".replace(/[xy]/g, function (c) {
			var r = (Math.random() * 16) | 0,
				v = c === "x" ? r : (r & 0x3) | 0x8;
			return v.toString(16);
		});
	}

	function randomIdentificador() {
		var characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
		var id = "";
		for (var i = 0; i < 6; i++) {
			var randomIndex = Math.floor(Math.random() * characters.length);
			id += characters[randomIndex];
		}
		return id;
	}

	function handleSave() {
		if (upcomingCategorias.length) {
			importCategorias();
		} else {
			save();
		}
	}

	function save() {
		setLoading(true);
		let option = { headers: { "Content-Type": ["application/json"] } };

		const registro = [
			{
				id_conta: sessionStorage.getItem("idConta"),
				id_usuario: sessionStorage.getItem("idUser"),
				modo: "p",
				identificador: randomIdentificador(),
				qtd_ausente: 0,
				qtd_encontrado: 0,
				qtd_auditado: 0,
				qtd_excedente: 0,
				qtd_incorporado: 0,
				qtd_outro_ambiente: 0,
				status: 1,
			},
		];
		api.post(`/registro`, registro, option).then(
			(response) => {
				const id_registro = response?.data[0]?._id;
				const newOrder = orders.map((order, index) => ({
					...order,
					id_registro: id_registro,
				}));
				api
					.post(`/registro_pedido`, newOrder, option)
					.then(
						(response) => {
							setMessage({
								type: "success",
								title: t("messages.success"),
								message: t("settings.clean_base.success_message"),
							});
							setLoading(false);
						},
						(error) => {
							setMessage({
								type: "error",
								title: t("messages.errors.error"),
								message: t("messages.errors.500"),
							});
						}
					)
					.finally(() => {
						setLoading(false);
					});
			},
			(error) => {}
		);
	}

	function importCategorias() {
		setLoading(true);
		let option = { headers: { "Content-Type": ["application/json"] } };
		let reg = [
			{
				categoria: upcomingCategorias,
				itens: [],
				nivel1: [],
				nivel2: [],
				nivel3: [],
				nivel4: [],
				parceiros: [],
			},
		];
		api
			.post(`/itensDef/${sessionStorage.getItem("idConta")}`, reg, option)
			.then(
				(response) => {
					save();
				},
				(error) => {
					setMessage({
						type: "error",
						title: t("messages.errors.error"),
						message: t("messages.errors.500"),
					});
				}
			)
			.finally(() => {
				setLoading(false);
			});
	}

	function handleUpload(uploadFile) {
		if (!configImport || !uploadFile || !uploadFile?.length) {
			setMessage({
				type: "error",
				title: t("messages.error"),
				message:
					"Ocorreu um erro ao tentar ler a planilha. Por favor, verifique as configurações de importação e tente novamente.",
			});
			return;
		}
		handleUploadFile(uploadFile);
	}

	function handleUploadCSV(file) {
		const { data } = file;

		if (configImport) {
			const { fields, xmlUploadFields } = configImport;
			const rows = data.map((item) => {
				const row = {};
				fields.forEach((field, index) => {
					row[field] = item[xmlUploadFields[index]];
				});
				return row;
			});
			handleUploadFile(rows);
		} else {
			const rows = data.map((item) => {
				const row = {};
				const keys = orderFields.map((i) => i.value);
				keys.forEach((key, index) => {
					row[key] = Object.values(item)[index];
				});
				return row;
			});

			console.log(rows);
			handleUploadFile(rows);
		}
	}

	function handleTemplate() {
		const data = orderFields.map((i) => i.label); // Obter apenas os campos sem o ponto e vírgula

		const header = data.join(";"); // Concatena com ponto e vírgula, sem adicionar vírgulas
		const csvContent = `${header}\n`; // Adiciona quebra de linha após o cabeçalho

		const blob = new Blob(["\uFEFF" + csvContent], { type: "text/csv;charset=utf-8" });
		const url = URL.createObjectURL(blob);
		const link = document.createElement("a");
		link.href = url;
		link.download = "template-import-itens.csv";
		link.click();
		URL.revokeObjectURL(url);
	}

	async function handleUploadFile(uploadFile) {
		const newOrders = orders.length ? orders : [];
		const upCategorias = upcomingCategorias || []; // Categorias não cadastradas

		for (var i = 0; i < uploadFile.length; i++) {
			const itens = uploadFile[i];
			const xean = itens?.ean;
			const xdesc = itens?.id_categoria;
			const getCategoria = categorias.find((c) => c.descricao === xdesc || c.ean === xean);
			const order = {
				id: i,
				id_conta: sessionStorage.getItem("idConta"),
				id_categoria: getCategoria?._id || getID(),
				categoria: getCategoria?.descricao || xdesc,
				ean: getCategoria?.ean || xean,
				quantidade: itens?.quantidade && !isNaN(itens?.quantidade) ? Math.ceil(itens?.quantidade) : 0,
				quantidade_lida: itens?.quantidade_lida || 0,
				quantidade_auditada: itens?.quantidade_auditada || 0,
				observacao: itens?.observacao || 0,
				isValid: getCategoria ? true : false,
			};
			newOrders.push(order);
			if (!getCategoria) {
				upCategorias.push({
					_id: order.id_categoria,
					descricao: order.categoria,
					ean: order.ean,
					id_conta: order.id_conta,
				});
			}
		}

		setCategorias(upCategorias);
		setOrders(newOrders);
	}

	return (
		<>
			<Stack spacing={3}>
				<Stack direction="row" justifyContent="space-between">
					<Typography variant="h1">Importar arquivo</Typography>
					<Stack spacing={1} direction="row">
						<Button
							variant="outlined"
							color="dark"
							onClick={(e) => {
								navigate("/orders");
							}}
						>
							{t("actions.exit")}
						</Button>
						<Button
							variant="contained"
							onClick={(e) => {
								handleSave();
							}}
						>
							{t("actions.save")}
						</Button>
					</Stack>
				</Stack>

				<Stack spacing={3} as={Paper} sx={{ borderRadius: 3, p: 3 }}>
					<Stack direction="row" justifyContent="end" spacing={1}>
						<FormControl size="small">
							<InputLabel id="model-form">Modelo</InputLabel>
							<Select
								labelId="model-form"
								label="Modelo"
								value={configImport}
								onChange={(e) => {
									setConfigImport(e.target.value);
								}}
								sx={{ width: "25ch" }}
							>
								<MenuItem value={null}>--</MenuItem>
								{models.map((m) => (
									<MenuItem value={m} key={m._id}>
										{m.name}
									</MenuItem>
								))}
							</Select>
						</FormControl>
						<Button
							variant="outlined"
							color="dark"
							onClick={(e) => {
								handleTemplate();
							}}
						>
							Baixar {t("export.model")}
						</Button>
						<Button
							variant="outlined"
							startIcon={<Icon icon="solar:settings-linear" />}
							onClick={(e) => {
								setOpenConfig(!openConfig);
							}}
						>
							Configurar
						</Button>
						<Modal
							open={openConfig}
							onClose={(e) => {
								setOpenConfig(false);
							}}
							aria-labelledby="modal-modal-title"
							aria-describedby="modal-modal-description"
						>
							<Box
								as={Paper}
								sx={{
									position: "absolute",
									top: "50%",
									left: "50%",
									transform: "translate(-50%, -50%)",
									width: expandModal ? "95vw" : "60vw",
									height: expandModal ? "90vh" : "auto",
									borderRadius: 3,
									p: 2,
								}}
							>
								<Stack direction="row" justifyContent="end" spacing={1}>
									<IconButton
										onClick={() => {
											setExpandModal(!expandModal);
										}}
									>
										<Icon icon="majesticons:arrows-expand-full" />
									</IconButton>
									<IconButton
										onClick={() => {
											setOpenConfig(false);
										}}
									>
										<Icon icon="mingcute:close-fill" />
									</IconButton>
								</Stack>
								<ConfigImport
									module="pedidos"
									optionsFields={orderFields}
									onChangeConfig={(e) => {
										setConfigImport(e);
									}}
									modelImport={[
										{
											name: "NF-e",
											position: "cell",
											keyTag: "prod",
											fields: ["id_categoria", "ean", "quantidade"],
											xmlUploadFields: ["xProd", "cEAN", "vProd"],
										},
									]}
								/>
							</Box>
						</Modal>
					</Stack>

					{format == "xml" ? (
						<ImportXML
							{...configImport}
							principalKey={"id_categoria"}
							onUpload={(e) => {
								handleUpload(e);
							}}
						/>
					) : (
						<ImportCSV
							{...configImport}
							onUpload={(e) => {
								handleUploadCSV(e);
							}}
						/>
					)}

					<DataGrid
						localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
						rows={orders}
						columns={columns}
						editMode="row"
						disableRowSelectionOnClick={true}
						sx={{
							border: 0,
						}}
						processRowUpdate={(updatedRow, originalRow) => {
							setOrders((prevRows) => {
								const newRows = [...prevRows];
								newRows[originalRow.id] = updatedRow;
								return newRows;
							});
						}}
						getRowHeight={({ id, densityFactor }) => {
							if (id % 2 === 0) {
								return 50 * densityFactor;
							}

							return null;
						}}
						slotProps={{
							columnsManagement: {
								toggleAllMode: "filteredOnly",
							},
						}}
					/>
				</Stack>
			</Stack>

			<Loading show={loading} />

			{message ? (
				<Alert
					type={message.type}
					title={message.title}
					message={message.message}
					onClose={(e) => {
						setMessage(e);
					}}
				/>
			) : (
				""
			)}
		</>
	);
}
