import { Container, Stack, Typography } from "@mui/material";

import { useLocation } from "react-router-dom";

import Maps from "../../components/Maps/Maps";
import { Icon } from "@iconify/react/dist/iconify.js";

export default function ItemsMap() {
	const location = useLocation();

	const { mapItems } = location?.state || [];

	const item = mapItems[0];
	return (
		<>
			<Container>
				<Stack spacing={3}>
					<Typography variant="h1">Items</Typography>
					<Maps items={mapItems} zoom={15} height="80vh" />
				</Stack>
			</Container>
		</>
	);
}
