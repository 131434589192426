import { useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";

import { Container, IconButton, Stack, Typography } from "@mui/material";

import QrCodeScanner from "../../components/QrcodeScan/QrcodeScan";

import { Icon } from "@iconify/react/dist/iconify.js";

// -------------------------------------------------------

export default function QRCode() {
	const navigate = useNavigate();

	const [readedData, setReadedData] = useState();

	useEffect(() => {
		if (readedData) {
			if (readedData.includes("https://www.inovacode.app.br")) {
				navigate(readedData.replace("https://www.inovacode.app.br", ""));
			} else {
				window.open(readedData);
			}
		}
	}, [readedData]);

	return (
		<>
			<Container maxWidth="xs">
				<Stack spacing={3} sx={{ py: 3 }}>
					<div>
						<Stack direction="row" justifyContent="center">
							<IconButton sx={{ color: "primary.main", my: 1 }}>
								<Icon icon="f7:qrcode-viewfinder" width={32} />
							</IconButton>
						</Stack>
						<Typography variant="h1" textAlign="center">
							Scan QR code
						</Typography>
						<Typography variant="subtitle" textAlign="center">
							Scaneie ou envie um qrcode direto da sua galeria
						</Typography>
					</div>
					<>
						<QrCodeScanner onChange={(e) => setReadedData(e)} />{" "}
						<Typography variant="subtitle" textAlign="center">
							{readedData}
						</Typography>
					</>
				</Stack>
			</Container>
		</>
	);
}
