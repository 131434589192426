import { useEffect, useState } from "react";

import {
	Button,
	Chip,
	FormControl,
	FormLabel,
	Grid2,
	IconButton,
	MenuItem,
	Select,
	Stack,
	Typography,
} from "@mui/material";

import api from "../../../services/api";

import Datagrid from "../../../components/datagrid";

import { Icon } from "@iconify/react/dist/iconify.js";

import { t } from "i18next";

import { useNavigate } from "react-router-dom";

//----------------------------------------------------------

export default function InventoryTracking({ ausentes, tag }) {
	const navigate = useNavigate();

	const [registros, setRegistros] = useState([]);

	useEffect(() => {
		function getRegistros() {
			const tags = [{ tags: ausentes }];
			api.post(`/registro_tag/pesquisar_inv/${sessionStorage.getItem("idConta")}?populate=true`, tags).then((res) => {
				const data = res.data.map((item, index) => {
					const registro = item.id_registro.find((i) => i?._id);
					return {
						...item,
						status: getStatus(item),
						id_registro: registro?._id,
						identificador: registro?.identificador,
						id: index,
					};
				});
				setRegistros(data);
			});
		}
		if (ausentes?.length) {
			getRegistros();
		}
	}, []);

	function getStatus(item) {
		if (item.incorporado === 1) return "Incorporado";
		if (item.auditado === 1) return "Auditado";
		if (item.excedente === 1 && item?.id_item) return "Outro_Ambiente";
		if (item.excedente === 1) return "Excedente";
		if (item.encontrado === 1) return "Encontrado";
		return "Ausente";
	}

	const columns = [
		{
			field: "id",
			headerName: "ID",
			width: 90,
		},
		{
			field: "tag",
			headerName: "Tag",
			width: 300,
			renderCell: (params) => (
				<>
					<Stack direction="column">
						<Typography sx={{ fontWeight: 600 }}>{params.row?.descricao}</Typography>
						<Typography variant="subtitle">{params.row?.tag}</Typography>
					</Stack>
				</>
			),
		},
		{
			field: "dt_leitura",
			headerName: "Data",
			type: "dateTime",
			valueGetter: (value, row) => new Date(row.dt_leitura),
		},
		{
			field: "nivel_loc_1",
			headerName: "Localização",
			width: 300,
			renderCell: (params) => (
				<>
					<Stack direction="column">
						<div>{params.row?.nivel_loc_1}</div>
						<div>{params.row?.nivel_loc_2}</div>
						<div>{params.row?.nivel_loc_3}</div>
						<div>{params.row?.nivel_loc_4}</div>
					</Stack>
				</>
			),
		},
		{
			field: "id_registro",
			headerName: "Inventário",
			width: 200,
			renderCell: (params) => (
				<>
					<Stack direction="row" alignItems="center" spacing={3}>
						<div>
							<Typography variant="subtitle">Id.:</Typography>
							<Typography>{params?.row?.identificador}</Typography>
						</div>
						<Chip
							label={t("actions.view")}
							icon={<Icon icon="ci:external-link" />}
							sx={{ fontSize: "1rem" }}
							onClick={() => {
								navigate("/collections/inventories/" + params?.row?.id_registro);
							}}
						/>
					</Stack>
				</>
			),
		},
	];

	return (
		<Stack spacing={3} sx={{ p: 3 }}>
			<div>
				<Typography variant="h1">{t("common.registers")}</Typography>
				<Typography variant="subtitle">{!ausentes?.length ? ausentes : ""}</Typography>
			</div>
			<Datagrid header={columns} tableData={registros} />
		</Stack>
	);
}
