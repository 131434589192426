import * as React from "react";

import Button from "@mui/material/Button";

import { styled } from "@mui/material/styles";

import Dialog from "@mui/material/Dialog";

import DialogTitle from "@mui/material/DialogTitle";

import DialogContent from "@mui/material/DialogContent";

import DialogActions from "@mui/material/DialogActions";

import IconButton from "@mui/material/IconButton";

import Typography from "@mui/material/Typography";

import { Icon } from "@iconify/react";

import { Box, Grid } from "@mui/material";

import { useTranslation } from "react-i18next";
import { borderRadius } from "@mui/system";

export default function CustomModal({
	title,
	message,
	type,
	dividers = true,
	icon,
	disableIcon,
	confirmText,
	denyText,
	confirmColor,
	onClose,
	onConfirm,
	onSave,
	onSend,
	onDeny,
	children,
	width,
}) {
	const { t } = useTranslation();

	const [open, setOpen] = React.useState(true);

	const handleClose = () => {
		setOpen(false);
		if (onClose) {
			onClose(null);
		}
	};

	const handleSave = () => {
		setOpen(false);
		onSave(null);
	};

	const handleConfirm = () => {
		setOpen(false);
		onConfirm(null);
	};

	const handleSend = () => {
		setOpen(false);
		onSend(null);
	};

	const handleDeny = () => {
		setOpen(false);
		onDeny(null);
	};

	const BootstrapDialog = styled(Dialog)(({ theme }) => ({
		"& .MuiDialog-paper": {
			minWidth: width ?? 500,
			borderRadius: 18,
		},
		"& .MuiDialogContent-root": {
			padding: theme.spacing(2),
		},
		"& .MuiDialogActions-root": {
			padding: theme.spacing(1),
		},
	}));

	return (
		<>
			<BootstrapDialog onClose={handleClose} open={open}>
				{title ? (
					<DialogTitle>
						{!disableIcon && <Icon icon={icon ?? "ic:round-warning"} style={{ marginRight: 8 }} />}
						{title}
					</DialogTitle>
				) : (
					""
				)}

				<IconButton
					aria-label="close"
					onClick={handleClose}
					sx={{
						position: "absolute",
						right: 8,
						top: 8,
						color: (theme) => theme.palette.grey[500],
					}}
				>
					<Icon icon="line-md:close" />
				</IconButton>

				<DialogContent dividers={dividers}>{children}</DialogContent>

				<DialogActions>
					{onClose ? (
						<Button variant="outlined" color="dark" onClick={handleClose}>
							{t("actions.exit")}
						</Button>
					) : (
						""
					)}
					{onDeny ? (
						<Button variant="outlined" color="dark" onClick={handleDeny}>
							{denyText ?? t("actions.cancel")}
						</Button>
					) : (
						""
					)}
					{onConfirm ? (
						<Button variant="contained" color={confirmColor || "error"} onClick={handleConfirm}>
							{confirmText ?? t("actions.continue")}
						</Button>
					) : (
						""
					)}
					{onSave ? (
						<Button variant="contained" color="error" onClick={handleSave}>
							{t("actions.save")}
						</Button>
					) : (
						""
					)}
					{onSend ? (
						<Button startIcon={<Icon icon="tabler:send" />} variant="contained" onClick={handleSend}>
							{t("actions.send")}
						</Button>
					) : (
						""
					)}
				</DialogActions>
			</BootstrapDialog>
		</>
	);
}
