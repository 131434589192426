import { Button, Container, Paper, Stack } from "@mui/material";

import ConfigImport from "../../../components/Import/ConfigImport";
import { useState } from "react";
import { t } from "i18next";
import { useLocation, useNavigate, useParams } from "react-router-dom";

const itemsField = [
	{ label: "Tag", value: "tag" },
	{ label: "Categoria", value: "id_categoria" },
	{ label: "EAN", value: "ean" },
	{ label: "Nível Loc 1", value: "id_nivel_loc_1" },
	{ label: "Nível Loc 2", value: "id_nivel_loc_2" },
	{ label: "Nível Loc 3", value: "id_nivel_loc_3" },
	{ label: "Nível Loc 4", value: "id_nivel_loc_4" },
	{ label: "Informação Complementar 1", value: "inf_compl_1" },
	{ label: "Informação Complementar 2", value: "inf_compl_2" },
	{ label: "Informação Complementar 3", value: "inf_compl_3" },
	{ label: "Informação Complementar 4", value: "inf_compl_4" },
	{ label: "Informação Complementar 5", value: "inf_compl_5" },
	{ label: "Informação Complementar 6", value: "inf_compl_6" },
	{ label: "Informação Complementar 7", value: "inf_compl_7" },
	{ label: "Informação Complementar 8", value: "inf_compl_8" },
	{ label: "Informação Complementar 9", value: "inf_compl_9" },
	{ label: "Informação Complementar 10", value: "inf_compl_10" },
	{ label: "Marca", value: "id_marca" },
	{ label: "Modelo", value: "id_modelo" },
	{ label: "Valor", value: "valor" },
	{ label: "Parceiro", value: "id_parceiro" },
	{ label: "Observação", value: "observacao" },
	{ label: "Ativo", value: "ativo" },
	{ label: "Foto", value: "foto" },
	{ label: "ID Externo", value: "id_externo" },
	{ label: "Item Vinculado", value: "id_item_vinculado" },
	{ label: "UP 1", value: "up_1" },
	{ label: "UP 2", value: "up_2" },
	{ label: "UP 3", value: "up_3" },
	{ label: "UP 4", value: "up_4" },
	{ label: "UP 5", value: "up_5" },
	{ label: "Latitude", value: "latitude" },
	{ label: "Longitude", value: "longitude" },
];

export default function ItemsImportConfig() {
	let { file } = useParams();

	const navigate = useNavigate();

	const location = useLocation();

	const [configImport, setConfigImport] = useState(location?.state?.config || "");

	const [preLoad, setPreload] = useState(location?.state?.preLoad || null);

	const [xmlField, setXmlField] = useState(location?.state?.xmlfields || null);

	return (
		<>
			<Container>
				<Stack direction="row" justifyContent="flex-end" spacing={1} sx={{ py: 3 }}>
					<Button
						variant="outlined"
						color="dark"
						onClick={() => {
							navigate(`/items/import/${file || "xml"}`);
						}}
					>
						{t("actions.cancel")}
					</Button>
					<Button
						variant="contained"
						color="error"
						onClick={() => {
							navigate(`/items/import/${file || "xml"}`, { state: { config: configImport, preLoad: preLoad } });
						}}
					>
						{t("actions.save")}
					</Button>
				</Stack>

				<Stack as={Paper} sx={{ p: 3 }}>
					<ConfigImport
						module="itens"
						format={file}
						optionsFields={itemsField}
						xmlFieldOptions={xmlField}
						onChangeConfig={(e) => {
							setConfigImport(e);
						}}
						selectedModel={{ configImport }}
					/>
				</Stack>
			</Container>
		</>
	);
}
