import { useEffect, useState } from "react";

import { t } from "i18next";

import api from "../../services/api";

import ImportCSV from "./ImportCSV";

import Loading from "../ui/Loading";
import { Paper, Stack, Typography } from "@mui/material";
import ImportXML from "./ImportXML";

// -------------------------------------------------

export default function Import({ format = "xml", module = "itens", fields = [], onUpload = () => {} }) {
	const [loading, setLoading] = useState(false);

	const [message, setMessage] = useState();

	const [settings, setSettings] = useState(); // Configuração de importação

	useEffect(() => {
		const getModels = async () => {
			setLoading(true);
			try {
				const accountId = sessionStorage.getItem("idConta");
				if (!accountId) return;

				const response = await api.get(`/conta_config/${accountId}`);
				const data = response.data?.[0];

				const importModules = data?.import?.[module];

				if (importModules?.length) {
					//setSettings(importModules?.find((i) => i._id));
				}
			} catch (error) {
				setMessage({
					type: "error",
					message: t("messages.errors.500"),
					title: t("messages.errors.error"),
				});
			} finally {
				setLoading(false);
			}
		};

		getModels();
	}, [module]);

	function handleUploadCSV(file) {
		if (settings) {
			const { fields, xmlUploadFields } = settings;
			const rows = file.map((item) => {
				const row = {};
				fields.forEach((field, index) => {
					row[field] = item[xmlUploadFields[index]];
				});
				return row;
			});
			onUpload(rows);
		} else {
			const rows = file.map((item) => {
				const row = {};
				fields.forEach((key, index) => {
					row[key] = Object.values(item)[index];
				});

				return row;
			});
			onUpload(rows);
		}
	}

	return (
		<>
			{format == "csv" ? (
				<ImportCSV
					onUpload={(e) => {
						handleUploadCSV(e.data);
					}}
				/>
			) : (
				<ImportXML
					position={settings?.position}
					fields={settings?.fields}
					xmlUploadFields={settings?.xmlUploadFields}
					keyTag={settings?.keyTag}
					fixedField={settings?.fixedField}
					onUpload={(e) => {
						onUpload(e);
					}}
				/>
			)}

			<Loading show={loading} />
		</>
	);
}
