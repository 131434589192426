import { Icon } from "@iconify/react/dist/iconify.js";
import { IconButton, Tooltip } from "@mui/material";

export default function Helper({ text = "" }) {
	return (
		<>
			<Tooltip
				title={text}
				arrow
				componentsProps={{
					tooltip: {
						sx: {
							backgroundColor: "white",
							color: "text.primary",
							boxShadow: 3,
							zIndex: 1300,
							fontFamily: "Inter, sans-serif",
							fontSize: "0.9rem",
							border: 1,
							borderColor: "grey.300",
							fontWeight: 400,
						},
					},
				}}
			>
				<IconButton sx={{ color: "grey.400", p: 0 }}>
					<Icon icon="mage:question-mark-circle-fill" width={18} />
				</IconButton>
			</Tooltip>
		</>
	);
}
