import { useCallback } from "react";

const useDownloadTemplateItems = () => {
	const handleCSVDownload = useCallback(() => {
		// Definindo os cabeçalhos e descrições diretamente
		const headers = [
			"Tag Incorporada",
			"iD Externo",
			"Ativo",
			"Tag*",
			"Categoria*",
			"EAN",
			"Inf. Complementar 1",
			"Inf. Complementar 2",
			"Inf. Complementar 3",
			"Inf. Complementar 4",
			"Inf. Complementar 5",
			"Parceiro",
			"Nível de Localização 1*",
			"Nível de Localização 2",
			"Nível de Localização 3",
			"Nível de Localização 4",
			"Observação",
			"Valor do Ativo",
			"Inf. Complementar 6",
			"Inf. Complementar 7",
			"Inf. Complementar 8",
			"Inf. Complementar 9",
			"Inf. Complementar 10",
			"Ups 1",
			"Ups 2",
			"Ups 3",
			"Ups 4",
			"Ups 5",
			"",
			"ID",
			"Latitude",
			"Longitude",
			"Marca",
			"Modelo",
		];

		const descriptions = [
			"Informe o id do item vinculado.",
			"Chave primária do item na base de origem.",
			"Status do item, sendo 0 para inativo, 1 para ativo, 2 para em uso, 3 para danificado e 4 para obsoleto.",
			"TAG/EPC do Item que será criado ou atualizado.",
			"Descrição da categoria do Item.",
			"EAN da Categoria do Item.",
			"Campo livre para informações extra do Item.",
			"Campo livre para informações extra do Item.",
			"Campo livre para informações extra do Item.",
			"Campo livre para informações extra do Item.",
			"Campo livre para informações extra do Item.",
			"Entidade ou indivíduo vinculado ao item (Ex.: Fornecedor, Cliente, Transportadora, colaborador, etc)",
			"Localização do item no estoque.",
			"Localização do item no estoque. Subnível do nivel 1",
			"Localização do item no estoque. Subnível do nivel 2",
			"Localização do item no estoque. Subnível do nivel 3",
			"Informações complementares",
			"Valor do ativo",
			"Campo livre para informações extra do Item.",
			"Campo livre para informações extra do Item.",
			"Campo livre para informações extra do Item.",
			"Campo livre para informações extra do Item.",
			"Campo livre para informações extra do Item.",
			"Campo livre para identificação extra do Item",
			"Campo livre para identificação extra do Item",
			"Campo livre para identificação extra do Item",
			"Campo livre para identificação extra do Item",
			"Campo livre para identificação extra do Item",
			"Deixe essa coluna vazia para ser preenchida por dados internos.",
			"Id/Chave primária interno do item.",
			"Latitude do item",
			"Longitude do item",
			"Marca do item",
			"Modelo do item",
		];

		// Juntando os cabeçalhos e descrições em uma única string CSV
		const header = headers.join(";");
		const description = descriptions.join(";");
		const csvContent = `${header}\n${description}`;

		// Criando o Blob e fazendo o download do arquivo
		const blob = new Blob(["\uFEFF" + csvContent], { type: "text/csv;charset=utf-8" });
		const url = URL.createObjectURL(blob);
		const link = document.createElement("a");
		link.href = url;
		link.download = "Modelo base de dados.csv";
		link.click();
		URL.revokeObjectURL(url);
	}, []);

	return handleCSVDownload;
};

export default useDownloadTemplateItems;
