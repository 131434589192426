import { Icon } from "@iconify/react/dist/iconify.js";

import { Box, Button, Paper, Stack, styled, Typography } from "@mui/material";

import { t } from "i18next";

import { useState } from "react";

import Loading from "../../components/loading";
// ----------------------------------------------------------

const VisuallyHiddenInput = styled("input")({
	clip: "rect(0 0 0 0)",
	clipPath: "inset(50%)",
	height: "100%",
	overflow: "hidden",
	position: "absolute",
	bottom: 0,
	left: 0,
	whiteSpace: "nowrap",
	width: "100%",
});

export default function ImportXML({
	position = "cell",
	fields,
	xmlUploadFields,
	keyTag,
	onUpload,
	fixedField = [],
	principalKey,
}) {
	const [isLoading, setIsLoading] = useState(false); // Estado para o indicador de carregamento

	// Função principal para manipular o upload de arquivos
	async function handleUpload(file) {
		try {
			setIsLoading(true);
			// Verifica se os campos necessários foram fornecidos e se são arrays
			if (!fields || !Array.isArray(fields)) {
				console.error("Invalid or missing 'fields'.");
				setIsLoading(false);
				return onUpload([]);
			}
			if (!xmlUploadFields || !Array.isArray(xmlUploadFields)) {
				console.error("Invalid or missing 'xmlUploadFields'.");
				setIsLoading(false);
				return onUpload([]);
			}

			// Decide como processar o arquivo com base na posição da informação
			if (position === "cell") {
				await handleCell(file); // Processa quando os dados estão por célula
			} else if (position === "row") {
				handleFileUpload(file); // Processa quando os dados estão por linha
			}
		} catch (error) {
			setIsLoading(false);
			console.error("Error during file upload and parsing:", error.message);
			onUpload([]);
		}
	}

	// Função para processar arquivos quando os dados estão estruturados por célula
	async function handleCell(file) {
		// Importa dinamicamente o parser XML
		const XMLParser = (await import("react-xml-parser")).default;

		// Lê o conteúdo do arquivo como texto e o converte para XML
		const xml = new XMLParser().parseFromString(await file.text());
		const lista = []; // Lista para armazenar os dados processados

		const principalTag = principalKey ? xmlUploadFields[fields?.indexOf(principalKey)] : null;

		// Obtém os elementos-chave com base no tag especificado
		const elements = keyTag
			? xml.getElementsByTagName(keyTag)
			: principalTag
				? xml.getElementsByTagName(principalTag)
				: fields
					? xml.getElementsByTagName(fields[0])
					: [];

		const objKeys = fields || [];
		const valueKeys = xmlUploadFields || [];

		// Itera sobre os elementos para extrair os dados
		for (let i = 0; i < elements.length; i++) {
			const item = {}; // Objeto para armazenar os valores extraídos

			for (let j = 0; j < objKeys.length; j++) {
				// Associa os valores extraídos às chaves do objeto
				var value;
				if (fixedField[j]) {
					value = valueKeys[j];
				} else {
					value = xml.getElementsByTagName(valueKeys[j])[i]?.value || null;
				}

				item[objKeys[j]] = value;
			}

			lista.push(item);
		}

		setIsLoading(false);
		onUpload(lista); // Retorna os dados processados
	}

	// Função para processar arquivos quando os dados estão estruturados por linha
	const handleFileUpload = (file) => {
		if (!file) return;

		// Lê o arquivo como texto
		const reader = new FileReader();
		reader.onload = (e) => {
			const xmlString = e.target.result;
			parseXML(xmlString); // Passa o texto para análise
		};
		reader.readAsText(file);
	};

	// Função para analisar o conteúdo XML em string
	const parseXML = (xmlString) => {
		const parser = new DOMParser();
		const xmlDoc = parser.parseFromString(xmlString, "text/xml"); // Converte a string XML em um documento DOM

		const rows = []; // Lista para armazenar linhas processadas
		const headersSet = new Set(); // Conjunto para armazenar os nomes das colunas

		// Função recursiva para percorrer os nós XML
		const traverseNode = (node, parentData = {}) => {
			const currentData = { ...parentData }; // Copia os dados do nó pai

			if (node.nodeType === 1) {
				// Verifica se o nó é um elemento
				// Adiciona atributos do nó como colunas
				Array.from(node.attributes).forEach((attr) => {
					currentData[attr.name] = attr.value;
					headersSet.add(attr.name);
				});

				// Adiciona o conteúdo de texto do nó se não estiver vazio
				const textContent = node.textContent.trim();
				if (textContent) {
					currentData[node.nodeName] = textContent;
					headersSet.add(node.nodeName);
				}
			}

			// Processa os nós filhos recursivamente
			const children = Array.from(node.children);
			if (children.length > 0) {
				children.forEach((child) => traverseNode(child, currentData));
			} else if (node.nodeType === 1) {
				// Se for um nó folha, salva os dados atuais
				rows.push(currentData);
			}
		};

		traverseNode(xmlDoc.documentElement); // Inicia a análise a partir do elemento raiz

		const lista = []; // Lista para armazenar os dados formatados
		const objKeys = fields || [];
		const valueKeys = xmlUploadFields || [];

		// Mapeia os dados processados para o formato final
		for (let i = 0; i < rows.length; i++) {
			const currentElement = rows[i];
			const item = {};

			for (let j = 0; j < objKeys.length; j++) {
				// Associa valores às chaves do objeto
				var value;
				if (fixedField[j]) {
					value = valueKeys[j];
				} else {
					value = currentElement[valueKeys[j]];
				}

				item[objKeys[j]] = value;
			}

			lista.push(item);
		}

		setIsLoading(false);
		onUpload(lista); // Retorna os dados processados
	};

	return (
		<>
			<Loading show={isLoading} />
			<Stack spacing={3} sx={{ borderRadius: 3, p: 3 }}>
				<Box
					component="label"
					role={undefined}
					variant="standard"
					tabIndex={-1}
					onDragOver={(e) => {
						e.preventDefault(); // Necessário para permitir o drop
						e.stopPropagation();
					}}
					onDragEnter={(e) => {
						e.preventDefault();
						e.stopPropagation();
					}}
					onDrop={(e) => {
						e.preventDefault();
						e.stopPropagation();
						// Obtém o arquivo solto no box
						const file = e.dataTransfer.files[0];
						if (file) {
							handleUpload(file);
						}
					}}
					sx={{
						p: 3,
						height: "100%",
						border: 1,
						borderStyle: "dashed",
						borderColor: "grey.400",
						borderRadius: 1,
						color: "grey.500",
						display: "flex",
						flexDirection: "column",
						alignItems: "center",
						justifyContent: "center",
					}}
				>
					<Icon icon="duo-icons:upload-file" width="32" />
					<Typography textAlign="center" sx={{ mt: 2 }}>
						{t("items.upload_file")} XML
					</Typography>
					<VisuallyHiddenInput
						type="file"
						accept=".xml"
						onChange={(e) => {
							handleUpload(e.target.files[0]);
						}}
					/>
				</Box>
			</Stack>
		</>
	);
}
