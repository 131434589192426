import React from "react";

import Chart from "react-apexcharts";

const getAutoRanges = (data) => {
	// Encontra todos os valores presentes nos dados
	const allValues = data.flatMap((series) => series.data);

	// Filtra os valores maiores que 0 para calcular os intervalos
	const positiveValues = allValues.filter((value) => value > 0);

	// Calcula o valor mínimo e máximo dos valores positivos
	const minValue = Math.min(...positiveValues);
	const maxValue = Math.max(...positiveValues);

	// Divide o intervalo dos valores positivos em três partes iguais
	const rangeSize = (maxValue - minValue) / 3;

	// Define os intervalos
	return [
		{
			from: 0,
			to: 0,
			color: "#e0e0e0", // Cinza para valores 0
			name: "zero",
		},
		{
			from: minValue,
			to: minValue + rangeSize,
			color: "#FF0000", // Cinza claro para valores baixos
			name: "low",
		},
		{
			from: minValue + rangeSize + 1,
			to: minValue + 2 * rangeSize,
			color: "#FFB200", // Laranja para valores médios
			name: "medium",
		},
		{
			from: minValue + 2 * rangeSize + 1,
			to: maxValue,
			color: "#00A100", // Vermelho para valores altos
			name: "high",
		},
	];
};

const Heatmap = ({ data, categories, colors }) => {
	// Configurações do gráfico
	const options = {
		chart: {
			type: "heatmap",
		},
		dataLabels: {
			enabled: true, // Habilita as labels dentro dos quadrados
			style: {
				colors: ["#1e1e1e"], // Cor do texto dos valores
				fontSize: "12px", // Tamanho da fonte dos valores dentro das células
			},
		},
		colors: colors || ["#008FFB"], // Cores padrão ou personalizadas
		xaxis: {
			categories: categories, // Categorias para o eixo X
			labels: {
				style: {
					fontSize: "14px", // Aumenta o tamanho da fonte do eixo X
				},
			},
			tickPlacement: "between", // Centraliza os ticks entre as células
		},
		yaxis: {
			labels: {
				style: {
					fontSize: "14px", // Aumenta o tamanho da fonte do eixo Y
				},
			},
		},
		plotOptions: {
			heatmap: {
				colorScale: {
					ranges: getAutoRanges(data), // Define os intervalos automaticamente
				},
				radius: 0, // Remove o arredondamento das bordas das células
				useFillColorAsStroke: false, // Remove a borda das células
				distributed: true, // Distribui as cores uniformemente
				cellWidth: 30, // Largura fixa das células
				cellHeight: 30, // Altura fixa das células
			},
		},
	};

	// Dados do heatmap
	const series = data;

	// Calcula a altura do gráfico com base no número de séries e tamanho das células
	const height = data.length * 30 + 100; // Ajuste baseado no cellHeight

	return (
		<div>
			<Chart options={options} series={series} type="heatmap" height={height} />
		</div>
	);
};
// Exemplo de uso do componente
const CustomHeatmap = ({ data, categories, colors }) => {
	// Exemplo de dados para o heatmap
	const defaultData = [
		{
			name: "Metric1",
			data: [10, 20, 30, 40, 50, 60, 70, 80, 90, 100],
		},
		{
			name: "Metric2",
			data: [15, 25, 35, 45, 55, 65, 75, 85, 95, 105],
		},
	];

	// Categorias para o eixo X
	const defaultCategories = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct"];

	return (
		<div>
			<Heatmap
				data={data || defaultData}
				categories={categories || defaultCategories}
				colors={[("#e0e0e0", "#00A100")]}
			/>
		</div>
	);
};

export default CustomHeatmap;
