import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

// componentes
import api from "../../services/api";
import LoadingPage from "../../components/loading/LoadingPage";

// estilos
import "./style.css";

import logoInova from "../../assets/logoinova.png";

import stock from "../../assets/images/stock.png";

//packages
import { useTranslation } from "react-i18next";
import Swal from "sweetalert2";
import {
	Box,
	Button,
	Container,
	FormControl,
	FormLabel,
	Grid2,
	IconButton,
	InputAdornment,
	Stack,
	TextField,
	Typography,
} from "@mui/material";
import { Icon } from "@iconify/react/dist/iconify.js";

// ----------------------------------------------------------------

export default function Register() {
	const { t } = useTranslation();

	const navigate = useNavigate();

	const [nomeRegister, setNomeRegister] = useState("");

	const [emailRegister, setEmailRegister] = useState("");

	const [passRegister, setPassRegister] = useState("");

	const [rpassRegister, setRPassRegister] = useState("");

	const [loading, setLoading] = useState(false);

	const [passError, setPassError] = useState("");

	const [showPassword, setShowPassword] = useState("");

	const [showRPassword, setShowRPassword] = useState("");

	useEffect(() => {
		const error = verifySenha();
		if (error) {
			setPassError(t(`login.messages.sign_up.errors.password.${error}`));
		} else {
			setPassError("");
		}
	}, [passRegister, rpassRegister]);

	function verifySenha() {
		let error;

		// Definindo as regras de validação da senha
		const minLength = 6;
		const hasUpperCase = /[A-Z]/.test(passRegister);
		const hasLowerCase = /[a-z]/.test(passRegister);
		const hasNumber = /\d/.test(passRegister);
		const hasSpecialChar = /[?:*\/]/.test(passRegister);
		const isCommonPassword = !["senha", "123456", "abcdef"].includes(passRegister.toLowerCase());
		const isNotUsername = passRegister.toLowerCase() !== nomeRegister.toLowerCase();

		if (passRegister === "" || rpassRegister === "") {
			return error;
		}

		if (passRegister.length < minLength) {
			error = "minLength";
			return error;
		}

		if (!hasUpperCase || !hasLowerCase || !hasNumber) {
			error = "rules";
			return error;
		}

		if (hasSpecialChar) {
			error = "hasSpecialChar";
			return error;
		}

		if (!isCommonPassword) {
			error = "isCommonPassword";
			return error;
		}

		if (!isNotUsername) {
			error = "notUsername";
			return error;
		}

		if (passRegister !== rpassRegister) {
			error = "matchPassword";
			return error;
		}

		return error;
	}

	function handleRegister(e) {
		e.preventDefault();

		Swal.fire({
			title: `${t("login.messages.sign_up.warning")}`,
			text: `${t("login.messages.sign_up.text")}`,
			icon: "warning",
			showCancelButton: true,
			confirmButtonColor: "#DD6B55",
			confirmButtonText: `${t("login.messages.sign_up.yes")}`,
			cancelButtonText: `${t("login.messages.sign_up.no")}`,
		}).then((result) => {
			if (result.isConfirmed) {
				if (!nomeRegister) {
					Swal.fire({
						title: "Oops... !",
						text: `${t("login.messages.sign_up.errors.name_missing")}`,
						icon: "error",
						showConfirmButton: false,
						timerProgressBar: true,
						timer: "2500",
					});
				} else if (!emailRegister) {
					Swal.fire({
						title: "Oops... !",
						text: `${t("login.messages.sign_up.errors.email_missing")}`,
						icon: "error",
						showConfirmButton: false,
						timerProgressBar: true,
						timer: "2500",
					});
				} else if (!(emailRegister.search("@") >= 0)) {
					Swal.fire({
						title: "Oops... !",
						text: `${t("login.messages.sign_up.errors.email_error")}`,
						icon: "error",
						showConfirmButton: false,
						timerProgressBar: true,
						timer: "2500",
					});
				} else if (passError) {
					Swal.fire({
						title: "Oops... !",
						text: `${passError}`,
						icon: "error",
						showConfirmButton: false,
						timerProgressBar: true,
						timer: "2500",
					});
				} else if (passRegister !== rpassRegister || passRegister === "" || rpassRegister === "") {
					Swal.fire({
						title: "Oops... !",
						text: `${t("login.messages.sign_up.errors.pass_match_error")}`,
						icon: "error",
						showConfirmButton: false,
						timerProgressBar: true,
						timer: "2500",
					});
				} else if (passRegister.length < 6) {
					Swal.fire({
						title: "Oops... !",
						text: `${t("login.messages.sign_up.errors.pass_size_error")}`,
						icon: "error",
						showConfirmButton: false,
						timerProgressBar: true,
						timer: "2500",
					});
				} else if (
					passRegister.includes("?") ||
					passRegister.includes(":") ||
					passRegister.includes("*") ||
					passRegister.includes("/")
				) {
					Swal.fire({
						title: "Oops... !",
						html: `${t("login.messages.sign_up.errors.pass_char_error")}`,
						icon: "error",
						showConfirmButton: false,
						timerProgressBar: true,
						timer: "2500",
					});
				} else {
					let dt = new Date();
					let dt_reg =
						dt.getFullYear() + "-" + ("0" + (dt.getMonth() + 1)).slice(-2) + "-" + ("0" + dt.getDate()).slice(-2);
					dt_reg +=
						" " +
						("0" + dt.getHours()).slice(-2) +
						":" +
						("0" + dt.getMinutes()).slice(-2) +
						":" +
						("0" + dt.getSeconds()).slice(-2);

					let reg = [];
					reg.push({
						id_conta: "0",
						ativo: 1,
						uuid: "",
						nome: nomeRegister,
						email: emailRegister,
						endereco: "",
						cnpj: "",
						observacao: "",
						foto: "",
						dt_registro: dt_reg,
						dt_licenca: dt_reg,
					});

					let options = {
						headers: {
							"Content-Type": ["application/json"],
						},
					};

					setLoading(true);
					api.post("/conta", reg, options).then(
						async function (res) {
							reg = [];
							reg.push({
								id_usuario: "0",
								id_conta: res.data[0]._id,
								ativo: 1,
								perfil: "admin",
								nome: nomeRegister,
								login: emailRegister,
								senha: passRegister,
								foto: "",
								dt_acesso: dt_reg,
							});

							api.post("/usuario", reg, options).then(
								(response) => {
									Swal.fire({
										title: `${t("login.messages.sign_up.success")}`,
										text: `${t("login.messages.sign_up.success_text")}`,
										icon: "success",
										showConfirmButton: false,
										timerProgressBar: true,
										timer: "2500",
									}).then((result) => {
										navigate("/login");
									});
								},
								(response) => {
									Swal.fire({
										title: "Oops... !",
										text: `${t("login.messages.sign_up.errors.error")}`,
										icon: "error",
										showConfirmButton: false,
										timerProgressBar: true,
										timer: "2500",
									});
									setLoading(false);
								}
							);
						},
						(error) => {
							const errorMessage = error.response?.data?.error;
							Swal.fire({
								title: "Oops... !",
								text: `${errorMessage || t("login.messages.sign_up.errors.error")}`,
								icon: "error",
								showConfirmButton: false,
								timerProgressBar: true,
								timer: "2500",
							});
							setLoading(false);
						}
					);
				}
			}
		});
	}

	return (
		<>
			{loading ? <LoadingPage /> : ""}

			<Grid2 container justifyContent="space-between" sx={{ height: "100vh" }}>
				<Grid2
					container
					size={{ xs: 12, md: "grow" }}
					sx={{
						backgroundColor: "white",
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
						flexDirection: "column",
						gap: 3,
					}}
				>
					<Box
						component="img"
						sx={{
							height: "auto",
							width: "13rem",
						}}
						alt="Logotype"
						src={logoInova}
					/>
					<Box
						component="form"
						autoComplete="off"
						onSubmit={handleRegister}
						sx={{
							width: { xs: "100%", md: "80%", lg: "50%", xl: "40%" },
							display: "flex",
							flexDirection: "column",
							gap: 3,
							p: 3,
						}}
					>
						<FormControl>
							<FormLabel>{t("login.placeholder.name")}</FormLabel>
							<TextField
								required
								size="small"
								fullWidth
								type="text"
								value={nomeRegister}
								onChange={(e) => setNomeRegister(e.target.value)}
							/>
						</FormControl>
						<FormControl autoComplete="off">
							<FormLabel>{t("login.placeholder.email")}</FormLabel>
							<TextField
								required
								size="small"
								fullWidth
								type="text"
								value={emailRegister}
								onChange={(e) => setEmailRegister(e.target.value)}
							/>
						</FormControl>
						<div>
							<Stack direction="row" spacing={1}>
								<FormControl fullWidth>
									<FormLabel>{t("login.placeholder.password")}</FormLabel>
									<TextField
										required
										size="small"
										type={showPassword ? "text" : "password"}
										value={passRegister}
										onChange={(e) => setPassRegister(e.target.value)}
										slotProps={{
											input: {
												endAdornment: (
													<InputAdornment position="end">
														<IconButton
															onClick={() => {
																setShowPassword(!showPassword);
															}}
														>
															<Icon icon={`fluent:eye-${showPassword ? "off-" : ""}16-filled`} />
														</IconButton>
													</InputAdornment>
												),
											},
										}}
									/>
								</FormControl>
								<FormControl fullWidth>
									<FormLabel sx={{ whiteSpace: "nowrap", overflow: "hidden" }}>
										{t("login.placeholder.confirm_password")}
									</FormLabel>
									<TextField
										size="small"
										type={showRPassword ? "text" : "password"}
										value={rpassRegister}
										onChange={(e) => setRPassRegister(e.target.value)}
										slotProps={{
											input: {
												endAdornment: (
													<InputAdornment position="end">
														<IconButton
															onClick={() => {
																setShowRPassword(!showRPassword);
															}}
														>
															<Icon icon={`fluent:eye-${showRPassword ? "off-" : ""}16-filled`} />
														</IconButton>
													</InputAdornment>
												),
											},
										}}
									/>
								</FormControl>
							</Stack>
							{passError && (
								<Typography variant="subtitle" color="error">
									{passError}
								</Typography>
							)}
						</div>
						<Button variant="contained" type="submit" value="CADASTRAR">
							{t("login.buttons.register")}
						</Button>
						<Stack direction="row" spacing={1} onClick={(e) => navigate("/login")}>
							<Typography variant="subtitle">{t("login.login_text")}</Typography>
							<Typography variant="subtitle" sx={{ fontWeight: "bold", color: "primary.main", cursor: "pointer" }}>
								{t("login.sign_in")}
							</Typography>
						</Stack>
					</Box>
				</Grid2>

				<Grid2
					size={{ xs: 0, md: 6, lg: 4 }}
					sx={{ backgroundColor: "grey.200", p: 5, display: { xs: "none", md: "block" } }}
				>
					<Stack justifyContent="center" sx={{ width: "100%", height: "100%" }}>
						<Box
							component="img"
							sx={{
								height: "auto",
								width: "100%",
							}}
							alt="Imagem de verificação de estoque"
							src={stock}
						/>
						<Typography variant="h2">{t("login.messages.sign_in.welcome")}</Typography>
						<Typography variant="subtitle">{t("login.messages.sign_in.welcome_text")}</Typography>
					</Stack>
				</Grid2>
			</Grid2>
		</>
	);
}
