import { useNavigate } from "react-router-dom";

import { t } from "i18next";

import { Icon } from "@iconify/react";

import { Box, Container, Grid, Stack, Typography } from "@mui/material";

import useDownloadTemplateItems from "../../hooks/useDownloadTemplateItems";

//----------------------------------------------------------------

export default function Guides() {
	const handleTemplateDownload = useDownloadTemplateItems();

	const guides = [
		{
			name: t("settings.guides.readers"),
			icon: "bx:barcode-reader",
			link: "https://drive.google.com/drive/folders/1RJvB3bs5hF4M1WRsU8DHNb8PTlO6Yu7B?usp=share_link",
		},
		{
			name: t("settings.guides.import"),
			icon: "tabler:file-import",
			function: () => {
				handleTemplateDownload();
			},
		},
		{
			name: t("settings.guides.print"),
			icon: "ph:printer",
			link: "https://drive.google.com/drive/u/0/folders/1zhNsNm4vRlxmVgGr0YVLwzXO5gCRliRG",
		},
		{
			name: t("settings.guides.inovaone"),
			icon: "fluent:shield-48-regular",
			link: "https://drive.google.com/drive/folders/1mAop5WfB1GgU6YnwfY9haCF1JYKV7QQK?usp=share_link",
		},
		{
			name: t("settings.guides.tag"),
			icon: "wpf:rfid-tag",
			link: "https://drive.google.com/drive/folders/1IxVEuThtv_xaZYu0AzbpIcuhS0ANZZ_k?usp=share_link",
		},
		{
			name: t("settings.guides.users"),
			icon: "fa-solid:users-cog",
			link: "/guides/user_guide",
		},
	];

	return (
		<>
			<Stack spacing={3}>
				<Typography variant="h2">{t("menu.guides")}</Typography>
				<Grid container xs={10} spacing={3}>
					{guides.map((guide) => (
						<Grid item xs={12} md={6} sx={{ color: "primary.main" }}>
							<Stack
								direction="row"
								alignItems="center"
								sx={{
									px: 2,
									py: 3,
									border: 1,
									borderRadius: 2,
									backgroundColor: "common.white",
									borderColor: "grey.300",
									cursor: "pointer",
									"&:hover": {
										boxShadow: 3,
									},
								}}
								onClick={(e) => {
									if (guide.function) {
										guide.function();
									} else {
										window.open(guide.link);
									}
								}}
							>
								<Icon icon={guide.icon} width="24" />
								<Typography variant="" sx={{ ml: 3 }}>
									{guide.name}
								</Typography>
							</Stack>
						</Grid>
					))}
				</Grid>
			</Stack>
		</>
	);
}
