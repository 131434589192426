import { useEffect, useState } from "react";

import { Button, Chip, Grid, Paper, Stack, Typography } from "@mui/material";

import { common } from "@mui/material/colors";

import WidgetItemsByLevel from "../../Widgets/WidgetItemsByLevel.js";

import TableTracking from "./TableTracking.js";

import ItemsByLevels from "./ItemsByLevels.js";

import ItemsByDays from "./ItemsByDays.js";

import api from "../../../../services/api.js";

import dayjs from "dayjs";
import { t } from "i18next";
import axios from "axios";
import Alert from "../../../../components/ui/Alert.js";
import { Icon } from "@iconify/react/dist/iconify.js";

//------------------------------------------------------------------------------

export default function CBA() {
	const [loading, setLoading] = useState(false);

	const [movList, setMovList] = useState([]);

	const [categoriaList, setCategoriaList] = useState([]);

	const [categoria, setCategoria] = useState("");

	const [interval, setInterval] = useState("");

	const [nivel1, setNivel1] = useState("");

	const [nivel2, setNivel2] = useState("");

	const [nivel3, setNivel3] = useState("");

	const [nivel4, setNivel4] = useState("");

	const [message, setMessage] = useState();

	useEffect(() => {
		function getData() {
			setLoading(true);
			const { startDate, endDate } = interval;
			api
				.get(
					`/reg_mov_itens/${sessionStorage.getItem("idConta")}/*/*/*/${nivel1?._id ?? "*"}/${nivel2?._id ?? "*"}/${nivel3?._id ?? "*"}/${nivel4?._id ?? "*"}?dt_registro_inicial=${startDate ? dayjs(startDate).format("YYYY-MM-DD") : "*"}&dt_registro_final=${endDate ? dayjs(endDate).add(1, "day").format("YYYY-MM-DD") : "*"}`
				)
				.then((response) => {
					let res = response.data.filter((item) => item._id) || null;
					if (res && categoria) {
						res = res.filter((item) => item?.id_item?.id_categoria === categoria._id);
					}
					setMovList(res);

					setLoading(false);
				});
		}

		getData();
	}, [interval, nivel1, nivel2, nivel3, nivel4, categoria]);

	useEffect(() => {
		function getCategorias() {
			setLoading(true);

			api.get(`/categoria/${sessionStorage.getItem("idConta")}/*/*/*/*`).then((response) => {
				response.data.pop();
				if (response.data.length) {
					setCategoriaList(response.data);
					setLoading(false);
				}
			});
		}

		getCategorias();
	}, []);

	return (
		<>
			<Stack spacing={3}>
				<Stack direction="row" justifyContent="space-between" alignItems="center">
					<Typography variant="h1">Dashboard</Typography>
					<Button
						variant="contained"
						startIcon={<Icon icon="mage:external-link" />}
						onClick={() => {
							window.open(
								"https://app.powerbi.com/view?r=eyJrIjoiN2VjMTNhYjUtYjNjNS00NmFjLTkyOTgtMjVhMjNhMzUxOGI4IiwidCI6IjhlOTQ2MjRmLTU1YzUtNGFiMi1hMDhiLTY2Mjk3NTYwODYyNSJ9"
							);
						}}
					>
						BI
					</Button>
				</Stack>
				<Grid container justifyContent="space-between" spacing={1}>
					<Grid item xs={12} md={4}>
						<ItemsByDays />
					</Grid>
					<Grid item xs={12} md={8}>
						<ItemsByLevels
							onChangeInterval={(e) => {
								setInterval(e);
							}}
							onChangeCategoria={(e) => {
								setCategoria(e);
							}}
							onChangeNivel1={(e) => {
								setNivel1(e);
							}}
							onChangeNivel2={(e) => {
								setNivel2(e);
							}}
							onChangeNivel3={(e) => {
								setNivel3(e);
							}}
							onChangeNivel4={(e) => {
								setNivel4(e);
							}}
						/>
					</Grid>
				</Grid>

				<Stack
					component={Paper}
					sx={{
						p: 1,
						borderRadius: 2,
						"& .MuiDataGrid-cell": {
							display: "flex",
							alignItems: "center",
						},
					}}
				>
					<Stack spacing={2} direction="row" sx={{ p: 2 }}>
						<Typography>{t("common.filter")}s : </Typography>
						{categoria ? (
							<Chip
								label={categoria.descricao}
								onDelete={(e) => {
									setCategoria("");
								}}
							/>
						) : (
							""
						)}
						{interval ? (
							<>
								<Chip
									label={dayjs(interval.startDate).format("DD/MM/YYYY")}
									onDelete={(e) => {
										setInterval("");
									}}
								/>
								<Chip
									label={dayjs(interval.endDate).format("DD/MM/YYYY")}
									onDelete={(e) => {
										setInterval("");
									}}
								/>
							</>
						) : (
							""
						)}
						{nivel1 ? (
							<Chip
								label={nivel1.descricao}
								onDelete={(e) => {
									setNivel1("");
								}}
							/>
						) : (
							""
						)}
						{nivel2 ? (
							<Chip
								label={nivel2.descricao}
								onDelete={(e) => {
									setNivel2("");
								}}
							/>
						) : (
							""
						)}
						{nivel3 ? (
							<Chip
								label={nivel3.descricao}
								onDelete={(e) => {
									setNivel3("");
								}}
							/>
						) : (
							""
						)}
						{nivel4 ? (
							<Chip
								label={nivel4.descricao}
								onDelete={(e) => {
									setNivel4("");
								}}
							/>
						) : (
							""
						)}
					</Stack>

					<TableTracking movList={movList} categoriaList={categoriaList} />
				</Stack>
			</Stack>

			{message ? (
				<Alert
					type={message.type}
					title={message.title}
					message={message.message}
					onClose={(e) => {
						setMessage(null);
					}}
				/>
			) : (
				""
			)}
		</>
	);
}
