import { useEffect, useState } from "react";

import { useNavigate, useParams } from "react-router-dom";

import { t } from "i18next";

import {
	Button,
	Chip,
	Container,
	Divider,
	FormControl,
	FormLabel,
	Grid2,
	InputLabel,
	MenuItem,
	Paper,
	Select,
	Stack,
	Typography,
} from "@mui/material";

import useDownloadTemplateItems from "../../../hooks/useDownloadTemplateItems.js";

import { green, grey, red, yellow } from "@mui/material/colors";

import { Icon } from "@iconify/react/dist/iconify.js";

import { DataGrid } from "@mui/x-data-grid";

import { ptBR } from "@mui/x-data-grid/locales";

import api from "../../../services/api.js";

import Loading from "../../../components/ui/Loading.js";

import Alert from "../../../components/ui/Alert.js";

import CustomModal from "../../../components/ui/CustomModal.js";

import ImportCSV from "../../../components/Import/ImportCSV.js";

import ImportXML from "../../../components/Import/ImportXML.js";

import ImportERP from "../../../components/Import/ImportERP.js";

// ----------------------------------------------------------

const columns = [
	{
		field: "id",
		headerName: "ID",
		width: 90,
	},
	{
		headerName: "Status",
		field: "status",
		width: 200,
		filterable: true,
		renderCell: (params) => (
			<Chip
				label={params.row.status}
				sx={{
					borderRadius: 1,
					fontWeight: 600,
					backgroundColor: params.row.status.includes("Erro")
						? red[100]
						: params.row.status == "Correto"
							? green[50]
							: "",
					color: params.row.status.includes("Erro")
						? red[600]
						: params.row.status == "Correto"
							? green[600]
							: grey[600],
				}}
			/>
		),
	},
	{ headerName: "Tag", field: "tag", width: 300 },
	{ headerName: "Categoria", field: "categoria", width: 300 },
	{ headerName: "EAN", field: "ean" },
	{ headerName: "Marca", field: "marca" },
	{ headerName: "Modelo", field: "modelo" },
	{ headerName: "Nível Loc 1", field: "nivel_loc_1" },
	{ headerName: "Nível Loc 2", field: "nivel_loc_2" },
	{ headerName: "Nível Loc 3", field: "nivel_loc_3" },
	{ headerName: "Nível Loc 4", field: "nivel_loc_4" },
	{ headerName: "Informação Complementar 1", field: "inf_compl_1" },
	{ headerName: "Informação Complementar 2", field: "inf_compl_2" },
	{ headerName: "Informação Complementar 3", field: "inf_compl_3" },
	{ headerName: "Informação Complementar 4", field: "inf_compl_4" },
	{ headerName: "Informação Complementar 5", field: "inf_compl_5" },
	{ headerName: "Informação Complementar 6", field: "inf_compl_6" },
	{ headerName: "Informação Complementar 7", field: "inf_compl_7" },
	{ headerName: "Informação Complementar 8", field: "inf_compl_8" },
	{ headerName: "Informação Complementar 9", field: "inf_compl_9" },
	{ headerName: "Informação Complementar 10", field: "inf_compl_10" },
	{ headerName: "Valor", field: "valor" },
	{ headerName: "Parceiro", field: "parceiro" },
	{ headerName: "Observação", field: "observacao" },
	{ headerName: "Ativo", field: "ativo" },
	{ headerName: "Foto", field: "foto" },
	{ headerName: "ID Externo", field: "id_externo" },
	{ headerName: "Item Vinculado", field: "id_item_vinculado" },
	{ headerName: "UP 1", field: "up_1" },
	{ headerName: "UP 2", field: "up_2" },
	{ headerName: "UP 3", field: "up_3" },
	{ headerName: "UP 4", field: "up_4" },
	{ headerName: "UP 5", field: "up_5" },
	{ headerName: "Latitude", field: "latitude" },
	{ headerName: "Longitude", field: "longitude" },
];

const keys = [
	"id_item_vinculado",
	"id_externo",
	"ativo",
	"tag",
	"id_categoria",
	"ean",
	"inf_compl_1",
	"inf_compl_2",
	"inf_compl_3",
	"inf_compl_4",
	"inf_compl_5",
	"id_parceiro",
	"id_nivel_loc_1",
	"id_nivel_loc_2",
	"id_nivel_loc_3",
	"id_nivel_loc_4",
	"observacao",
	"valor",
	"inf_compl_6",
	"inf_compl_7",
	"inf_compl_8",
	"inf_compl_9",
	"inf_compl_10",
	"up_1",
	"up_2",
	"up_3",
	"up_4",
	"up_5",
	"latitude",
	"longitude",
	"id_marca",
	"id_modelo",
];

const optionsFields = [
	{ label: "Tag", value: "tag" },
	{ label: "Categoria", value: "id_categoria" },
	{ label: "EAN", value: "ean" },
	{ label: "Nível Loc 1", value: "id_nivel_loc_1" },
	{ label: "Nível Loc 2", value: "id_nivel_loc_2" },
	{ label: "Nível Loc 3", value: "id_nivel_loc_3" },
	{ label: "Nível Loc 4", value: "id_nivel_loc_4" },
	{ label: "Informação Complementar 1", value: "inf_compl_1" },
	{ label: "Informação Complementar 2", value: "inf_compl_2" },
	{ label: "Informação Complementar 3", value: "inf_compl_3" },
	{ label: "Informação Complementar 4", value: "inf_compl_4" },
	{ label: "Informação Complementar 5", value: "inf_compl_5" },
	{ label: "Informação Complementar 6", value: "inf_compl_6" },
	{ label: "Informação Complementar 7", value: "inf_compl_7" },
	{ label: "Informação Complementar 8", value: "inf_compl_8" },
	{ label: "Informação Complementar 9", value: "inf_compl_9" },
	{ label: "Informação Complementar 10", value: "inf_compl_10" },
	{ label: "Marca", value: "id_marca" },
	{ label: "Modelo", value: "id_modelo" },
	{ label: "Valor", value: "valor" },
	{ label: "Parceiro", value: "id_parceiro" },
	{ label: "Observação", value: "observacao" },
	{ label: "Ativo", value: "ativo" },
	{ label: "Foto", value: "foto" },
	{ label: "ID Externo", value: "id_externo" },
	{ label: "Item Vinculado", value: "id_item_vinculado" },
	{ label: "UP 1", value: "up_1" },
	{ label: "UP 2", value: "up_2" },
	{ label: "UP 3", value: "up_3" },
	{ label: "UP 4", value: "up_4" },
	{ label: "UP 5", value: "up_5" },
	{ label: "Latitude", value: "latitude" },
	{ label: "Longitude", value: "longitude" },
];

const ModeloDefaultV1 = {
	fields: [
		"id_item_vinculado",
		"id_externo",
		"ativo",
		"tag",
		"id_categoria",
		"ean",
		"inf_compl_1",
		"inf_compl_2",
		"inf_compl_3",
		"inf_compl_4",
		"inf_compl_5",
		"id_parceiro",
		"id_nivel_loc_1",
		"id_nivel_loc_2",
		"id_nivel_loc_3",
		"id_nivel_loc_4",
		"observacao",
		"valor",
		"inf_compl_6",
		"inf_compl_7",
		"inf_compl_8",
		"inf_compl_9",
		"inf_compl_10",
		"up_1",
		"up_2",
		"up_3",
		"up_4",
		"up_5",
		"id_item",
		"latitude",
		"longitude",
		"id_marca",
		"id_modelo",
	],
	fixedField: [""],
	xmlUploadFields: [
		"0",
		"1",
		"2",
		"3",
		"4",
		"5",
		"6",
		"7",
		"8",
		"9",
		"10",
		"11",
		"12",
		"13",
		"14",
		"15",
		"16",
		"17",
		"18",
		"19",
		"20",
		"21",
		"22",
		"23",
		"24",
		"25",
		"26",
		"27",
		"29",
		"30",
		"31",
		"32",
		"33",
	],
	position: "cell",
	keyTag: "",
	header: false,
	name: "Padrão",
	_id: "default-model-v1",
};

// ----------------------------------------------------------

export default function ImportItems() {
	const { format = "csv" } = useParams();

	const navigate = useNavigate();

	const id_conta = sessionStorage.getItem("idConta");

	const [loading, setLoading] = useState(false);

	const [loadingText, setLoadingText] = useState("");

	const [message, setMessage] = useState(); // Armazena mensagens e erros

	// Configurações
	const [configImport, setConfigImport] = useState(ModeloDefaultV1); // Configuração do import

	const [modeloID, setModeloID] = useState(ModeloDefaultV1?._id || ""); // Modelo de importação padrão

	const [modelos, setModelos] = useState([]); // Lista de modelos de importação

	const [prefix, setPrefix] = useState(""); // Prefixo da tag

	// Listas que já existem na conta
	const [categorias, setCategorias] = useState([]); // Lista de categorias na base

	const [itemsList, setitemsList] = useState([]); // Lista de itens na base

	const [parceiros, setParceiros] = useState([]); // Lista de parceiros na base

	const [nivel1, setNivel1] = useState([]);

	const [nivel2, setNivel2] = useState([]);

	const [nivel3, setNivel3] = useState([]);

	const [nivel4, setNivel4] = useState([]);

	const [marcas, setMarcas] = useState([]);

	// Upcomming - Listas que serão importadas
	const [items, setItems] = useState([]); // Upcomming Items: Itens que foram importados

	const [upComming, setUpComming] = useState(); // Itens que serão importados

	const [upMarcas, setUpMarcas] = useState([]); // Marcas que serão importadas

	const [importCount, setImportCount] = useState(); // Contagem de itens por status

	const [fileHeader, setFileHeader] = useState();

	const [fileData, setFileData] = useState([]);

	const [showModal, setShowModal] = useState(false);

	// Funções
	const handleTemplateDownload = useDownloadTemplateItems();

	// Requisita configurações da conta e modelos
	useEffect(() => {
		function getSettings() {
			api.get(`/conta_config/${id_conta}`).then((response) => {
				const data = response.data.find((i) => i?._id);
				setPrefix(data?.item_verif_inicial);
				const resModelos = data?.import?.itens || [];
				setModelos(resModelos);
				if (resModelos?.length && !configImport) {
					const modeloSelecionado = resModelos[0];
					setConfigImport(modeloSelecionado);
					setModeloID(modeloSelecionado?._id);
				}
			});
		}
		getSettings();
	}, []);

	useEffect(() => {
		// # Requisição de itens
		async function getItems() {
			setLoading(true);
			setLoadingText(`${t("actions.downloading")} ${t("common.items")}`);
			await api
				.get(`/item/${id_conta}/*/*/*/*/*/*/*/*/*/*/*/*/*?regForm=true`)
				.then((response) => {
					setitemsList(response?.data?.filter((item) => item?._id));
				})
				.catch((error) => {
					setMessage({
						type: "error",
						title: t("messages.errors.error"),
						message: t("messages.errors.500"),
					});
				})
				.finally(() => {
					setLoadingText("");
					setLoading(false);
				});
		}
		getItems();

		// # Requisição de Categorias
		async function getcategorias() {
			setLoading(true);
			setLoadingText(`${t("actions.downloading")} ${t("common.categories")}`);
			await api
				.get(`/categoria/${id_conta}/*/*/*/1`, {})
				.then((response) => {
					setCategorias(response?.data?.filter((item) => item?._id));
				})
				.finally(() => {
					setLoadingText("");
					setLoading(false);
				});

			// # Requisição de Marcas / Modelos
			setLoading(true);
			setLoadingText(`${t("actions.downloading")} ${t("common.brand")}`);
			await api
				.get(`/infs_compl/${id_conta}`)
				.then((response) => {
					setMarcas(response.data?.filter((item) => item?._id));
				})
				.finally(() => {
					setLoadingText("");
					setLoading(false);
				});
		}

		getcategorias();

		async function getPartners() {
			setLoading(true);
			setLoadingText(`${t("actions.downloading")} ${t("common.partner")}`);
			await api
				.get(`/parceiro/${id_conta}/*/*/*/1`, {})
				.then((response) => {
					setParceiros(response?.data?.filter((item) => item?._id));
				})
				.finally(() => {
					setLoadingText("");
					setLoading(false);
				});
		}

		getPartners();

		async function getLocates() {
			setLoading(true);
			setLoadingText(`${t("actions.downloading")} ${t("common.locate")}`);
			await api
				.get(`/nivel_loc1/${id_conta}/*/*/*/*`, {})
				.then((response) => {
					setNivel1(response?.data?.filter((item) => item?._id));
				})
				.finally(() => {
					setLoadingText("");
					setLoading(false);
				});

			await api
				.get(`/nivel_loc2/${id_conta}/*/*/*/*/*`, {})
				.then((response) => {
					setNivel2(response?.data?.filter((item) => item?._id));
				})
				.finally(() => {
					setLoadingText("");
					setLoading(false);
				});
			await api
				.get(`/nivel_loc3/${id_conta}/*/*/*/*/*`, {})
				.then((response) => {
					setNivel3(response?.data?.filter((item) => item?._id));
				})
				.finally(() => {
					setLoadingText("");
					setLoading(false);
				});
			await api
				.get(`/nivel_loc4/${id_conta}/*/*/*/*/*`, {})
				.then((response) => {
					setNivel4(response?.data?.filter((item) => item?._id));
				})
				.finally(() => {
					setLoadingText("");
					setLoading(false);
				});
		}

		getLocates();
	}, []);

	function getID() {
		return "xxxxxxxx-yxxx".replace(/[xy]/g, function (c) {
			var r = (Math.random() * 16) | 0,
				v = c === "x" ? r : (r & 0x3) | 0x8;
			return v.toString(16);
		});
	}

	function getItemID() {
		// Obtém a data e a hora atuais
		const now = new Date();

		// Formata a data no formato AAAAMMDD
		const year = now.getFullYear();
		const month = String(now.getMonth() + 1).padStart(2, "0"); // Mês de 0-11 para 1-12
		const day = String(now.getDate()).padStart(2, "0"); // Dia do mês de 1-31

		// Concatena a data e a hora no formato AAAAMMDD-HHMMSS
		const formattedDateTime = `${year}${month}${day}`;

		// Gera a parte aleatória do ID
		const randomID = "xxxxxxxx-yxxx".replace(/[xy]/g, function (c) {
			var r = (Math.random() * 16) | 0,
				v = c === "x" ? r : (r & 0x3) | 0x8;
			return v.toString(16);
		});

		// Combina a data e a hora com o ID aleatório
		return `${randomID}_${sessionStorage.getItem("idConta")}${formattedDateTime}`;
	}

	function getStatus(tag, categoria, duplicado, valor, nivel1, getItem, campoIgnorar, idLido) {
		if (idLido) {
			return "Erro: Item com ID Duplicado";
		}

		if (campoIgnorar) {
			return "Erro: Campo inválido";
		}

		const regex = /^[0-9A-Fa-f]+$/;
		if (typeof tag === "string" && !regex.test(tag)) {
			return "Erro: Tag Inválida";
		}

		if (!categoria) {
			return "Erro: Categoria Ausente";
		}

		if (duplicado) {
			return "Erro: Tag Duplicada";
		}

		if (valor && isNaN(valor)) {
			return "Erro: Campo valor em formato de texto";
		}

		if (!nivel1) {
			return "Erro: Nivel 1 ausente";
		}

		if (getItem?._id) {
			return "Registrado";
		}

		return "Correto";
	}

	useEffect(() => {
		if (fileData.length && !configImport) {
			setShowModal(true);
		} else if (fileData.length) {
			handleUpload(fileData);
		}
	}, [fileData]);

	function handleFileCSV(e) {
		const { header, data } = e;

		setFileHeader(header);

		setFileData(data);
	}

	function handleFileXML(uploadFile) {
		if (!configImport || !uploadFile || !uploadFile?.length) {
			setMessage({
				type: "error",
				title: t("messages.error"),
				message:
					"Ocorreu um erro ao tentar ler a planilha. Por favor, verifique as configurações de importação e tente novamente.",
			});
			return;
		}
		handleUploadFile(uploadFile);
	}

	function handleUpload(file) {
		if (configImport) {
			const { fields, xmlUploadFields, fixedField = [] } = configImport;

			const rows = file.map((item) => {
				const row = {};

				fields?.forEach((field, index) => {
					row[field] = fixedField[index] ? xmlUploadFields[index] : item[xmlUploadFields[index]];
				});

				return row;
			});
			handleUploadFile(rows);
		} else {
			const rows = file.map((item) => {
				const row = {};
				keys.forEach((key, index) => {
					row[key] = Object.values(item)[index];
				});

				return row;
			});

			handleUploadFile(rows);
		}
	}

	async function handleSave() {
		setLoading(true);
		let option = { headers: { "Content-Type": ["application/json"] } };
		await api
			.post(`/itensDef/${sessionStorage.getItem("idConta")}`, [upComming], option)
			.then(
				(response) => {
					if (upMarcas.length) {
						api.post("/infs_compl", upMarcas, option).then(
							(response) => {
								setMessage({
									type: "success",
									title: t("messages.success"),
									message: t("messages.success_save"),
								});
							},
							(error) => {
								setMessage({
									type: "error",
									title: t("messages.errors.error"),
									message: t("messages.errors.500"),
								});
							}
						);
					} else {
						setMessage({
							type: "success",
							title: t("messages.success"),
							message: t("messages.success_save"),
						});
					}
				},
				(error) => {
					setMessage({
						type: "error",
						title: t("messages.errors.error"),
						message: t("messages.errors.500"),
					});
				}
			)
			.finally(() => {
				setLoading(false);
			});
	}

	function handleNavigate() {
		const propsConfig = {
			modulo: "itens",
			redirectTo: "/items/import/" + format,
			format: format,
			opcoesCampos: optionsFields,
		};
		navigate("/config", { state: { ...propsConfig } });
	}

	async function handleUploadFile(uploadFile) {
		try {
			const upItems = [];
			const upCategorias = [];
			const upNivel1 = [];
			const upNivel2 = [];
			const upNivel3 = [];
			const upNivel4 = [];
			const upParceiros = [];
			let upcomingMarcas = [];
			const upIDs = new Set();

			const categoriaMap = new Map((categorias || []).map((cat) => [cat.descricao, cat]));
			const parceiroMap = new Map((parceiros || []).map((parc) => [parc.nome, parc]));
			const nivel1Map = new Map((nivel1 || []).map((niv) => [niv.descricao, niv]));
			const nivel2Map = new Map((nivel2 || []).map((niv) => [niv.descricao, niv]));
			const nivel3Map = new Map((nivel3 || []).map((niv) => [niv.descricao, niv]));
			const nivel4Map = new Map((nivel4 || []).map((niv) => [niv.descricao, niv]));
			let marcaMap = new Map((marcas || []).map((marca) => [marca.marca, marca]));
			const dt_criacao = new Date();
			const processedTags = new Set();

			for (let i = 0; i <= uploadFile.length; i++) {
				if (!uploadFile[i]) continue;

				const {
					tag = "",
					id_categoria,
					ean,
					id_nivel_loc_1,
					id_nivel_loc_2,
					id_nivel_loc_3,
					id_nivel_loc_4,
					inf_compl_1,
					inf_compl_2,
					inf_compl_3,
					inf_compl_4,
					inf_compl_5,
					inf_compl_6,
					inf_compl_7,
					inf_compl_8,
					inf_compl_9,
					inf_compl_10,
					id_marca,
					id_modelo,
					valor,
					id_parceiro,
					observacao,
					ativo,
					foto,
					id_externo,
					id_item_vinculado,
					up_1,
					up_2,
					up_3,
					up_4,
					up_5,
					latitude,
					longitude,
				} = uploadFile[i];

				const tagStr = tag ? String(tag).toUpperCase() : "0";
				const prefixStr = prefix || "";
				const tagPadded = tagStr.padStart(24 - prefixStr?.length, "0"); // Ajusta para o tamanho correto
				const formatTag = prefixStr + tagPadded;

				//if (processedTags.has(formatTag)) continue;
				processedTags.add(formatTag);
				let getItem = null;
				if (tag) {
					getItem = itemsList.find((item) => item.tag === formatTag);
				} else if (id_externo) {
					getItem = itemsList.find((item) => item.id_externo === id_externo);
				}
				const getCategoria = categoriaMap.get(id_categoria);
				const getParceiro = parceiroMap.get(id_parceiro);
				const getNivel1 = nivel1Map.get(id_nivel_loc_1);
				let getNivel2 = nivel2Map.get(id_nivel_loc_2);
				if (id_nivel_loc_2 && getNivel2 && getNivel2?.id_nivel_loc_1 === getNivel1?._id) {
					getNivel2 = null;
				}
				let getNivel3 = nivel3Map.get(id_nivel_loc_3);
				if (id_nivel_loc_3 && getNivel3 && getNivel3?.id_nivel_loc_2 === getNivel2?._id) {
					getNivel3 = null;
				}
				let getNivel4 = nivel4Map.get(id_nivel_loc_4);
				if (id_nivel_loc_4 && getNivel4 && getNivel4?.id_nivel_loc_3 === getNivel3?._id) {
					getNivel4 = null;
				}
				const getMarca = getCategoria && id_marca ? marcaMap.get(id_marca) : "";
				const getModelo = getMarca ? getMarca?.modelo?.find((c) => c.descricao === id_modelo) : "";
				const getValor = valor
					? valor
							.replace(/[^\d,]/g, "") // Remove tudo que não for número ou vírgula
							.replace(",", ".") // Substitui a vírgula por ponto
					: "";
				// TRATATIVA NESTLE
				const campoIgnorar =
					sessionStorage.getItem("idConta") === "0vEHm35wt" &&
					inf_compl_4 &&
					(inf_compl_4?.includes("Retired") || inf_compl_4?.includes("Missing"));

				const itemID = getItem?._id || getItemID();
				const hasID = upIDs.has(itemID);

				const item = {
					id: i,
					_id: itemID,
					id_conta: id_conta,
					id_categoria: id_categoria ? getCategoria?._id || getID() : getItem?.id_categoria || null,
					categoria: getCategoria?.descricao || id_categoria,
					ean: getCategoria?.ean || ean || "",
					tag: formatTag,
					status: getStatus(
						formatTag,
						id_categoria,
						false,
						getValor,
						getNivel1?._id || id_nivel_loc_1,
						getItem,
						campoIgnorar,
						hasID
					),
					id_nivel_loc_1: id_nivel_loc_1 ? getNivel1?._id || getID() : getItem?.id_nivel_loc_1 || "",
					id_nivel_loc_2: id_nivel_loc_2 ? getNivel2?._id || getID() : "",
					id_nivel_loc_3: id_nivel_loc_3 ? getNivel3?._id || getID() : "",
					id_nivel_loc_4: id_nivel_loc_4 ? getNivel4?._id || getID() : "",
					nivel_loc_1: id_nivel_loc_1,
					nivel_loc_2: id_nivel_loc_2,
					nivel_loc_3: id_nivel_loc_3,
					nivel_loc_4: id_nivel_loc_4,
					inf_compl_1: String(inf_compl_1 || ""),
					inf_compl_2: String(inf_compl_2 || ""),
					inf_compl_3: String(inf_compl_3 || ""),
					inf_compl_4: String(inf_compl_4 || ""),
					inf_compl_5: String(inf_compl_5 || ""),
					inf_compl_6: String(inf_compl_6 || ""),
					inf_compl_7: String(inf_compl_7 || ""),
					inf_compl_8: String(inf_compl_8 || ""),
					inf_compl_9: String(inf_compl_9 || ""),
					inf_compl_10: String(inf_compl_10 || ""),
					id_marca: id_marca ? getMarca?._id || getID() : "",
					id_modelo: id_modelo ? getModelo?._id || getID() : "",
					marca: id_marca,
					modelo: id_modelo,
					valor: getValor || "",
					id_parceiro: id_parceiro ? getParceiro?._id || getID() : "",
					parceiro: getParceiro?.descricao || id_parceiro,
					ativo: ativo || 1,
					foto: foto || "",
					id_externo: id_externo || "",
					id_item_vinculado: id_item_vinculado || "",
					up_1: up_1 || "",
					up_2: up_2 || "",
					up_3: up_3 || "",
					up_4: up_4 || "",
					up_5: up_5 || "",
					observacao: observacao || "",
					latitude: latitude || "",
					longitude: longitude || "",
					dt_criacao: dt_criacao,
				};

				upItems.push(item);
				upIDs.add(itemID);

				if (!getCategoria && id_categoria) {
					const upCat = {
						_id: item.id_categoria,
						descricao: item.categoria,
						ean: item.ean,
						id_conta: id_conta,
					};
					upCategorias.push(upCat);
					categoriaMap.set(id_categoria, upCat);
				}

				if (!getNivel1 && id_nivel_loc_1) {
					const upNivel = {
						_id: item.id_nivel_loc_1,
						descricao: item.nivel_loc_1,
						id_conta: id_conta,
					};
					upNivel1.push(upNivel);
					nivel1Map.set(id_nivel_loc_1, upNivel);
				}

				if (!getNivel2 && id_nivel_loc_2) {
					const upNivel = {
						_id: item.id_nivel_loc_2,
						descricao: item.nivel_loc_2,
						id_nivel_loc1: item.id_nivel_loc_1,
						id_conta: id_conta,
					};
					upNivel2.push(upNivel);
					nivel2Map.set(id_nivel_loc_2, upNivel);
				}

				if (!getNivel3 && id_nivel_loc_3) {
					const upNivel = {
						_id: item.id_nivel_loc_3,
						descricao: item.nivel_loc_3,
						id_nivel_loc2: item.id_nivel_loc_2,
						id_conta: id_conta,
					};
					upNivel3.push(upNivel);
					nivel3Map.set(id_nivel_loc_3, upNivel);
				}

				if (!getNivel4 && id_nivel_loc_4) {
					const upNivel = {
						_id: item.id_nivel_loc_4,
						descricao: item.nivel_loc_4,
						id_nivel_loc3: item.id_nivel_loc_3,
						id_conta: id_conta,
					};
					upNivel4.push(upNivel);
					nivel4Map.set(id_nivel_loc_4, upNivel);
				}

				if (!getMarca && id_marca) {
					const upMarca = {
						_id: item.id_marca,
						marca: item.marca,
						id_categoria: item.id_categoria,
						modelo: [],
						id_conta: id_conta,
					};
					upcomingMarcas.push(upMarca);
					marcaMap.set(id_marca, upMarca);
				}

				if (!getModelo && id_modelo) {
					const upMarca = marcaMap.get(id_marca);
					if (upMarca) {
						const newModelo = {
							_id: item.id_modelo,
							id_conta: id_conta,
							descricao: item.modelo,
						};
						upMarca.modelo.push(newModelo);
					}
				}

				if (!getParceiro && id_parceiro) {
					const upParceiro = {
						_id: item.id_parceiro,
						nome: item.parceiro,
						id_conta: id_conta,
					};
					upParceiros.push(upParceiro);
					parceiroMap.set(id_parceiro, upParceiro);
				}
			}
			const itensValidos = [...upItems].filter((item) => !item.status.includes("Erro"));

			setItems(upItems);
			setUpMarcas(upcomingMarcas);

			setUpComming({
				categoria: upCategorias,
				itens: itensValidos,
				nivel1: upNivel1,
				nivel2: upNivel2,
				nivel3: upNivel3,
				nivel4: upNivel4,
				parceiros: upParceiros,
			});

			setImportCount([
				{
					color: green[600],
					label: "Novos Itens",
					icon: "lets-icons:check-ring-duotone",
					value: upItems.filter((item) => item.status.includes("Correto"))?.length,
				},
				{
					color: grey[500],
					label: "Já cadastrados",
					icon: "lets-icons:check-ring-duotone",
					value: upItems.filter((item) => item.status.includes("Registrado"))?.length,
				},
				{
					color: red[300],
					label: "Tag repetidas",
					icon: "lets-icons:close-ring-duotone",
					value: upItems.filter((item) => item.status.includes("Duplicada"))?.length,
				},
				{
					color: red[300],
					label: "Itens incorretos",
					icon: "lets-icons:close-ring-duotone",
					value: upItems.filter((item) => item.status.includes("Erro"))?.length,
				},
				{
					color: grey[500],
					label: "Itens importados",
					icon: "lets-icons:folder-check-duotone",
					value: upItems.filter((item) => !item.status.includes("Erro"))?.length,
				},
				{
					color: grey[500],
					label: "Total de itens",
					icon: "lets-icons:folder-up-duotone",
					value: upItems?.length,
				},
			]);
		} finally {
			setLoading(false);
		}
	}

	function formatConfig(propsModulo = {}) {
		const data = propsModulo;
		return data;
	}

	return (
		<>
			<Loading show={loading} text={loadingText} />

			{message ? (
				<Alert
					type={message.type}
					title={message.title}
					message={message.message}
					onClose={(e) => {
						setMessage(e);
					}}
				/>
			) : (
				""
			)}

			<Container maxWidth="xl">
				<Stack spacing={3}>
					<Stack direction="row" justifyContent="space-between">
						<Typography variant="h1">{t("actions.import")}</Typography>
						<Stack spacing={1} direction="row">
							<Button
								variant="outlined"
								color="dark"
								onClick={(e) => {
									navigate("/items");
								}}
							>
								{t("actions.exit")}
							</Button>
							<Button
								variant="contained"
								startIcon={<Icon icon="iconoir:cloud-upload" />}
								disabled={!items.length}
								onClick={(e) => {
									handleSave();
								}}
							>
								{t("actions.import")}
							</Button>
						</Stack>
					</Stack>

					<Stack spacing={3} as={Paper} sx={{ borderRadius: 3, p: 3 }}>
						<Stack direction="row" justifyContent="space-between" alignItems="center">
							<div>
								<Typography variant="h3">{t("items.manage_import")}</Typography>
								<Typography variant="subtitle">
									Configure a importação ou faça o upload de um arquivo no formato padrão.
								</Typography>
							</div>
							<Stack>
								<Button
									color="dark"
									startIcon={<Icon icon="ic:round-download" />}
									onClick={(e) => {
										handleTemplateDownload();
									}}
								>
									{t("actions.download")} {t("export.model")}
								</Button>
							</Stack>
						</Stack>

						<Grid2 container alignItems="center" spacing={1}>
							<Grid2 size={3}>
								<FormControl fullWidth>
									<InputLabel id="model-input">{t("menu.settings")}</InputLabel>
									<Select
										size="small"
										labelId="model-input"
										label={t("menu.settings")}
										value={modeloID}
										onChange={(e) => {
											setModeloID(e.target.value);
										}}
										renderValue={() => configImport?.name}
										fullWidth
									>
										<MenuItem
											key="null"
											value={null}
											onClick={() => {
												setConfigImport(null);
											}}
										>
											--
										</MenuItem>
										{modelos.map((m, index) => (
											<MenuItem
												key={index}
												value={m?._id}
												onClick={() => {
													setConfigImport(formatConfig(m));
												}}
											>
												{m?.name}
											</MenuItem>
										))}
										<Divider />
										<MenuItem
											key="default"
											value={ModeloDefaultV1?._id}
											onClick={() => {
												setConfigImport(formatConfig(ModeloDefaultV1));
											}}
										>
											<Stack direction="row" justifyContent="space-between">
												<em>{ModeloDefaultV1?.name}</em>
											</Stack>
										</MenuItem>
									</Select>
								</FormControl>
							</Grid2>
							<Grid2 size={1}>
								<Button
									color="dark"
									startIcon={<Icon icon="solar:settings-linear" />}
									onClick={() => {
										handleNavigate();
									}}
									sx={{ whiteSpace: "nowrap" }}
								>
									{t("menu.settings")}
								</Button>
							</Grid2>
						</Grid2>

						<div>
							{format == "csv" && (
								<ImportCSV
									header={configImport?.header}
									{...configImport}
									onUpload={(e) => {
										handleFileCSV(e);
									}}
								/>
							)}

							{format == "xml" && (
								<ImportXML
									{...configImport}
									onUpload={(e) => {
										handleFileXML(e);
									}}
								/>
							)}

							{format == "erp" && (
								<ImportERP
									{...configImport}
									onUpload={(e) => {
										handleFileXML(e);
									}}
								/>
							)}
						</div>

						{importCount ? (
							<Stack direction="row" justifyContent="center">
								<Stack
									direction="row"
									divider={<Divider orientation="vertical" flexItem />}
									spacing={1}
									sx={{ boxShadow: 1, p: 2, borderRadius: 2, border: 1, borderColor: grey[100] }}
								>
									{importCount?.map((item) => (
										<>
											<Grid2 container spacing={1} sx={{ p: 1 }}>
												<Grid2>
													<Icon width="32" icon={item.icon} color={item?.color} />
												</Grid2>
												<Grid2>
													<Typography variant="subtitle">{item.label}:</Typography>
												</Grid2>
												<Grid2>
													<Typography>{item.value}</Typography>
												</Grid2>
											</Grid2>
										</>
									))}
								</Stack>
							</Stack>
						) : (
							""
						)}

						{items ? (
							<DataGrid
								localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
								rows={items}
								columns={columns}
								editMode="row"
								disableRowSelectionOnClick={true}
								sx={{
									border: 0,
								}}
								pageSizeOptions={[10, 50, 100]}
								processRowUpdate={(updatedRow, originalRow) => {
									setItems((prevRows) => {
										const newRows = [...prevRows];
										newRows[originalRow.id] = updatedRow;
										return newRows;
									});
								}}
								getRowHeight={({ id, densityFactor }) => {
									if (id % 2 === 0) {
										return 50 * densityFactor;
									}

									return null;
								}}
								slotProps={{
									columnsManagement: {
										toggleAllMode: "filteredOnly",
									},
								}}
								initialState={{
									pagination: {
										paginationModel: {
											pageSize: 10,
										},
									},
								}}
							/>
						) : (
							""
						)}
					</Stack>
				</Stack>
			</Container>

			{showModal && (
				<CustomModal dividers={false}>
					<Stack spacing={2} sx={{ p: 2 }}>
						<Typography color="error">
							<Icon icon="ph:warning-fill" width="64" />
						</Typography>
						<div>
							<Typography variant="h1">{t("messages.attention")}</Typography>
							<Typography variant="subtitle">
								Não há uma configuração de importação definida. Deseja continuar mesmo assim?
							</Typography>
						</div>
						<Stack direction="row" justifyContent="flex-end" spacing={2}>
							<Button
								variant="outlined"
								color="dark"
								onClick={() => {
									handleNavigate();
								}}
							>
								Configurar
							</Button>
							<Button
								variant="contained"
								color="dark"
								onClick={() => {
									setShowModal(false);
								}}
							>
								Continuar
							</Button>
						</Stack>
					</Stack>
				</CustomModal>
			)}
		</>
	);
}
