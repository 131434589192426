import { Stack } from "@mui/material";

import QrcodeGenerator from "../../../components/QrcodeGenerator";
import { t } from "i18next";
import AlertDialog from "../../../components/AlertDialog";
import { Button } from "react-bootstrap";

//---------------------------------------------------------------

export default function GenerateQr({ idRegistro }) {
	function handleGenerateQR() {
		AlertDialog.fire({
			title: `${t("actions.generate")} QRCode`,
			titleIcon: "ic:round-warning",
			preComponent: (
				<>
					<Stack justifyContent="center" alignItems="center" spacing={3}>
						<QrcodeGenerator value={idRegistro || ""} width="50%" showButton={false} />
					</Stack>
				</>
			),
			onConfirm: () => {},
			onCancel: () => {},
		});
	}
	return (
		<>
			<Button
				variant="light"
				onClick={(e) => {
					handleGenerateQR();
				}}
			>
				QR Code
			</Button>
		</>
	);
}
