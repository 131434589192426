import { useEffect, useState } from "react";

import {
	Button,
	Checkbox,
	Container,
	FormControl,
	FormControlLabel,
	FormLabel,
	Grid2,
	IconButton,
	ListItemIcon,
	ListItemText,
	MenuItem,
	Paper,
	Radio,
	RadioGroup,
	Select,
	Stack,
	TextField,
	Tooltip,
	Typography,
} from "@mui/material";

import { t } from "i18next";

import api from "../../services/api";

import { redirect, useLocation, useNavigate } from "react-router-dom";

import { Icon } from "@iconify/react/dist/iconify.js";

import Loading from "../../components/ui/Loading";

import Alert from "../../components/ui/Alert";

export default function ImportConfig() {
	const navigate = useNavigate();

	const location = useLocation();

	const { modulo = "itens", redirectTo = -1, format = "csv", opcoesCampos = [] } = location?.state || {};

	const [loading, setLoading] = useState(false);

	const [message, setMessage] = useState();

	const [contaConfig, setContaConfig] = useState(); // Lista de configurações de importação

	const [configList, setConfigList] = useState([]); // Lista de configurações de importação

	const [configSelectedID, setConfigSelectedID] = useState(); // Lista de configurações de importação

	// Informações da Configuração
	const [name, setName] = useState("");

	const [standard, setStandard] = useState(false);

	const [header, setHeader] = useState(false);

	const [position, setPosition] = useState("cell");

	const [keyTag, setKeyTag] = useState("");

	const [countField, setCountField] = useState(1);

	const [fields, setFields] = useState([""]); // Campos do Inovaone para onde serão importadas as informações

	const [xmlUploadFields, setXmlUploadFields] = useState([""]); // Informações que serão importados do arquivo

	const [fixedField, setFixedField] = useState([""]); // Textos fixos - os campos do inovaone serão substituidos por textos fixos

	useEffect(() => {
		getConfiguracoes();
	}, []);

	function getConfiguracoes() {
		setLoading(true);
		api
			.get(`/conta_config/${sessionStorage.getItem("idConta")}`)
			.then((response) => {
				const data = response?.data.find((i) => i?._id);
				setContaConfig(data);
				const configs = data?.import?.[modulo];
				setConfigList(configs || []);
			})
			.catch((error) => {
				setMessage({
					type: "error",
					message: t("messages.errors.500"),
					title: t("messages.errors.error"),
				});
			})
			.finally(() => {
				setLoading(false);
			});
	}

	function handleChangeFields(action = () => {}, value, index) {
		action((prevFields) => {
			const newFields = [...prevFields];
			newFields[index] = value;
			return newFields;
		});
	}

	function handleConfigSelect(id_config) {
		setConfigSelectedID(id_config);
		const configDetails = configList.find((config) => config._id === id_config);
		setFields(configDetails?.fields || [""]);
		setXmlUploadFields(configDetails?.xmlUploadFields || [""]);
		setFixedField(configDetails?.fixedField || [""]);
		setKeyTag(configDetails?.keyTag || "");
		setName(configDetails?.name || "");
		const index = configList.findIndex((config) => config._id === id_config);
		setStandard(index === 0);
		setCountField(configDetails?.fields?.length || 1);
		setHeader(configDetails?.header || false);
		setPosition(configDetails?.position || "cell");
	}

	function handleDelete() {
		const updateConfig = Array.isArray(configList) ? configList.filter((i) => i?._id !== configSelectedID) : [];

		save(updateConfig);
	}

	function handleSave() {
		const reqConfig = {
			position,
			keyTag,
			header,
			fields,
			fixedField,
			xmlUploadFields,
			countField,
			name: name || t("settings.model"),
		};

		const updateConfig = Array.isArray(configList) ? [...configList, reqConfig] : [reqConfig];

		save(updateConfig);
	}

	function handleEdit() {
		const reqConfig = {
			_id: configSelectedID,
			position,
			keyTag,
			header,
			fields,
			fixedField,
			xmlUploadFields,
			count: countField,
			name: name || t("settings.model"),
		};

		const updateConfig = Array.isArray(configList) ? [...configList] : [];

		const index = configList.findIndex((item) => item._id === configSelectedID);

		updateConfig[index] = reqConfig;

		save(updateConfig);
	}

	function handlePin() {
		const reqConfig = {
			position,
			keyTag,
			header,
			fields,
			fixedField,
			xmlUploadFields,
			countField,
			name: name || t("settings.model"),
		};

		const updateConfig = configList.filter((i) => i?._id !== configSelectedID);

		updateConfig.unshift(reqConfig);

		setStandard(true);

		save(updateConfig);
	}

	function save(upcommingConfig) {
		setLoading(true);
		const reg = {
			_id: contaConfig?._id,
			id_conta: sessionStorage.getItem("idConta"),
			import: {
				...contaConfig?.import,
				[modulo]: upcommingConfig,
			},
		};
		api
			.post(`/conta_config`, [reg])
			.then((response) => {
				setMessage({
					type: "success",
					message: t("messages.success_save"),
					title: t("messages.success"),
				});
			})
			.catch((err) => {
				setMessage({
					type: "error",
					message: t("messages.errors.500"),
					title: t("messages.errors.error"),
				});
			})
			.finally(() => {
				getConfiguracoes();
				setLoading(false);
			});
	}

	return (
		<>
			<Loading show={loading} />
			{message ? (
				<Alert
					type={message.type}
					title={message.title}
					message={message.message}
					onClose={(e) => {
						setMessage(e);
					}}
				/>
			) : (
				""
			)}
			<Container>
				<Stack spacing={3}>
					<Stack direction="row" justifyContent="space-between">
						<Typography variant="h1">{t("actions.import")}</Typography>
						<Button
							color="dark"
							onClick={() => {
								navigate(redirectTo);
							}}
						>
							{t("actions.exit")}
						</Button>
					</Stack>
					<Stack as={Paper} spacing={3} sx={{ borderRadius: 3, p: 3 }}>
						<Stack direction="row" justifyContent="end" spacing={1}>
							{configSelectedID ? (
								<>
									<Tooltip title={`${t("actions.delete")} ${t("settings.model")}`}>
										<IconButton
											onClick={() => {
												handleDelete();
											}}
										>
											<Icon icon="mage:trash" />
										</IconButton>
									</Tooltip>
									<Tooltip title={`Tornar configuração padrão`}>
										<IconButton
											color={standard ? "warning" : "dark"}
											onClick={() => {
												handlePin();
											}}
										>
											<Icon icon={standard ? "solar:star-bold" : "solar:star-linear"} />
										</IconButton>
									</Tooltip>
									<Button
										variant="contained"
										color="dark"
										startIcon={<Icon icon="lets-icons:edit-light" />}
										onClick={() => {
											handleEdit();
										}}
									>
										{t("actions.edit")}
									</Button>
								</>
							) : (
								<Button
									variant="contained"
									color="dark"
									startIcon={<Icon icon="mage:plus" />}
									onClick={() => {
										handleSave();
									}}
								>
									{t("actions.save")}
								</Button>
							)}
						</Stack>
						<FormControl size="small" fullWidth>
							<FormLabel>{t("settings.model")}</FormLabel>
							<Select
								value={configSelectedID}
								onChange={(e) => {
									handleConfigSelect(e.target.value);
								}}
								fullWidth
							>
								<MenuItem key="null" value={null}>
									--
								</MenuItem>
								{configList.map((config, index) => (
									<MenuItem key={index} value={config?._id}>
										{config?.name}
									</MenuItem>
								))}
							</Select>
						</FormControl>

						<FormControl size="small" fullWidth>
							<FormLabel>{t("common.name")}</FormLabel>
							<TextField
								size="small"
								value={name}
								onChange={(e) => {
									setName(e.target.value);
								}}
							/>
						</FormControl>

						{format === "csv" && (
							<FormControl>
								<FormLabel>{t("import.has_header")}</FormLabel>
								<RadioGroup
									row
									aria-labelledby="demo-row-radio-buttons-group-label"
									name="row-radio-buttons-group"
									value={header}
									onChange={(e) => {
										setHeader(e.target.value);
									}}
								>
									<FormControlLabel value={true} control={<Radio color="dark" />} label={t("common.yes")} />
									<FormControlLabel value={false} control={<Radio color="dark" />} label={t("common.no")} />
								</RadioGroup>
							</FormControl>
						)}

						{format === "xml" && (
							<>
								<FormControl>
									<FormLabel>{t("import.info_position")}</FormLabel>
									<RadioGroup
										row
										aria-labelledby="demo-row-radio-buttons-group-label"
										name="row-radio-buttons-group"
										value={position}
										onChange={(e) => {
											setPosition(e.target.value);
										}}
									>
										<FormControlLabel value="cell" control={<Radio />} label={t("import.cell")} />
										<FormControlLabel value="row" control={<Radio />} label={t("import.row")} />
									</RadioGroup>
								</FormControl>
								<FormControl>
									<FormLabel>Tag ({position === "row" ? t("import.row") : t("import.cell")})</FormLabel>
									<TextField
										size="small"
										value={keyTag}
										onChange={(e) => {
											setKeyTag(e.target.value);
										}}
										sx={{ minWidth: 300 }}
									/>
								</FormControl>
							</>
						)}

						{[...Array(Math.min(countField, opcoesCampos.length)).keys()].map((field, i) => (
							<Grid2 container alignItems="center" spacing={3}>
								<Grid2 item size={5}>
									<FormControl fullWidth>
										{!i && <FormLabel>{t("import.destination_data")}</FormLabel>}
										<Select
											fullWidth
											size="small"
											value={fields[i]}
											onChange={(e) => {
												handleChangeFields(setFields, e.target.value, i);
											}}
											sx={{ minWidth: 300 }}
										>
											<MenuItem key="0" value="">
												--
											</MenuItem>
											{opcoesCampos.map((option) => (
												<MenuItem
													key={option.value}
													value={option.value}
													disabled={fields.find((item) => item == option.value)}
												>
													{option.label}
												</MenuItem>
											))}
										</Select>
									</FormControl>
								</Grid2>
								<Grid2 item size="auto" sx={{ color: "primary.main" }}>
									<Icon icon="radix-icons:pin-left" />
								</Grid2>
								<Grid2 item size={5}>
									<FormControl fullWidth>
										{!i && <FormLabel>{t("import.imports_data")}</FormLabel>}
										<TextField
											fullWidth
											size="small"
											value={xmlUploadFields[i]}
											onChange={(e) => {
												handleChangeFields(setXmlUploadFields, e.target.value, i);
											}}
											sx={{ minWidth: 300 }}
										/>
									</FormControl>
								</Grid2>
								<Grid2 item size="auto">
									<FormControl style={{ display: "flex", alignItems: "start" }}>
										{!i && <FormLabel>{t("import.text_fixed")}</FormLabel>}
										<Checkbox
											checked={fixedField[i]}
											onChange={(e) => {
												const updatedFixedField = [...fixedField];
												updatedFixedField[i] = e.target.checked;
												setFixedField(updatedFixedField);
											}}
											inputProps={{ "aria-label": "controlled" }}
										/>
									</FormControl>
								</Grid2>
							</Grid2>
						))}
						<Button
							disabled={countField >= opcoesCampos.length ? true : false}
							fullWidth
							variant="contained"
							onClick={(e) => {
								setCountField(countField + 1);
							}}
							startIcon={<Icon icon="mage:plus" />}
						>
							{t("import.field")}
						</Button>
					</Stack>
				</Stack>
			</Container>
		</>
	);
}
