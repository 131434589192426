import { useEffect, useState } from "react";

import { t } from "i18next";

import secureLocalStorage from "react-secure-storage";

import api from "../../../services/api";

import Alert from "../../../components/ui/Alert";

import CustomModal from "../../../components/ui/CustomModal";

import {
	Backdrop,
	Button,
	CircularProgress,
	FormControlLabel,
	FormGroup,
	FormLabel,
	Grid,
	IconButton,
	InputAdornment,
	MenuItem,
	Select,
	Stack,
	Switch,
	TextField,
	Typography,
} from "@mui/material";

import { Icon } from "@iconify/react/dist/iconify.js";

//----------------------------------------------------------------

export default function UsersDetails({ userData = {}, onExit, onSave }) {
	const [loading, setLoading] = useState(false);

	const [showModal, setModal] = useState(false);

	const [message, setMessage] = useState();

	const [_id, setId] = useState(userData?._id || "0");

	const [name, setName] = useState(userData?.nome);

	const [active, setActive] = useState(userData?.ativo || 1);

	const [perfil, setPerfil] = useState(userData?.perfil || "usu");

	const [login, setLogin] = useState(userData?.login);

	const [password, setPassword] = useState(userData?.senha || "");

	const [passwordError, setPasswordError] = useState("");

	const [showPassword, setShowPassword] = useState(false);

	const [tag, setTag] = useState(userData?.tag);

	const [printerAddress, setPrinterAddress] = useState(userData?.endereco_impressora);

	const [nivel1, setNivel1] = useState(
		userData?.niveis_acesso?.length ? userData?.niveis_acesso[0]?.niveis1[0]?.id_nivel_loc1 : ""
	);

	const [nivel2, setNivel2] = useState(
		userData?.niveis_acesso?.length ? userData?.niveis_acesso[0]?.niveis2[0]?.id_nivel_loc2 : ""
	);

	const [nivel3, setNivel3] = useState(
		userData?.niveis_acesso?.length ? userData?.niveis_acesso[0]?.niveis3[0]?.id_nivel_loc3 : ""
	);

	const [nivel4, setNivel4] = useState(
		userData?.niveis_acesso?.length ? userData?.niveis_acesso[0]?.niveis4[0]?.id_nivel_loc4 : ""
	);

	const [restrictCreate, setRestrictCreate] = useState(
		userData?.perfil_personalizavel?.length ? userData?.perfil_personalizavel[0]?.permissoes[0]?.itens?.criar : true
	);

	const [restrictView, setRestrictView] = useState(
		userData?.perfil_personalizavel?.length
			? userData?.perfil_personalizavel[0]?.permissoes[0]?.itens?.visualizar
			: true
	);

	const [restrictEdit, setRestrictEdit] = useState(
		userData?.perfil_personalizavel?.length ? userData?.perfil_personalizavel[0]?.permissoes[0]?.itens?.editar : true
	);

	const [restrictDelete, setRestrictDelete] = useState(
		userData?.perfil_personalizavel?.length
			? userData?.perfil_personalizavel[0]?.permissoes[0]?.itens?.excluir
			: userData?.perfil?.includes("usu")
				? false
				: true
	);

	const [canCreateItem, setCanCreateItem] = useState(
		userData?.perfil_personalizavel?.length && userData?.perfil_personalizavel[0]?.permissoes[1]
			? userData?.perfil_personalizavel[0]?.permissoes[1]?.itens?.criar
			: true
	);

	const [canViewItem, setCanViewItem] = useState(
		userData?.perfil_personalizavel?.length && userData?.perfil_personalizavel[0]?.permissoes[1]
			? userData?.perfil_personalizavel[0]?.permissoes[1]?.itens?.visualizar
			: true
	);

	const [canEditItem, setCanEditItem] = useState(
		userData?.perfil_personalizavel?.length && userData?.perfil_personalizavel[0]?.permissoes[1]
			? userData?.perfil_personalizavel[0]?.permissoes[1]?.itens?.editar
			: true
	);

	const [canDeleteItem, setCanDeleteItem] = useState(
		userData?.perfil_personalizavel?.length && userData?.perfil_personalizavel[0]?.permissoes[1]
			? userData?.perfil_personalizavel[0]?.permissoes[1]?.itens?.excluir
			: userData?.perfil?.includes("usu")
				? false
				: true
	);

	const [nivel1List, setNivel1List] = useState([]);

	const [nivel2List, setNivel2List] = useState([]);

	const [nivel3List, setNivel3List] = useState([]);

	const [nivel4List, setNivel4List] = useState([]);

	useEffect(() => {
		getNivel1();
		if (nivel2) {
			getNivel2();
			if (nivel3) {
				getNivel3();
				if (nivel4) {
					getNivel4();
				}
			}
		}
	}, []);

	useEffect(() => {
		const error = verifySenha();
		if (error) {
			setPasswordError(t(`login.messages.sign_up.errors.password.${error}`));
		} else {
			setPasswordError("");
		}
	}, [password]);

	function verifySenha() {
		let error;

		// Definindo as regras de validação da senha
		const minLength = 6;
		const hasUpperCase = /[A-Z]/.test(password);
		const hasLowerCase = /[a-z]/.test(password);
		const hasNumber = /\d/.test(password);
		const hasSpecialChar = /[?:*\/]/.test(password);
		const isCommonPassword = !["senha", "123456", "abcdef"].includes(password?.toLowerCase());

		if (password.length < minLength) {
			error = "minLength";
			return error;
		}

		if (!hasUpperCase || !hasLowerCase || !hasNumber) {
			error = "rules";
			return error;
		}

		if (hasSpecialChar) {
			error = "hasSpecialChar";
			return error;
		}

		if (!isCommonPassword) {
			error = "isCommonPassword";
			return error;
		}
		return error;
	}

	function handleSelectNivel1(e) {
		setNivel1(e.target.value);
		setNivel2();
		setNivel3();
		setNivel4();
		getNivel2(e.target.value);
	}

	function handleSelectNivel2(e) {
		setNivel2(e.target.value);
		setNivel3();
		setNivel4();
		getNivel3(e.target.value);
	}

	function handleSelectNivel3(e) {
		setNivel3(e.target.value);
		setNivel4();
		getNivel4(e.target.value);
	}

	function getNivel1() {
		api.get(`/nivel_loc1/${sessionStorage.getItem("idConta")}/*/*/*/*`, {}).then((response) => {
			setNivel1List(response.data);
		});
	}

	function getNivel2(id_nivelLoc1) {
		api
			.get(
				`/nivel_loc2/${sessionStorage.getItem("idConta")}/${id_nivelLoc1 && id_nivelLoc1 != undefined ? id_nivelLoc1 : "*"}/*/*/*/*`,
				{}
			)
			.then((response) => {
				setNivel2List(response.data);
			});
	}

	function getNivel3(id_nivelLoc2) {
		api
			.get(
				`/nivel_loc3/${sessionStorage.getItem("idConta")}/${id_nivelLoc2 && id_nivelLoc2 != undefined ? id_nivelLoc2 : "*"}/*/*/*/*`,
				{}
			)
			.then((response) => {
				setNivel3List(response.data);
			});
	}

	function getNivel4(id_nivelLoc3) {
		api
			.get(
				`/nivel_loc4/${sessionStorage.getItem("idConta")}/${id_nivelLoc3 && id_nivelLoc3 != undefined ? id_nivelLoc3 : "*"}/*/*/*/*`,
				{}
			)
			.then((response) => {
				setNivel4List(response.data);
			});
	}

	function handleSave() {
		setLoading(true);
		if (passwordError) {
			setMessage({
				type: "error",
				message: passwordError,
				title: t("messages.errors.error"),
			});
			setLoading(false);
			return;
		}
		setModal(false);
		userData.id_usuario = _id;
		userData.id_conta = sessionStorage.getItem("idConta");
		userData.dt_acesso = new Date()
			.toLocaleString("pt-BR", {
				year: "numeric",
				month: "2-digit",
				day: "2-digit",
				hour: "2-digit",
				minute: "2-digit",
				second: "2-digit",
				hour12: false,
			})
			.replace(",", "");
		userData.nome = name;
		userData.login = login;
		userData.ativo = active;
		userData.perfil = perfil;
		userData.senha = password;
		userData.tag = tag;
		userData.endereco_impressora = printerAddress;
		userData.niveis_acesso = [
			{
				niveis1: [
					{
						id_nivel_loc1: nivel1,
					},
				],
				niveis2: [
					{
						id_nivel_loc2: nivel2,
					},
				],
				niveis3: [
					{
						id_nivel_loc3: nivel3,
					},
				],
				niveis4: [
					{
						id_nivel_loc4: nivel4,
					},
				],
			},
		];
		userData.perfil_personalizavel = [
			{
				permissoes: [
					{
						itens: {
							criar: restrictCreate,
							editar: restrictEdit,
							excluir: restrictDelete,
							visualizar: restrictView,
						},
					},
				],
			},
		];

		if (!canCreateItem || !canViewItem || !canEditItem || !canDeleteItem) {
			userData.perfil_personalizavel[0].permissoes[1] = {
				itens: {
					criar: canCreateItem,
					editar: canEditItem,
					excluir: canDeleteItem,
					visualizar: canViewItem,
				},
			};
			if (!canCreateItem || !canViewItem || !canEditItem || !canDeleteItem) {
				userData.perfil_personalizavel[0].permissoes[1] = {
					itens: {
						criar: canCreateItem,
						editar: canEditItem,
						excluir: canDeleteItem,
						visualizar: canViewItem,
					},
				};
				if (!canCreateItem) {
					sessionStorage.setItem("cantCreate", true);
				}
				if (!canEditItem) {
					sessionStorage.setItem("cantEdit", true);
				}
				if (!canDeleteItem) {
					sessionStorage.setItem("cantDelete", true);
				}
			}
		}

		let option = {
			headers: { "Content-Type": ["application/json"] },
		};

		api.post("/usuario", [userData], option).then(
			(response) => {
				setLoading(false);
				setMessage({
					type: "success",
					message: t("messages.success_save"),
					title: t("messages.success"),
				});
				onSave();
			},
			(error) => {
				setLoading(false);
				setMessage({
					type: "error",
					message: t("messages.errors.500"),
					title: t("messages.errors.error"),
				});
			}
		);
	}

	function handleDelete() {
		let option = {
			headers: { "Content-Type": ["application/json"] },
		};

		api.delete(`/usuario/${_id}`, option).then(
			(response) => {
				setLoading(false);
				setMessage({
					type: "success",
					message: t("messages.success_save"),
					title: t("messages.success"),
				});
				onSave();
			},
			(error) => {
				setLoading(false);
				setMessage({
					type: "error",
					message: t("messages.errors.500"),
					title: t("messages.errors.error"),
				});
			}
		);
	}

	return (
		<>
			<Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading}>
				<CircularProgress color="inherit" />
			</Backdrop>

			<Stack direction="row" justifyContent="space-between">
				<div>
					<Typography variant="h2">{t("settings.config_users")}</Typography>
					<Typography variant="subtitle">{t("settings.config_users_subtitle")}</Typography>
				</div>
				<Stack direction="row" justifyContent="end" spacing={1}>
					<Button
						size="small"
						variant="outlined"
						color="dark"
						startIcon={<Icon icon="solar:trash-bin-minimalistic-linear" />}
						onClick={(e) => {
							handleDelete(true);
						}}
					>
						{t("actions.delete")}
					</Button>
					<Button
						size="small"
						variant="outlined"
						color="dark"
						startIcon={<Icon icon="mingcute:arrow-left-fill" />}
						onClick={(e) => {
							onExit(true);
						}}
					>
						{t("actions.exit")}
					</Button>
				</Stack>
			</Stack>

			<Grid container spacing={3} xs={12} md={8} sx={{ my: 3 }}>
				<Grid item xs={12} md={6}>
					<FormGroup>
						<FormLabel>{t("settings.data.name")}</FormLabel>
						<TextField
							size="small"
							value={name}
							onChange={(e) => {
								setName(e.target.value);
							}}
							fullWidth
						/>
					</FormGroup>
				</Grid>
				<Grid item xs={12} md={6}>
					<FormGroup>
						<FormLabel>{t("common.active")}</FormLabel>
						<Select
							size="small"
							value={active}
							onChange={(e) => {
								setActive(e.target.value);
							}}
							fullWidth
						>
							<MenuItem value={1}>{t("common.yes")}</MenuItem>
							<MenuItem value={0}>{t("common.no")}</MenuItem>
						</Select>
					</FormGroup>
				</Grid>
				<Grid item xs={12} md={6}>
					<FormGroup>
						<FormLabel>{t("settings.profile")}</FormLabel>
						<Select
							size="small"
							value={perfil}
							onChange={(e) => {
								setPerfil(e.target.value);
							}}
							fullWidth
						>
							<MenuItem value="admin">
								Administrador {sessionStorage.getItem("idConta") === "c68AeC-CZ" ? "Master" : ""}
							</MenuItem>
							{sessionStorage.getItem("idConta") === "c68AeC-CZ" ? (
								<MenuItem value="restricted-admin">Administrador</MenuItem>
							) : (
								""
							)}
							<MenuItem value="usu">Usuário</MenuItem>
							<MenuItem value="ivc">Inventário Cego</MenuItem>
							<MenuItem value="consumidor">Consumidor</MenuItem>
						</Select>
					</FormGroup>
				</Grid>
				<Grid item xs={12} md={6}>
					<FormGroup>
						<FormLabel>Login</FormLabel>
						<TextField
							size="small"
							value={login}
							onChange={(e) => {
								setLogin(e.target.value);
							}}
							fullWidth
						/>
					</FormGroup>
				</Grid>
				<Grid item xs={12} md={6}>
					<FormGroup>
						<FormLabel>{t("login.placeholder.password")}</FormLabel>
						<TextField
							size="small"
							type={showPassword ? "text" : "password"}
							value={password}
							onChange={(e) => {
								setPassword(e.target.value);
							}}
							fullWidth
							error={!!passwordError || false}
							helperText={passwordError || ""}
							slotProps={{
								input: {
									endAdornment: (
										<InputAdornment position="end">
											<IconButton
												onClick={() => {
													setShowPassword(!showPassword);
												}}
											>
												<Icon icon={`fluent:eye-${showPassword ? "off-" : ""}16-filled`} />
											</IconButton>
										</InputAdornment>
									),
								},
							}}
						/>
					</FormGroup>
				</Grid>
				<Grid item xs={12} md={6}>
					<FormGroup>
						<FormLabel>Tag</FormLabel>
						<TextField
							size="small"
							value={tag}
							onChange={(e) => {
								setTag(e.target.value);
							}}
							fullWidth
						/>
					</FormGroup>
				</Grid>
				<Grid item xs={12} md={12}>
					<FormGroup>
						<FormLabel>{t("printadress.printadress")}</FormLabel>
						<TextField
							size="small"
							value={printerAddress}
							onChange={(e) => {
								setPrinterAddress(e.target.value);
							}}
							fullWidth
						/>
					</FormGroup>
				</Grid>
			</Grid>

			<Typography variant="h3">{t("common.permissions")}</Typography>
			<Typography variant="subtitle">{t("settings.config_permissions_subtitle")}</Typography>

			<Grid container sx={{ my: 1 }} spacing={2}>
				<Grid item xs={4}>
					<Typography>{t("items.create")}</Typography>
				</Grid>
				<Grid item xs={7}>
					<Switch
						checked={canCreateItem}
						onClick={(e) => {
							setCanCreateItem(!canCreateItem);
						}}
					/>
				</Grid>
				<Grid item xs={4}>
					<Typography>{t("items.manage_itens")}</Typography>
				</Grid>
				<Grid item xs={7}>
					<Switch
						checked={canEditItem}
						onClick={(e) => {
							setCanEditItem(!canEditItem);
						}}
					/>
				</Grid>
				<Grid item xs={4}>
					<Typography>{t("items.delete_itens")}</Typography>
				</Grid>
				<Grid item xs={7}>
					<Switch
						checked={canDeleteItem}
						onClick={(e) => {
							setCanDeleteItem(!canDeleteItem);
						}}
						disabled={perfil.includes("admin") ? false : true}
					/>
				</Grid>
			</Grid>

			<Typography variant="h3" sx={{ mt: 3 }}>
				{t("common.level")}
			</Typography>

			<Typography variant="subtitle">{t("settings.locale_subtitle")}</Typography>

			<Grid container sx={{ my: 1 }} spacing={3} xs={12} md={8}>
				<Grid item xs={12} md={6}>
					<FormGroup>
						<FormLabel>{t("common.level")} 1</FormLabel>
						<Select
							size="small"
							value={nivel1}
							onChange={(e) => {
								handleSelectNivel1(e);
							}}
							fullWidth
						>
							<MenuItem value="" sx={{ p: 2 }}></MenuItem>
							{nivel1List.map((nivel_1) => (
								<MenuItem key={nivel_1._id} value={nivel_1._id}>
									{nivel_1.descricao}
								</MenuItem>
							))}
						</Select>
					</FormGroup>
				</Grid>
				<Grid item xs={12} md={6}>
					<FormGroup>
						<FormLabel>{t("common.level")} 2</FormLabel>
						<Select
							size="small"
							value={nivel2}
							onChange={(e) => {
								handleSelectNivel2(e);
							}}
							fullWidth
						>
							<MenuItem value="" sx={{ p: 2 }}></MenuItem>
							{nivel2List.map((nivel_2) => (
								<MenuItem key={nivel_2._id} value={nivel_2._id}>
									{nivel_2.descricao}
								</MenuItem>
							))}
						</Select>
					</FormGroup>
				</Grid>
				<Grid item xs={12} md={6}>
					<FormGroup>
						<FormLabel>{t("common.level")} 3</FormLabel>
						<Select
							size="small"
							value={nivel3}
							onChange={(e) => {
								handleSelectNivel3(e);
							}}
							fullWidth
						>
							<MenuItem value="" sx={{ p: 2 }}></MenuItem>
							{nivel3List.map((nivel_3) => (
								<MenuItem key={nivel_3._id} value={nivel_3._id}>
									{nivel_3.descricao}
								</MenuItem>
							))}
						</Select>
					</FormGroup>
				</Grid>
				<Grid item xs={12} md={6}>
					<FormGroup>
						<FormLabel>{t("common.level")} 4</FormLabel>
						<Select
							size="small"
							value={nivel4}
							onChange={(e) => {
								setNivel4(e.target.value);
							}}
							fullWidth
						>
							<MenuItem value="" sx={{ p: 2 }}></MenuItem>
							{nivel4List.map((nivel_4) => (
								<MenuItem key={nivel_4._id} value={nivel_4._id}>
									{nivel_4.descricao}
								</MenuItem>
							))}
						</Select>
					</FormGroup>
				</Grid>
				<Grid item xs={12}>
					{nivel1 && (
						<>
							<Stack direction="row" justifyContent="space-between">
								<div>
									<FormControlLabel
										label={`${t("actions.restrict")} ${t("items.create")}`}
										control={
											<Switch
												checked={restrictCreate}
												onClick={(e) => {
													setRestrictCreate(!restrictCreate);
												}}
											/>
										}
									/>
									<FormControlLabel
										label={`${t("actions.restrict")} ${t("items.delete_itens")}`}
										control={
											<Switch
												checked={restrictDelete}
												onClick={(e) => {
													setRestrictDelete(!restrictDelete);
												}}
												disabled={perfil.includes("admin") ? false : true}
											/>
										}
									/>
								</div>
								<div>
									<FormControlLabel
										label={`${t("actions.restrict")} ${t("items.manage_itens")}`}
										control={
											<Switch
												checked={restrictEdit}
												onClick={(e) => {
													setRestrictEdit(!restrictEdit);
												}}
											/>
										}
									/>
									<FormControlLabel
										label={`${t("actions.restrict")} ${t("items.view_itens")}`}
										control={
											<Switch
												checked={restrictView}
												onClick={(e) => {
													setRestrictView(!restrictView);
												}}
											/>
										}
									/>
								</div>
							</Stack>
						</>
					)}
				</Grid>
				<Grid item xs={12} md={6}>
					<Button
						variant="contained"
						color="error"
						onClick={(e) => {
							setModal(!showModal);
						}}
					>
						{t("actions.save")}
					</Button>
				</Grid>
			</Grid>

			{showModal ? (
				<CustomModal
					title={t("messages.attention")}
					onConfirm={(e) => {
						handleSave();
					}}
					onClose={(e) => {
						setModal(false);
					}}
				>
					<Typography variant="subtitle" paragraph>
						{t("settings.messages.confirm_save")}
					</Typography>
					<Typography variant="subtitle">{t("messages.confirm.ask_confirm")}</Typography>
				</CustomModal>
			) : (
				""
			)}

			{message ? (
				<Alert
					type={message.type}
					title={message.title}
					message={message.message}
					onClose={(e) => {
						setMessage(e);
					}}
				/>
			) : (
				""
			)}
		</>
	);
}
