import { useEffect, useState } from "react";

import { t } from "i18next";

import Loading from "../../../components/ui/Loading.js";

import api from "../../../services/api.js";

import { Box, Button, Chip, Divider, IconButton, Modal, Paper, Stack, styled, Typography } from "@mui/material";

import { Icon } from "@iconify/react/dist/iconify.js";

import {
	DataGrid,
	GridToolbar,
	GridToolbarContainer,
	GridToolbarExport,
	GridToolbarFilterButton,
} from "@mui/x-data-grid";

import { ptBR } from "@mui/x-data-grid/locales";

import { useLocation, useNavigate } from "react-router-dom";

import Alert from "../../../components/ui/Alert.js";

import ImportCSV from "../../../components/Import/ImportCSV.js";

import { green, grey, red, yellow } from "@mui/material/colors";

import CustomModal from "../../../components/ui/CustomModal.js";

// ----------------------------------------------------------

const getStatusChip = (status) => (
	<Chip
		label={status}
		sx={{
			borderRadius: 1,
			fontWeight: 600,
			backgroundColor: status.includes("Erro") ? red[100] : status === "Correto" ? green[50] : "",
			color: status.includes("Erro") ? red[600] : status === "Correto" ? green[600] : grey[600],
		}}
	/>
);

const columns = [
	{
		field: "id",
		headerName: "ID",
		width: 90,
	},
	{
		headerName: "Status (1)",
		field: "nivel_loc_1_status",
		renderCell: (params) => (params?.row?.nivel_loc_1_status ? getStatusChip(params.row.nivel_loc_1_status) : ""),
	},
	{ headerName: "Descrição (1)", field: "nivel_loc_1" },
	{ headerName: "Ativo (1)", field: "nivel_loc_1_ativo" },
	{ headerName: "Tag (1)", field: "nivel_loc_1_tag" },
	{ headerName: "Cor (1)", field: "nivel_loc_1_cor" },

	{
		headerName: "Status (2)",
		field: "nivel_loc_2_status",
		renderCell: (params) => (params?.row?.nivel_loc_2_status ? getStatusChip(params.row.nivel_loc_2_status) : ""),
	},
	{ headerName: "Descrição (2)", field: "nivel_loc_2" },
	{ headerName: "Ativo (2)", field: "nivel_loc_2_ativo" },
	{ headerName: "Tag (2)", field: "nivel_loc_2_tag" },
	{ headerName: "Cor (2)", field: "nivel_loc_2_cor" },

	{
		headerName: "Status (3)",
		field: "nivel_loc_3_status",
		renderCell: (params) => (params?.row?.nivel_loc_3_status ? getStatusChip(params.row.nivel_loc_3_status) : ""),
	},
	{ headerName: "Descrição (3)", field: "nivel_loc_3" },
	{ headerName: "Ativo (3)", field: "nivel_loc_3_ativo" },
	{ headerName: "Tag (3)", field: "nivel_loc_3_tag" },
	{ headerName: "Cor (3)", field: "nivel_loc_3_cor" },

	{
		headerName: "Status (4)",
		field: "nivel_loc_4_status",
		renderCell: (params) => (params?.row?.nivel_loc_4_status ? getStatusChip(params.row.nivel_loc_4_status) : ""),
	},
	{ headerName: "Descrição (4)", field: "nivel_loc_4" },
	{ headerName: "Ativo (4)", field: "nivel_loc_4_ativo" },
	{ headerName: "Tag (4)", field: "nivel_loc_4_tag" },
	{ headerName: "Cor (4)", field: "nivel_loc_4_cor" },
];

const columnsGroups = [
	{
		groupId: "Nivel 1",
		children: [
			{ field: "nivel_loc_1" },
			{ field: "nivel_loc_1_status" },
			{ field: "nivel_loc_1_ativo" },
			{ field: "nivel_loc_1_tag" },
			{ field: "nivel_loc_1_cor" },
		],
	},
	{
		groupId: "Nivel 2",
		children: [
			{ field: "nivel_loc_2" },
			{ field: "nivel_loc_2_status" },
			{ field: "nivel_loc_2_ativo" },
			{ field: "nivel_loc_2_tag" },
			{ field: "nivel_loc_2_cor" },
		],
	},
	{
		groupId: "Nivel 3",
		children: [
			{ field: "nivel_loc_3" },
			{ field: "nivel_loc_3_status" },
			{ field: "nivel_loc_3_ativo" },
			{ field: "nivel_loc_3_tag" },
			{ field: "nivel_loc_3_cor" },
		],
	},
	{
		groupId: "Nivel 4",
		children: [
			{ field: "nivel_loc_4" },
			{ field: "nivel_loc_4_status" },
			{ field: "nivel_loc_4_ativo" },
			{ field: "nivel_loc_4_tag" },
			{ field: "nivel_loc_4_cor" },
		],
	},
];

const keys = [
	"nivel_loc_1",
	"nivel_loc_1_ativo",
	"nivel_loc_1_tag",
	"nivel_loc_1_cor",
	"nivel_loc_2",
	"nivel_loc_2_ativo",
	"nivel_loc_2_tag",
	"nivel_loc_2_cor",
	"nivel_loc_3",
	"nivel_loc_3_ativo",
	"nivel_loc_3_tag",
	"nivel_loc_3_cor",
	"nivel_loc_4",
	"nivel_loc_4_ativo",
	"nivel_loc_4_tag",
	"nivel_loc_4_cor",
];

export default function ImportItensCSV() {
	const navigate = useNavigate();

	const location = useLocation();

	const id_conta = sessionStorage.getItem("idConta");

	const [loading, setLoading] = useState(false);

	const [message, setMessage] = useState(); // Armazena mensagens e erros

	const [configImport, setConfigImport] = useState(location?.state?.config || null); // Configuração do import

	// Lista de niveis na base
	const [nivel1, setNivel1] = useState([]);

	const [nivel2, setNivel2] = useState([]);

	const [nivel3, setNivel3] = useState([]);

	const [nivel4, setNivel4] = useState([]);

	const [marcas, setMarcas] = useState([]);

	const [items, setItems] = useState([]); // Itens que foram importados

	const [upComming, setUpComming] = useState(); // Itens que serão importados

	const [importCount, setImportCount] = useState(); // Contagem de itens por status

	const [fileHeader, setFileHeader] = useState();

	const [fileData, setFileData] = useState([]);

	const [showModal, setShowModal] = useState(false);

	useEffect(() => {
		if (location?.state?.preLoad) {
			handleUpload(location?.state?.preLoad);
		}
	}, []);

	useEffect(() => {
		async function getLocates() {
			await api
				.get(`/nivel_loc1/${sessionStorage.getItem("idConta")}/*/*/*/*`, {})
				.then((response) => {
					setNivel1(response.data.filter((item) => item._id));
				})
				.finally(() => {});
			await api
				.get(`/nivel_loc2/${sessionStorage.getItem("idConta")}/*/*/*/*/*`, {})
				.then((response) => {
					setNivel2(response.data.filter((item) => item._id));
				})
				.finally(() => {});
			await api
				.get(`/nivel_loc3/${sessionStorage.getItem("idConta")}/*/*/*/*/*`, {})
				.then((response) => {
					setNivel3(response.data.filter((item) => item._id));
				})
				.finally(() => {});
			await api
				.get(`/nivel_loc4/${sessionStorage.getItem("idConta")}/*/*/*/*/*`, {})
				.then((response) => {
					setNivel4(response.data.filter((item) => item._id));
				})
				.finally(() => {});
		}

		getLocates();
	}, []);

	function getID() {
		return "xxxxxxxx-yxxx".replace(/[xy]/g, function (c) {
			var r = (Math.random() * 16) | 0,
				v = c === "x" ? r : (r & 0x3) | 0x8;
			return v.toString(16);
		});
	}

	function getStatus(descricao, ativo, level, preNivel, registeredList) {
		if (!descricao) {
			return "Erro: Descrição Ausente";
		}

		if (ativo) {
			const ativoTest = !isNaN(ativo) && ativo >= 1 && ativo <= 4;
			if (!ativoTest) {
				return "Erro: Campo Ativo inválido. Use um número entre 1 e 4.";
			}
		}

		if (!preNivel) {
			return `Erro: Nivel ${level - 1} ausente.`;
		}

		return "Correto";
	}

	useEffect(() => {
		if (fileData.length && !configImport) {
			setShowModal(true);
		} else if (fileData.length) {
			handleUpload(fileData);
		}
	}, [fileData]);

	function handleFile(e) {
		const { header, data } = e;

		setFileHeader(header);

		setFileData(data);
	}

	function handleUpload(file) {
		if (configImport) {
			const { fields, xmlUploadFields } = configImport;
			const rows = file.map((item) => {
				const row = {};
				fields.forEach((field, index) => {
					row[field] = item[xmlUploadFields[index]];
				});
				return row;
			});
			handleUploadFile(rows);
		} else {
			const rows = file.map((item) => {
				const row = {};
				keys.forEach((key, index) => {
					row[key] = Object.values(item)[index];
				});
				return row;
			});

			handleUploadFile(rows);
		}
	}

	async function handleSave() {
		let option = { headers: { "Content-Type": ["application/json"] } };

		await api.post(`/itensDef/${sessionStorage.getItem("idConta")}`, [upComming], option).then(
			(response) => {
				setMessage({
					type: "success",
					title: t("messages.success"),
					message: t("settings.clean_base.success_message"),
				});
			},
			(error) => {
				setMessage({
					type: "error",
					title: t("messages.errors.error"),
					message: t("messages.errors.500"),
				});
			}
		);
	}

	function handleNavigate() {
		if (fileHeader) {
			navigate("/locations/import/config", { state: { xmlfields: fileHeader, preLoad: fileData } });
		} else {
			navigate("/locations/import/config", { state: { config: configImport, preLoad: fileData } });
		}
	}

	function handleTemplate() {
		const filteredColumns = columns.filter((item) => !item.headerName.includes("Status"));
		const data = filteredColumns.slice(1).map((item) => item.headerName); // Obter apenas os campos sem o ponto e vírgula
		const header = data.join(";"); // Concatena com ponto e vírgula, sem adicionar vírgulas
		const csvContent = `${header}\n`; // Adiciona quebra de linha após o cabeçalho

		const blob = new Blob(["\uFEFF" + csvContent], { type: "text/csv;charset=utf-8" });
		const url = URL.createObjectURL(blob);
		const link = document.createElement("a");
		link.href = url;
		link.download = "dados.csv";
		link.click();
		URL.revokeObjectURL(url);
	}

	async function handleUploadFile(uploadFile) {
		try {
			const upItems = [];
			const upNivel1 = [];
			const upNivel2 = [];
			const upNivel3 = [];
			const upNivel4 = [];
			const nivel1List = nivel1 || [];
			const nivel2List = nivel2 || [];
			const nivel3List = nivel3 || [];
			const nivel4List = nivel4 || [];

			for (var i = 0; i < uploadFile.length; i++) {
				const {
					nivel_loc_1,
					nivel_loc_1_ativo,
					nivel_loc_1_tag,
					nivel_loc_1_cor,
					nivel_loc_2,
					nivel_loc_2_ativo,
					nivel_loc_2_tag,
					nivel_loc_2_cor,
					nivel_loc_3,
					nivel_loc_3_ativo,
					nivel_loc_3_tag,
					nivel_loc_3_cor,
					nivel_loc_4,
					nivel_loc_4_ativo,
					nivel_loc_4_tag,
					nivel_loc_4_cor,
				} = uploadFile[i];
				const getNivel1 = nivel1List.find((c) => c.descricao === nivel_loc_1);
				const getNivel2 = nivel_loc_2
					? nivel2List.find((c) => c.descricao === nivel_loc_2 && c.id_nivel_loc1 === getNivel1?._id)
					: "";
				const getNivel3 = nivel_loc_3
					? nivel3List.find((c) => c.descricao === nivel_loc_3 && c.id_nivel_loc2 === getNivel2?._id)
					: "";
				const getNivel4 = nivel_loc_4
					? nivel4List.find((c) => c.descricao === nivel_loc_4 && c.id_nivel_loc3 === getNivel3?._id)
					: "";

				const row = {
					id: i,
					id_conta: id_conta,
					status: nivel_loc_1 ? "Ok" : "Erro",
					id_nivel_loc_1: nivel_loc_1 ? getNivel1?._id || getID() : "",
					id_nivel_loc_2: nivel_loc_1 ? getNivel2?._id || getID() : "",
					id_nivel_loc_3: nivel_loc_1 ? getNivel3?._id || getID() : "",
					id_nivel_loc_4: nivel_loc_1 ? getNivel4?._id || getID() : "",
					nivel_loc_1: getNivel1?.descricao || nivel_loc_1,
					nivel_loc_2: getNivel2?.descricao || nivel_loc_2,
					nivel_loc_3: getNivel3?.descricao || nivel_loc_3,
					nivel_loc_4: getNivel4?.descricao || nivel_loc_4,
					nivel_loc_1_tag: nivel_loc_1_tag || "",
					nivel_loc_2_tag: nivel_loc_2_tag || "",
					nivel_loc_3_tag: nivel_loc_3_tag || "",
					nivel_loc_4_tag: nivel_loc_4_tag || "",
					nivel_loc_1_ativo: nivel_loc_1_ativo || 1,
					nivel_loc_2_ativo: nivel_loc_2_ativo || 1,
					nivel_loc_3_ativo: nivel_loc_3_ativo || 1,
					nivel_loc_4_ativo: nivel_loc_4_ativo || 1,
					nivel_loc_1_cor: nivel_loc_1_cor?.toLowerCase() || "",
					nivel_loc_2_cor: nivel_loc_2_cor?.toLowerCase() || "",
					nivel_loc_3_cor: nivel_loc_3_cor?.toLowerCase() || "",
					nivel_loc_4_cor: nivel_loc_4_cor?.toLowerCase() || "",
					nivel_loc_1_status: nivel_loc_1
						? getStatus(nivel_loc_1, nivel_loc_1_ativo, 1, nivel_loc_1, getNivel1?._id)
						: "",
					nivel_loc_2_status: nivel_loc_2
						? getStatus(nivel_loc_2, nivel_loc_2_ativo, 2, nivel_loc_1, getNivel2?._id)
						: "",
					nivel_loc_3_status: nivel_loc_3
						? getStatus(nivel_loc_3, nivel_loc_3_ativo, 3, nivel_loc_2, getNivel3?._id)
						: "",
					nivel_loc_4_status: nivel_loc_4
						? getStatus(nivel_loc_4, nivel_loc_4_ativo, 4, nivel_loc_3, getNivel4?._id)
						: "",
				};

				upItems.push(row);

				if (!row.status.includes("Erro")) {
					if (!getNivel1 && nivel_loc_1 && !row.nivel_loc_1_status.includes("Erro")) {
						const upNivel = {
							_id: row.id_nivel_loc_1,
							descricao: row.nivel_loc_1,
							tag: row.nivel_loc_1_tag,
							cor: row.nivel_loc_1_cor,
							ativo: row.nivel_loc_1_ativo,
							id_conta: id_conta,
						};
						upNivel1.push(upNivel);
						nivel1.push(upNivel);
					}

					if (!getNivel2 && nivel_loc_2 && !row.nivel_loc_2_status.includes("Erro")) {
						const upNivel = {
							_id: row.id_nivel_loc_2,
							descricao: row.nivel_loc_2,
							id_nivel_loc1: row.id_nivel_loc_1,
							tag: row.nivel_loc_2_tag,
							cor: row.nivel_loc_2_cor,
							ativo: row.nivel_loc_2_ativo,
							id_conta: id_conta,
						};
						upNivel2.push(upNivel);
						nivel2.push(upNivel);
					}

					if (!getNivel3 && nivel_loc_3 && !row.nivel_loc_3_status.includes("Erro")) {
						const upNivel = {
							_id: row.id_nivel_loc_3,
							descricao: row.nivel_loc_3,
							id_nivel_loc2: row.id_nivel_loc_2,
							tag: row.nivel_loc_3_tag,
							cor: row.nivel_loc_3_cor,
							ativo: row.nivel_loc_3_ativo,
							id_conta: id_conta,
						};
						upNivel3.push(upNivel);
						nivel3.push(upNivel);
					}

					if (!getNivel4 && nivel_loc_4 && !row.nivel_loc_4_status.includes("Erro")) {
						const upNivel = {
							_id: row.id_nivel_loc_4,
							descricao: row.nivel_loc_4,
							id_nivel_loc3: row.id_nivel_loc_3,
							tag: row.nivel_loc_4_tag,
							cor: row.nivel_loc_4_cor,
							ativo: row.nivel_loc_4_ativo,
							id_conta: id_conta,
						};
						upNivel4.push(upNivel);
						nivel4.push(upNivel);
					}
				}
			}
			setItems(upItems);
			setUpComming({
				categoria: [],
				itens: [],
				nivel1: upNivel1,
				nivel2: upNivel2,
				nivel3: upNivel3,
				nivel4: upNivel4,
				parceiros: [],
			});
			const grouped = [
				{
					color: green[600],
					label: "Novos Itens",
					icon: "lets-icons:check-ring-duotone",
					value: upItems.filter((item) => item.status.includes("Ok"))?.length,
				},
				{
					color: grey[500],
					label: "Já cadastrados",
					icon: "lets-icons:check-ring-duotone",
					value: upItems.filter((item) => item.status.includes("Registrado"))?.length,
				},
				{
					color: red[300],
					label: "Tag repetidas",
					icon: "lets-icons:close-ring-duotone",
					value: upItems.filter((item) => item.status.includes("Duplicada"))?.length,
				},
				{
					color: red[300],
					label: "Itens incorretos",
					icon: "lets-icons:close-ring-duotone",
					value: upItems.filter((item) => item.status.includes("Erro"))?.length,
				},
				{
					color: grey[500],
					label: "Itens importados",
					icon: "lets-icons:folder-check-duotone",
					value: upItems.filter((item) => !item.status.includes("Erro"))?.length,
				},
				{
					color: grey[500],
					label: "Total de itens",
					icon: "lets-icons:folder-up-duotone",
					value: upItems?.length,
				},
			];
			setImportCount(grouped);
		} catch (err) {
			console.log("erro: " + err);
		} finally {
			setLoading(false);
		}
	}

	return (
		<>
			<Stack spacing={3}>
				<Stack direction="row" justifyContent="space-between">
					<Typography variant="h1">Importar arquivo CSV</Typography>
					<Stack spacing={1} direction="row">
						<Button
							variant="outlined"
							color="dark"
							onClick={(e) => {
								navigate("/locations");
							}}
						>
							{t("actions.exit")}
						</Button>
						<Button
							disabled={!upComming}
							variant="contained"
							onClick={(e) => {
								handleSave();
							}}
						>
							{t("actions.save")}
						</Button>
					</Stack>
				</Stack>

				<Stack spacing={3} as={Paper} sx={{ borderRadius: 3, p: 3 }}>
					<Stack direction="row" justifyContent="end" spacing={1}>
						<Button
							onClick={(e) => {
								handleTemplate();
							}}
						>
							Baixar {t("export.model")}
						</Button>
						<Button
							variant="outlined"
							color="dark"
							startIcon={<Icon icon="solar:settings-linear" />}
							onClick={() => {
								handleNavigate();
							}}
						>
							Configurar
						</Button>
					</Stack>

					<ImportCSV
						{...configImport}
						onUpload={(e) => {
							handleFile(e);
						}}
					/>

					{importCount ? (
						<Stack direction="row" justifyContent="center">
							<Stack
								direction="row"
								divider={<Divider orientation="vertical" flexItem />}
								spacing={1}
								sx={{ boxShadow: 1, p: 2, borderRadius: 2, border: 1, borderColor: grey[100] }}
							>
								{importCount?.map((item) => (
									<>
										<Stack direction="row" alignItems="center" spacing={1} sx={{ p: 1, px: 2 }}>
											<Icon width="32" icon={item.icon} color={item?.color} />
											<Typography>{item.label}:</Typography>
											<Typography>{item.value}</Typography>
										</Stack>
									</>
								))}
							</Stack>
						</Stack>
					) : (
						""
					)}

					{items ? (
						<DataGrid
							localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
							rows={items}
							columns={columns}
							columnGroupingModel={columnsGroups}
							editMode="row"
							disableRowSelectionOnClick={true}
							sx={{
								border: 0,
							}}
							pageSizeOptions={[10, 50, 100, 500, 1000]}
							processRowUpdate={(updatedRow, originalRow) => {
								setItems((prevRows) => {
									const newRows = [...prevRows];
									newRows[originalRow.id] = updatedRow;
									return newRows;
								});
							}}
							getRowHeight={({ id, densityFactor }) => {
								if (id % 2 === 0) {
									return 50 * densityFactor;
								}

								return null;
							}}
							slotProps={{
								columnsManagement: {
									toggleAllMode: "filteredOnly",
								},
							}}
						/>
					) : (
						""
					)}
				</Stack>
			</Stack>

			{showModal && (
				<CustomModal dividers={false}>
					<Stack spacing={2} sx={{ p: 2 }}>
						<Typography color="error">
							<Icon icon="ph:warning-fill" width="64" />
						</Typography>
						<div>
							<Typography variant="h1">{t("messages.attention")}</Typography>
							<Typography variant="subtitle">
								Não há uma configuração de importação definida. Deseja continuar mesmo assim?
							</Typography>
						</div>
						<Stack direction="row" justifyContent="flex-end" spacing={2}>
							<Button
								variant="outlined"
								color="dark"
								onClick={() => {
									navigate("/items/import/config/csv", { state: { xmlfields: fileHeader, preLoad: fileData } });
								}}
							>
								Configurar
							</Button>
							<Button
								variant="contained"
								onClick={() => {
									setShowModal(false);
									handleUpload(fileData);
								}}
							>
								Continuar
							</Button>
						</Stack>
					</Stack>
				</CustomModal>
			)}

			<Loading show={loading} />

			{message ? (
				<Alert
					type={message.type}
					title={message.title}
					message={message.message}
					onClose={(e) => {
						setMessage(e);
					}}
				/>
			) : (
				""
			)}
		</>
	);
}
