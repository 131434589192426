import React, { useState, useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

// componentes
import api from "../../services/api";
import LoadingPage from "../../components/loading/LoadingPage";

// estilos
import "./style.css";
import logoInova from "../../assets/logoinova.png";
import logo from "../../assets/logo.webp";

//packages
import { useTranslation } from "react-i18next";
import emailjs from "emailjs-com";
import Swal from "sweetalert2";

import secureLocalStorage from "react-secure-storage";
import dayjs from "dayjs";
import {
	Box,
	Button,
	Divider,
	FormControl,
	FormGroup,
	FormLabel,
	Grid2,
	Stack,
	TextField,
	Typography,
} from "@mui/material";
import Loading from "../../components/ui/Loading";
import useToken from "../../hooks/useToken";
import Alert from "../../components/ui/Alert";
import { Icon } from "@iconify/react/dist/iconify.js";

// ----------------------------------------------------------------

export default function Login() {
	const { token, id_conta } = useParams();
	const redirect = useLocation()?.state?.redirect;
	const history = useNavigate();
	const { t } = useTranslation();
	//
	const [loading, setLoading] = useState(false);
	const [recoverLoading, setRecoverLoading] = useState(false);
	const [userLogin, setUserLogin] = useState("");
	const [passLogin, setPassLogin] = useState("");
	const [message, setMessage] = useState("");

	useEffect(() => {
		if (token) {
			tokenAuthentication();
		}
	}, []);

	useEffect(() => {
		setLoading(true);
		if (sessionStorage.getItem("idConta")) {
			history("/home");
		}
		setLoading(false);
	}, []);

	function Auth(e) {
		if (userLogin !== "" && passLogin !== "") {
			setLoading(true);
			const user = {
				login: userLogin,
				senha: passLogin,
				tipo: "BO",
			};
			api.post(`/login`, user).then(
				(response) => {
					let User = response.data;

					// Store basic user information
					sessionStorage.setItem("idConta", User.id_conta);
					sessionStorage.setItem("idUser", User._id);
					User.perfil === ""
						? sessionStorage.setItem("perfil", "admin")
						: sessionStorage.setItem("perfil", User.perfil);
					if (User.nome) {
						sessionStorage.setItem("username", User.nome);
					}

					// Seta pra disconectar automaticamente para daqui um dia
					let disconnect = dayjs().add(1, "day").format();
					if (User.id_conta === "Mrz2ue7F2") {
						// Tratativa de desconect da CBA
						if (dayjs().get("hour") < 18) {
							disconnect = dayjs().set("hour", 18).set("minute", 0).set("second", 0).set("millisecond", 0).format();
						} else {
							disconnect = dayjs()
								.add(1, "day")
								.set("hour", 18)
								.set("minute", 0)
								.set("second", 0)
								.set("millisecond", 0)
								.format();
						}
					}
					sessionStorage.setItem("disconnect", disconnect);

					// Store access levels if available
					if (User.niveis_acesso.length > 0) {
						if (User?.niveis_acesso[0]?.niveis1[0]?.id_nivel_loc1?.length) {
							sessionStorage.setItem("nivel1", User.niveis_acesso[0].niveis1[0].id_nivel_loc1);
						}
						if (User?.niveis_acesso[0]?.niveis2[0]?.id_nivel_loc2?.length) {
							sessionStorage.setItem("nivel2", User.niveis_acesso[0].niveis2[0].id_nivel_loc2);
						}
						if (User?.niveis_acesso[0]?.niveis3[0]?.id_nivel_loc3?.length) {
							sessionStorage.setItem("nivel3", User.niveis_acesso[0].niveis3[0].id_nivel_loc3);
						}
						if (User?.niveis_acesso[0]?.niveis4[0]?.id_nivel_loc4?.length) {
							sessionStorage.setItem("nivel4", User.niveis_acesso[0].niveis4[0].id_nivel_loc4);
						}
					}

					// Store custom navbar if available
					if (User.modulos_acesso?.length && User.modulos_acesso[0] !== null) {
						sessionStorage.setItem(
							"menu",
							JSON.stringify([{ custom: Object.keys(User.modulos_acesso[0]).filter((item) => item !== "_id") }])
						);
					}

					// Store personalized profile permissions if available
					if (User.perfil_personalizavel.length > 0) {
						if (User.perfil_personalizavel[0].permissoes[0].itens) {
							sessionStorage.setItem("filterView", User.perfil_personalizavel[0].permissoes[0].itens.visualizar);
							sessionStorage.setItem("filterCreate", User.perfil_personalizavel[0].permissoes[0].itens.criar);
							sessionStorage.setItem("filterEdit", User.perfil_personalizavel[0].permissoes[0].itens.editar);
							sessionStorage.setItem("filterExclude", User.perfil_personalizavel[0].permissoes[0].itens.excluir);
						}
						if (User.perfil_personalizavel[0].permissoes[1]) {
							const permissions = User.perfil_personalizavel[0].permissoes[1].itens;
							if (permissions.criar === false) {
								sessionStorage.setItem("cantCreate", true);
							}
							if (permissions.editar === false) {
								sessionStorage.setItem("cantEdit", true);
							}
							if (permissions.excluir === false) {
								sessionStorage.setItem("cantDelete", true);
							}
						}
					}

					secureLocalStorage.setItem("userDetails", User);

					async function getToken() {
						const id_conta = User?.id_conta;
						const headerToken = { headers: { Authentication: id_conta } };
						api.get(`/getToken/${id_conta}`, headerToken).then((response) => {
							try {
								const iToken = response?.data?.token;
								secureLocalStorage.setItem("auth_token", iToken);
								getUserDetails(headerToken);
							} catch {
								throw "error";
							}
						});
					}

					getToken();

					async function getUserDetails({ itoken }) {
						api
							.get(`/usuario/${User.id_conta}/*/*/*/*`, { Authorization: "Bearer " + itoken })
							.then(
								(response) => {
									// Store custom navbar if available
									const res_data = response.data[0];

									if (res_data?.modulos_acesso?.length && res_data.modulos_acesso[0] !== null) {
										const custom_menu = res_data.modulos_acesso[0];
										secureLocalStorage.setItem("custom-menu", custom_menu);
									}

									Swal.fire({
										title: `${t("login.messages.sign_in.success")}`,
										text: `${t("login.messages.sign_in.welcome")} ${User.nome}`,
										icon: "success",
										showConfirmButton: false,
										timerProgressBar: true,
										timer: "2500",
									}).then((result) => {
										window.location.href = redirect ?? "/home";
									});
								},
								(error) => {
									Swal.fire({
										title: "Oops... !",
										text: `${t("login.messages.sign_up.errors.error")}`,
										icon: "error",
										showConfirmButton: false,
										timerProgressBar: true,
										timer: "2500",
									});
								}
							)
							.finally(() => {
								setLoading(false);
							});
					}
				},
				(err) => {
					const errMessage = err.response?.data?.error;
					const errStatus = err.response.status;
					const msg = {
						title: errStatus == 403 ? t("messages.errors.403") : t("messages.errors.error"),
						message: errMessage.includes("Usuário não registrado!")
							? t("messages.login.user_not_found")
							: errMessage.includes("Entre em contato com a equipe de suporte!")
								? t("messages.login.block_acess")
								: errMessage.includes("Usuário/Senha incorreto!")
									? t("messages.login.user_pass_error")
									: t("messages.errors.500"),
					};

					setMessage({
						type: "error",
						message: msg?.message,
						title: msg?.title,
					});
					setLoading(false);
				}
			);
		} else {
			Swal.fire({
				title: "Oops... !",
				text: `${t("login.messages.sign_in.missing")}`,
				icon: "error",
				showConfirmButton: false,
				timerProgressBar: true,
				timer: "2500",
			});
		}
	}

	function tokenAuthentication(e) {
		setLoading(true);
		api
			.post(`/login/authcba`, null, {
				headers: {
					Authorization: `Bearer ${token}`,
					id_conta: id_conta,
				},
			})
			.then(
				(response) => {
					let User = response.data;

					// Store basic user information
					sessionStorage.setItem("idConta", User.id_conta);
					sessionStorage.setItem("idUser", User._id);
					User.perfil === ""
						? sessionStorage.setItem("perfil", "admin")
						: sessionStorage.setItem("perfil", User.perfil);

					// Set disconnection date (2 days from now)
					const disconnect = new Date(new Date().getTime() + 2 * 24 * 60 * 60 * 1000);
					secureLocalStorage.setItem("ks:u", { disconnect: disconnect }); // armazena informações criptografadas

					// Store username if available
					if (User.nome) {
						sessionStorage.setItem("username", User.nome);
					}

					// Store access levels if available
					if (User.niveis_acesso.length > 0) {
						if (User.niveis_acesso[0].niveis1[0].id_nivel_loc1.length > 1) {
							sessionStorage.setItem("nivel1", User.niveis_acesso[0].niveis1[0].id_nivel_loc1);
						}
						if (User.niveis_acesso[0].niveis2[0].id_nivel_loc2.length > 1) {
							sessionStorage.setItem("nivel2", User.niveis_acesso[0].niveis2[0].id_nivel_loc2);
						}
						if (User.niveis_acesso[0].niveis3[0].id_nivel_loc3.length > 1) {
							sessionStorage.setItem("nivel3", User.niveis_acesso[0].niveis3[0].id_nivel_loc3);
						}
						if (User.niveis_acesso[0].niveis4[0].id_nivel_loc4.length > 1) {
							sessionStorage.setItem("nivel4", User.niveis_acesso[0].niveis4[0].id_nivel_loc4);
						}
					}

					// Store custom navbar if available
					if (User.modulos_acesso?.length && User.modulos_acesso[0] !== null) {
						sessionStorage.setItem(
							"menu",
							JSON.stringify([{ custom: Object.keys(User.modulos_acesso[0]).filter((item) => item !== "_id") }])
						);
					}

					// Store personalized profile permissions if available
					if (User.perfil_personalizavel.length > 0) {
						if (User.perfil_personalizavel[0].permissoes[0].itens) {
							sessionStorage.setItem("filterView", User.perfil_personalizavel[0].permissoes[0].itens.visualizar);
							sessionStorage.setItem("filterCreate", User.perfil_personalizavel[0].permissoes[0].itens.criar);
							sessionStorage.setItem("filterEdit", User.perfil_personalizavel[0].permissoes[0].itens.editar);
							sessionStorage.setItem("filterExclude", User.perfil_personalizavel[0].permissoes[0].itens.excluir);
						}
					}

					Swal.fire({
						title: `${t("login.messages.sign_in.success")}`,
						text: `${t("login.messages.sign_in.welcome")} ${User.nome}`,
						icon: "success",
						showConfirmButton: false,
						timerProgressBar: true,
						timer: "2500",
					}).then(
						(result) => {
							setLoading(false);
							if (redirect) {
								history(redirect);
							} else {
								history("/home");
							}
						},
						(result) => {
							Swal.fire({
								title: "Oops... !",
								text: `${t("login.messages.sign_up.errors.error")}`,
								icon: "error",
								showConfirmButton: false,
								timerProgressBar: true,
								timer: "2500",
							});
						}
					);
				},
				(error) => {
					Swal.fire({
						title: error.response.status === 403 ? "Acesso negado" : "Oops... !",
						text:
							error.response.status === 403
								? "Entre em contato com o suporte"
								: `${t("login.messages.sign_in.login_error")}`,
						icon: "error",
						showCancelButton: error.response.status === 403 ? true : false,
						showConfirmButton: error.response.status === 403 ? true : false,
						confirmButtonColor: "#2BA352",
						confirmButtonText: "Abrir chat com suporte.",
					}).then((result) => {
						if (result?.isConfirmed) {
							window.location.href = "https://wa.me/5511942316933";
						}
					});
					setLoading(false);
				}
			);
	}

	function RecoverFunc() {
		Swal.fire({
			title: `${t("login.messages.password_recover.title")}`,
			html: `<p style="text-align:left;">${t("login.messages.password_recover.text")}</p>`,
			input: "text",
			inputAttributes: {
				autocapitalize: "off",
			},
			showCancelButton: true,
			confirmButtonColor: "#DD6B55",
			confirmButtonText: `${t("login.messages.password_recover.send")}`,
			cancelButtonText: `${t("login.messages.password_recover.close")}`,
			preConfirm: (logRecover) => {
				if (logRecover !== "") {
					setRecoverLoading(true);
					api.get(`usuario/*/*/${logRecover}/*/*`).then(
						(response) => {
							if (response.data[0] && response.data[0].id_conta) {
								api.get(`/conta/${response.data[0].id_conta}/*/*/*`).then((res) => {
									const info = {
										userLogin: response.data[0].login,
										passLogin: response.data[0].senha,
										mainEmail: res.data[0].email,
									};
									emailjs
										.send(
											process.env.REACT_APP_SERVICE_ID,
											process.env.REACT_APP_TEMPLATE_ID,
											info,
											process.env.REACT_APP_USER_ID
										)
										.then(
											(result) => {
												Swal.fire({
													title: `${t("login.messages.password_recover.success")}`,
													text: `${t("login.messages.password_recover.success_text")} ${res.data[0].email}`,
													icon: "success",
													showConfirmButton: true,
												});
												setRecoverLoading(false);
											},
											(error) => {
												Swal.fire({
													title: "Oops... !",
													html: `${t("login.messages.password_recover.error")}<br/>${res.data[0].email}`,
													icon: "error",
													showConfirmButton: false,
													timerProgressBar: true,
													timer: "2500",
												});
												setRecoverLoading(false);
											}
										);
								});
							} else {
								Swal.fire({
									title: "Oops... !",
									text: `${t("login.messages.password_recover.error_catch")}`,
									icon: "error",
									showConfirmButton: false,
									timerProgressBar: true,
									timer: "2500",
								});
								setRecoverLoading(false);
							}
						},
						(response) => {
							Swal.fire({
								title: "Oops... !",
								text: `${t("login.messages.password_recover.error_catch")}`,
								icon: "error",
								showConfirmButton: false,
								timerProgressBar: true,
								timer: "2500",
							});
							setRecoverLoading(false);
						}
					);
				} else {
					Swal.fire({
						title: "Oops... !",
						text: `${t("login.messages.password_recover.error_missing")}`,
						icon: "error",
						showConfirmButton: false,
						timerProgressBar: true,
						timer: "2500",
					});
					setRecoverLoading(false);
				}
			},
			allowOutsideClick: () => !Swal.isLoading(),
		});
	}

	return (
		<>
			<Loading show={loading || recoverLoading} />

			{message ? (
				<Alert
					type={message.type}
					title={message.title}
					message={message.message}
					buttons={[
						{
							text: t("messages.call_support"),
							variant: "contained",
							color: "primary",
							onClick: () => {
								window.open("https://wa.me/5511942316933");
							},
							startIcon: "mingcute:chat-1-line",
						},
						{
							text: t("actions.exit"),
							variant: "outlined",
							color: "dark",
							onClick: () => setMessage(null),
						},
					]}
					onClose={(e) => {
						setMessage(null);
					}}
				/>
			) : (
				""
			)}
			<Grid2 container alignItems="stretch" sx={{ minHeight: "100vh" }}>
				{/* login form*/}
				<Grid2 size={{ xs: "grow", md: 7 }} display="flex" alignItems="center" justifyContent="center">
					<Stack spacing={3} sx={{ width: { xs: "80%", md: "40%" } }}>
						<Stack alignItems="center">
							<Box
								component="img"
								sx={{
									width: "20ch",
									p: 3,
								}}
								alt="Logotype Inovacode"
								src={logoInova}
							/>
						</Stack>
						<Typography align="left" variant="h1">
							{t("login.sign_in")}
						</Typography>
						<FormControl>
							<Typography>{t("login.placeholder.login")}</Typography>
							<TextField size="small" type="text" value={userLogin} onChange={(e) => setUserLogin(e.target.value)} />
						</FormControl>
						<FormControl>
							<Typography>{t("login.placeholder.password")}</Typography>
							<TextField
								size="small"
								type="password"
								value={passLogin}
								onChange={(e) => setPassLogin(e.target.value)}
							/>
						</FormControl>
						<Stack spacing={1}>
							<Button
								variant="contained"
								onClick={() => {
									Auth();
								}}
							>
								{t("login.buttons.login")}
							</Button>
							<Button
								fullWidth
								variant="outlined"
								sx={{ display: { xs: "block", md: "none" } }}
								onClick={(e) => history("/register")}
							>
								{t("login.buttons.create_account")}
							</Button>
							<Typography
								variant="subtitle"
								color="primary"
								align="right"
								onClick={(e) => {
									RecoverFunc();
								}}
								sx={{ cursor: "pointer" }}
							>
								{t("login.forgot_password")}
							</Typography>
						</Stack>
					</Stack>
				</Grid2>

				{/* redirect  cadastro*/}
				<Grid2
					size={{ xs: 0, md: "grow" }}
					className="background"
					display="flex"
					alignItems="center"
					justifyContent="center"
				>
					<Stack spacing={3} alignItems="center">
						<Typography variant="h2" align="center">
							{t("login.welcome")}
						</Typography>
						<Divider textAlign="center" sx={{ border: 2, width: "30%", opacity: "1" }} variant="middle" />
						<Typography variant="h5">{t("login.register_text")}</Typography>
						<Button
							fullWidth
							variant="outlined"
							sx={{ borderRadius: 10, borderColor: "#fff", color: "#fff" }}
							onClick={(e) => history("/register")}
						>
							{t("login.buttons.create_account")}
						</Button>
					</Stack>
				</Grid2>
			</Grid2>
		</>
	);
}
