import { useEffect, useState } from "react";

import { t } from "i18next";

import api from "../../../services/api";

import b64Resize from "../../../services/b64Resize";

import useToken from "../../../hooks/useToken";

// Styles

import { Icon } from "@iconify/react/dist/iconify.js";

import {
	Box,
	Button,
	Container,
	Paper,
	Stack,
	Typography,
	MenuItem,
	Select,
	TextField,
	styled,
	FormGroup,
	Grid,
	Divider,
	IconButton,
	Autocomplete,
	InputAdornment,
	OutlinedInput,
	FormControlLabel,
	Switch,
	Menu,
	ListItemText,
	ListItemIcon,
	createFilterOptions,
	FormControl,
	FormLabel,
	Radio,
	RadioGroup,
	Tooltip,
	Grid2,
	Backdrop,
	Checkbox,
} from "@mui/material";

import AlertDialog from "../../../components/AlertDialog";

import Alert from "../../../components/ui/Alert";

import Dropdown from "../../../components/ui/Dropdown";

import Loading from "../../../components/loading";
import { useLocation, useNavigate } from "react-router-dom";
import ItemsQrcode from "./ItemsQrcode";
import dayjs from "dayjs";

//----------------------------------------------------------------

const filter = createFilterOptions();

const VisuallyHiddenInput = styled("input")({
	clip: "rect(0 0 0 0)",
	clipPath: "inset(50%)",
	height: "100%",
	overflow: "hidden",
	position: "absolute",
	bottom: 0,
	left: 0,
	whiteSpace: "nowrap",
	width: "100%",
});

export default function ItemDetails() {
	const navigate = useNavigate();

	const { token } = useToken();

	const location = useLocation();

	const { item, propsInfoCompl, config } = location?.state || {};

	const { dt_criacao, dt_alteracao, id_usuario } = item || {};

	const [message, setMessage] = useState();

	const [loading, setLoading] = useState(false);

	//	Informações do item
	const [itemDetails, setItemDetails] = useState(item || {});

	const [IDItem, setIDItem] = useState(item?._id);

	const [categoria, setCategoria] = useState(item?.id_categoria?._id);

	const [marca, setMarca] = useState(item?.id_marca?._id || "");

	const [modelo, setModelo] = useState(item?.id_modelo || "");

	const [parceiro, setParceiro] = useState(item?.id_parceiro?._id);

	const [tag, setTag] = useState(item?.tag);

	const [IDExterno, setIDExterno] = useState(item?.id_externo);

	const [ativo, setAtivo] = useState(item?.ativo || 1);

	const [infoCompl1, setInfoCompl1] = useState(item?.inf_compl_1 || null);

	const [infoCompl2, setInfoCompl2] = useState(item?.inf_compl_2 || null);

	const [infoCompl3, setInfoCompl3] = useState(item?.inf_compl_3 || null);

	const [infoCompl4, setInfoCompl4] = useState(item?.inf_compl_4 || null);

	const [infoCompl5, setInfoCompl5] = useState(item?.inf_compl_5 || null);

	const [infoCompl6, setInfoCompl6] = useState(item?.inf_compl_6 || null);

	const [infoCompl7, setInfoCompl7] = useState(item?.inf_compl_7 || null);

	const [infoCompl8, setInfoCompl8] = useState(item?.inf_compl_8 || null);

	const [infoCompl9, setInfoCompl9] = useState(item?.inf_compl_9 || null);

	const [infoCompl10, setInfoCompl10] = useState(item?.inf_compl_10 || null);

	const [nivel1, setNivel1] = useState(item?.id_nivel_loc_1?._id);

	const [nivel2, setNivel2] = useState(item?.id_nivel_loc_2?._id);

	const [nivel3, setNivel3] = useState(item?.id_nivel_loc_3?._id);

	const [nivel4, setNivel4] = useState(item?.id_nivel_loc_4?._id);

	const [latitude, setLatitude] = useState(item?.latitude ?? "");

	const [longitude, setLongitude] = useState(item?.longitude ?? "");

	const [radius, setRadius] = useState(item?.raio);

	const [valor, setValor] = useState(item?.valor || 0);

	const [registro, setRegistro] = useState(item?.id_registro ?? []);

	const [publico, setPublico] = useState(item?.publico || false);

	const [quantidade, setQuantidade] = useState(item?.quantidade);

	const [observacao, setObservacao] = useState(item?.observacao);

	const [fotoPrincipal, setFotoPrincipal] = useState(item?.foto ? "https://api.inovacode.app.br/" + item.foto : "");

	const [fotosComplementares, setFotosComplementares] = useState(item?.foto_compl || []);

	const [fotosRemovalList, setFotosRemovalList] = useState([]);

	const [UP1, setUP1] = useState(item?.up_1 || "");

	const [UP2, setUP2] = useState(item?.up_2 || "");

	const [UP3, setUP3] = useState(item?.up_3 || "");

	const [UP4, setUP4] = useState(item?.up_4 || "");

	const [UP5, setUP5] = useState(item?.up_1 || "");

	// List //
	const [categoriaList, setCategoriaList] = useState([]);

	const [marcaList, setMarcaList] = useState([]);

	const [modeloList, setModeloList] = useState([]);

	const [parceiroList, setParceiroList] = useState([]);

	const [nivel1List, setNivel1List] = useState([]);

	const [nivel2List, setNivel2List] = useState([]);

	const [nivel3List, setNivel3List] = useState([]);

	const [nivel4List, setNivel4List] = useState([]);

	const [infoComplOptions, setInfoComplOptions] = useState([]); // Informações complementares

	const [infoDetails, setInfoDetails] = useState([]);

	// Labels customizadas
	const [infoComplLabels, setInfoComplLabels] = useState(propsInfoCompl || []);

	const [nivelLabels, setNivelLabels] = useState([]);

	// Functions options
	const [showAllInfoCompl, setShowAllInfoCompl] = useState(false); // Mostrar todos os campos de informação complementares

	// Configurações
	const [contaConfig, setContaConfig] = useState();

	// Requisição da configuração da conta
	useState(() => {
		async function getContaConfig() {
			try {
				const idConta = sessionStorage.getItem("idConta");
				if (!idConta) return;

				const response = await api.get(`/conta_config/${idConta}`);
				const data = response?.data?.find((item) => item?._id) || {};

				setContaConfig(data);

				const infoCompl = Array.from({ length: 10 }, (_, i) => {
					const infoKey = `info_compl_${i + 1}`;
					return data[infoKey];
				});

				setInfoComplLabels(infoCompl);

				const niveisConfig = Array.from({ length: 4 }, (_, i) => {
					const infoKey = `nivel_${i + 1}`;
					return data[infoKey];
				});

				setNivelLabels(niveisConfig);
			} catch (error) {
				console.error("Error geting conta config:", error);
			}
		}

		getContaConfig();
	}, []);

	// Requisição de categoria
	useState(() => {
		function categoriaGet() {
			api.get(`/categoria/${sessionStorage.getItem("idConta")}/*/*/*/*`, {}).then((response) => {
				setCategoriaList(response.data.filter((c) => c.descricao));
			});
		}

		categoriaGet();
	}, []);

	// Requisição de marcas - modelos
	useEffect(() => {
		async function getMarcas() {
			// Verifica se a categoria está definida antes de fazer a requisição
			if (!categoria) {
				return;
			}

			try {
				// Faz a requisição à API
				const response = await api.get(
					`/infs_compl/${sessionStorage.getItem("idConta")}?id_categoria=${categoria}&marca=&modelo=`
				);

				// Atualiza a lista de marcas com os dados da resposta
				const marcaList = response.data || [];
				setMarcaList(marcaList);

				// Verifica se a marca atual existe na nova lista de marcas
				const getMarca = marcaList?.find((item) => item?._id === marca);

				setModeloList(getMarca?.modelo || []);

				if (marca && !getMarca) {
					setMarca(null);
					setModelo(null);
				}
			} catch (error) {
				console.error("Falha ao buscar marcas:", error);
			}
		}

		if (categoria) {
			getMarcas();
		}
	}, [categoria]);

	// Requisição de parceiros
	useEffect(() => {
		function parceiroGet() {
			api.get(`/parceiro/${sessionStorage.getItem("idConta")}/*/*/*/*`, {}).then((response) => {
				setParceiroList(response.data);
			});
		}

		parceiroGet();
	}, []);

	// Requisição de nivel 1
	useEffect(() => {
		function nivel1Get() {
			api.get(`/nivel_loc1/${sessionStorage.getItem("idConta")}/*/*/*/*`, {}).then((response) => {
				setNivel1List(response.data);
			});
		}

		nivel1Get();
	}, []);

	// Requisição de nivel 2
	useEffect(() => {
		function nivel2Get(nivel1) {
			if (nivel1 && nivel1 !== "*") {
				const idConta = sessionStorage.getItem("idConta");
				const nivel1Path = nivel1 || "*";

				api.get(`/nivel_loc2/${idConta}/${nivel1Path}/*/*/*/*`).then((response) => {
					setNivel2List(response.data);
					if (!response?.data.find((item) => item._id === nivel2)) {
						setNivel2();
						setNivel3();
						setNivel4();
					}
				});
			} else {
				setNivel2List([]);
			}
		}

		nivel2Get(nivel1);
	}, [nivel1]);

	// Requisição de nivel 3
	useEffect(() => {
		function nivel3Get(nivel2) {
			if (nivel2 && nivel2 !== "*") {
				const idConta = sessionStorage.getItem("idConta");
				const nivel2Path = nivel2 || "*";

				api
					.get(`/nivel_loc3/${idConta}/${nivel2Path}/*/*/*/*`)
					.then((response) => {
						setNivel3List(response.data);
						if (!response?.data.find((item) => item._id === nivel3)) {
							setNivel3();
							setNivel4();
						}
					})
					.catch((error) => {
						console.error("Error fetching data:", error);
						// Optional: handle error state, show user feedback, etc.
					});
			} else {
				setNivel3List([]);
			}
		}

		nivel3Get(nivel2);
	}, [nivel2]);

	// Requisição de nivel 4
	useEffect(() => {
		function nivel4Get(nivel3) {
			if (nivel3 && nivel3 !== "*") {
				const idConta = sessionStorage.getItem("idConta");
				const nivel3Path = nivel3 || "*";

				api.get(`/nivel_loc4/${idConta}/${nivel3Path}/*/*/*/*`).then((response) => {
					setNivel4List(response.data);
				});
			} else {
				setNivel4List([]);
			}
		}

		nivel4Get(nivel3);
	}, [nivel3]);

	// Requisição de nivel 4
	useEffect(() => {
		function getInfoCompl() {
			api.get(`/select_box/*/${sessionStorage.getItem("idConta")}`).then((response) => {
				let lista = [];
				for (let i = 0; i < 10; i++) {
					let aux = response.data.filter((item) => {
						return item.destino == `inf_compl_${i + 1}`;
					});
					lista[i] = aux ? aux[0] : null;
				}
				setInfoComplOptions(lista);
			});
		}

		getInfoCompl();
	}, []);

	// Separa quais informações complementares devem ser mostradas, se são lista ou texto e altera as labels
	useEffect(() => {
		const optionsInfos = Array.from({ length: 10 }, (_, index) => {
			const position = index + 1;
			const fieldName = `inf_compl_${position}`;
			const hasLabel = Boolean(infoComplLabels[index]);
			const hasValues = infoComplOptions[index]?.valores?.length > 0;

			return {
				label: hasLabel ? infoComplLabels[index] : `${t("common.info_label")} ${position}`,
				modo: hasValues ? "lista" : "texto",
				show: index === 0 || showAllInfoCompl || hasLabel || Boolean(itemDetails[fieldName]),
			};
		});

		setInfoDetails(optionsInfos);
	}, [infoComplLabels, showAllInfoCompl, infoComplOptions]);

	// # Função para tratar a alteração do modelo
	function handleModelo(modelos) {
		// Atualiza a lista de modelos
		setModeloList(modelos || []);

		// Verifica se o modelo atual existe na nova lista de modelos
		const getModelo = modelos?.some((item) => item._id === modelo);

		// Se o modelo atual não existir na lista, redefine o modelo para null
		if (!getModelo) {
			setModelo(null);
		}
	}

	function handleImageInput(files, type) {
		if (files && files.length > 0) {
			const imageUrls = [];

			for (let i = 0; i < files.length; i++) {
				const file = files[i];
				const imageUrl = URL.createObjectURL(file);
				imageUrls.push(imageUrl);
			}

			if (type === "principal") {
				setFotoPrincipal(imageUrls[0]);
			} else {
				setFotosComplementares([...fotosComplementares, ...imageUrls]);
			}
		}
	}

	function handleRemoveFoto(value, type) {
		let image;
		if (type === "complementar") {
			image = fotosComplementares[value].replace("https://api.inovacode.app.br/", "").replace(".jpeg", "");

			setFotosComplementares((prevFotos) => {
				const newFotos = [...prevFotos];
				newFotos.splice(value, 1);
				return newFotos;
			});
		} else {
			image = value.replace("https://api.inovacode.app.br/", "").replace(".jpeg", "");
			setFotoPrincipal("");
		}
		if (!image.includes("blob")) {
			setFotosRemovalList([...fotosRemovalList], image);
		}
	}

	async function removeFotos() {
		const header = {
			headers: { id_conta: sessionStorage.idConta, Authorization: `Bearer ${token}` },
		};

		const uploadImagesPromises = fotosComplementares.map(async (foto) => {
			return await api.delete(`/imgDel/${foto}`, header);
		});

		const uploadImagesList = await Promise.all(uploadImagesPromises);
	}

	async function uploadImages() {
		const uploadImagesPromises = fotosComplementares.map(async (foto) => {
			if (foto.includes("blob")) {
				return "https://api.inovacode.app.br/" + (await saveImage(foto));
			} else {
				return foto;
			}
		});

		const uploadImagesList = await Promise.all(uploadImagesPromises);

		return uploadImagesList;
	}

	async function saveImage(image) {
		const option = {
			headers: { "Content-Type": "application/json" },
		};

		if (image && image.includes("blob")) {
			const b64 = await b64Resize(image, 500, 500);
			const response = await api.post(`/save_jpeg/${sessionStorage.getItem("idConta")}`, [{ foto: b64 }], option);
			return response.data[0].id_foto;
		}
	}

	// Salva alterações no item
	async function handleSave(redirect) {
		const uploadImageList = await uploadImages();
		setFotosComplementares(uploadImageList);

		const reg = {
			...itemDetails,
			ativo: ativo || 1,
			foto: await saveImage(fotoPrincipal),
			foto_compl: uploadImageList,
			id_categoria: categoria,
			id_conta: sessionStorage.getItem("idConta"),
			id_usuario: sessionStorage.getItem("idUser"),
			id_externo: IDExterno,
			id_item_vinculado: null,
			id_nivel_loc_1: nivel1,
			id_nivel_loc_2: nivel2,
			id_nivel_loc_3: nivel3,
			id_nivel_loc_4: nivel4,
			id_parceiro: parceiro,
			id_registro: item?.id_registro?._id,
			inf_compl_1: infoCompl1,
			inf_compl_2: infoCompl2,
			inf_compl_3: infoCompl3,
			inf_compl_4: infoCompl4,
			inf_compl_5: infoCompl5,
			inf_compl_6: infoCompl6,
			inf_compl_7: infoCompl7,
			inf_compl_8: infoCompl8,
			inf_compl_9: infoCompl9,
			inf_compl_10: infoCompl10,
			status_estoque: item?.status_estoque,
			latitude: latitude || "",
			longitude: longitude || "",
			raio: radius || "",
			lido_registro: 1,
			observacao: observacao,
			tag: tag,
			valor: Number(valor),
			_id: item._id || 0,
			id_marca: marca,
			id_modelo: modelo,
			publico: publico,
		};

		const option = {
			headers: { "Content-Type": ["application/json"] },
		};

		const campos_obrigatorios = JSON.parse(contaConfig?.obrigatorio || "[]");

		const camposNaoPreenchidos = campos_obrigatorios.filter((campo) => !reg[campo]);

		if (camposNaoPreenchidos?.length > 0) {
			setMessage({
				type: "error",
				title: t("messages.errors.error"),
				message: t("messages.errors.missing_field"),
				callout: camposNaoPreenchidos?.map((campo) => campo.replace(/^id_/, "").replaceAll("_", " ")).join(", "),
			});
			return;
		}

		api
			.post("/itemBO", [reg], option)
			.then((response) => {
				if (fotosRemovalList.length) {
					removeFotos(uploadImageList);
				} else {
					const item = response?.data?.find((i) => i?._id);
					setIDItem(item);
					if (redirect) {
						navigate(redirect);
						return;
					}
					setMessage({
						type: "success",
						message: t("messages.success_edit"),
						title: t("messages.success"),
					});
				}
			})
			.catch((error) => {
				setMessage({
					type: "error",
					title: t("messages.errors.error"),
					message: t("messages.errors.500"),
				});
			});
	}

	// Função para excluir item
	async function handleDelete() {
		AlertDialog.fire({
			title: "Atenção",
			message: "Deseja realmente excluir este Item ?",
			confirmText: t("actions.continue"),
			cancelText: t("actions.cancel"),
			onConfirm: () => {
				let option = { headers: { "Content-Type": ["application/json"] } };
				api
					.delete(
						`/item/${IDItem}?id_conta=${sessionStorage.getItem("idConta")}?id_usuario=${sessionStorage.getItem("idUser")}`,
						option
					)
					.then(
						(response) => {
							setMessage({
								type: "success",
								message: t("messages.success_save"),
								title: t("messages.success"),
							});
							navigate("/items");
						},
						(error) => {
							setMessage({
								type: "error",
								title: t("messages.errors.error"),
								message: t("messages.errors.500"),
							});
						}
					);
			},
			onCancel: () => console.log("Formulário cancelado"),
		});
	}

	// Função para criar marcas
	function handleCreateMarca() {
		AlertDialog.fire({
			title: "Nova Marca",
			message: "Insira o nome da marca",
			confirmText: t("actions.continue"),
			cancelText: t("actions.cancel"),
			fields: [{ name: "marca", label: "Marca", type: "text" }],
			onConfirm: (data) => {
				if (data?.marca) {
					const reg = [
						{
							_id: "0",
							id_conta: sessionStorage.getItem("idConta"),
							id_categoria: categoria,
							marca: data?.marca,
							modelo: [],
						},
					];
					let option = {
						headers: { "Content-Type": ["application/json"] },
					};
					api.post("/infs_compl", reg, option).then(
						(response) => {
							setMarcaList((prev) => [...prev, response.data[0][0]]);
							setMessage({
								type: "success",
								message: t("messages.success_save"),
								title: t("messages.success"),
							});
						},
						(error) => {
							setMessage({
								type: "error",
								title: t("messages.errors.error"),
								message: t("messages.errors.500"),
							});
						}
					);
				}
			},
			onCancel: () => console.log("Formulário cancelado"),
		});
	}

	// Função para excluir marcas
	function handleDeleteMarca(id_marca) {
		AlertDialog.fire({
			title: "Atenção",
			message: "Deseja realmente excluir este Item ?",
			confirmText: t("actions.continue"),
			cancelText: t("actions.cancel"),
			onConfirm: () => {
				api.delete(`/infs_compl/${id_marca}`).then(
					(response) => {
						setMarcaList(marcaList.filter((m) => m._id != id_marca));
						setMessage({
							type: "success",
							message: t("messages.success_save"),
							title: t("messages.success"),
						});
					},
					(error) => {
						setMessage({
							type: "error",
							title: t("messages.errors.error"),
							message: t("messages.errors.500"),
						});
					}
				);
			},
			onCancel: () => console.log("Formulário cancelado"),
		});
	}

	// Função para criar modelos
	function handleCreateModelo() {
		AlertDialog.fire({
			title: "Novo modelo",
			message: "Insira o nome do modelo",
			confirmText: t("actions.continue"),
			cancelText: t("actions.cancel"),
			fields: [{ name: "input_modelo", label: "Modelo", type: "text" }],
			onConfirm: (data) => {
				const { input_modelo } = data;
				if (input_modelo && marca) {
					const reg = marcaList.find((m) => m._id == marca);
					const newModelos = modeloList?.length ? modeloList : [];
					newModelos.push({
						_id: "0",
						descricao: input_modelo,
					});
					reg._id = "0";
					reg.modelo = newModelos;
					let option = {
						headers: { "Content-Type": ["application/json"] },
					};
					api.post("/infs_compl", [reg], option).then(
						(response) => {
							setModeloList(newModelos);
							setModelo(response?.data[0]?.modelo?.find((m) => m.descricao == input_modelo)?._id);
							setMessage({
								type: "success",
								message: t("messages.success_save"),
								title: t("messages.success"),
							});
						},
						(error) => {
							setMessage({
								type: "error",
								title: t("messages.errors.error"),
								message: t("messages.errors.500"),
							});
						}
					);
				}
			},
			onCancel: () => console.log("Formulário cancelado"),
		});
	}

	// Função para excluir modelos
	function handleDeleteModelo(id_modelo) {
		AlertDialog.fire({
			title: "Atenção",
			message: "Deseja realmente excluir este modelo ?",
			confirmText: t("actions.continue"),
			cancelText: t("actions.cancel"),
			onConfirm: () => {
				if (id_modelo && marca) {
					const reg = marcaList.find((m) => m._id == marca);
					reg.modelo = modeloList.filter((m) => m._id !== id_modelo);
					let option = {
						headers: { "Content-Type": ["application/json"] },
					};
					api.post("/infs_compl", [reg], option).then(
						(response) => {
							setModeloList(response.data[0]?.modelo);
							setMessage({
								type: "success",
								message: t("messages.success_save"),
								title: t("messages.success"),
							});
						},
						(error) => {
							setMessage({
								type: "error",
								title: t("messages.errors.error"),
								message: t("messages.errors.500"),
							});
						}
					);
				}
			},
			onCancel: () => console.log("Formulário cancelado"),
		});
	}

	// Função para criar parceiros
	function CreatePartner() {
		AlertDialog.fire({
			title: t("items.new_partner"),
			confirmText: t("actions.save"),
			cancelText: t("actions.cancel"),
			fields: [{ name: "partner", label: t("common.partners"), type: "text", formLabel: t("items.insert_name") }],
			onConfirm: (data) => {
				if (data?.partner) {
					const reg = [
						{
							ativo: 1,
							endereco: "",
							id_conta: sessionStorage.getItem("idConta"),
							id_externo: "",
							id_parceiro: "0",
							nome: data?.partner,
							observacao: "",
							telefone: "",
							uuid: "",
						},
					];

					let option = {
						headers: { "Content-Type": ["application/json"] },
					};

					api
						.post("/parceiro", reg, option)
						.then((response) => {
							setParceiroList((prev) => [...prev, response.data[0]]);
							setMessage({
								type: "success",
								message: t("messages.success_save"),
								title: t("messages.success"),
							});
						})
						.catch((error) => {
							setMessage({
								type: "error",
								title: t("messages.errors.error"),
								message: t("messages.errors.500"),
							});
						});
				}
			},
			onCancel: () => console.log("Formulário cancelado"),
		});
	}

	// Função para criar categoria
	function handleCreateCategory() {
		AlertDialog.fire({
			title: t("items.new_categories"),
			confirmText: t("actions.save"),
			cancelText: t("actions.cancel"),
			fields: [{ name: "input_categoria", label: "Categoria", type: "text", formLabel: t("items.insert_name") }],
			onConfirm: (data) => {
				const { input_categoria } = data;
				if (!input_categoria?.length) {
					setMessage({
						type: "error",
						title: t("messages.errors.error"),
						message: t("messages.errors.missing_field"),
					});
					return;
				}
				if (input_categoria) {
					const reg = [
						{
							ativo: 1,
							descricao: input_categoria,
							ean: "",
							id_categoria: "0",
							id_conta: sessionStorage.getItem("idConta"),
							id_externo: "",
							inf_compl_1: "",
							inf_compl_2: "",
							inf_compl_3: "",
							inf_compl_4: "",
							inf_compl_5: "",
							observacao: "",
						},
					];

					let option = {
						headers: { "Content-Type": ["application/json"] },
					};

					api.post("/categoria", reg, option).then(
						(response) => {
							setCategoriaList((prev) => [...prev, response.data[0]]);
							setMessage({
								type: "success",
								message: t("messages.success_save"),
								title: t("messages.success"),
							});
						},
						(error) => {
							const message = error?.response?.data?.error;
							if (message) {
								if (message.includes("Descrição já existente!")) {
									setMessage({
										type: "error",
										title: t("messages.errors.error"),
										message: t("messages.errors.duplicated"),
									});
									return;
								}
							}
							setMessage({
								type: "error",
								title: t("messages.errors.error"),
								message: t("messages.errors.500"),
							});
						}
					);
				}
			},
			onCancel: () => console.log("Formulário cancelado"),
		});
	}

	// Função para editar label de informação complementar
	function handleInfoLabels(index, name) {
		const targetIndex = index ?? infoDetails.findIndex((info) => !info.show);

		AlertDialog.fire({
			title: `${t("common.info")} ${targetIndex}`,
			confirmText: t("actions.save"),
			cancelText: t("actions.cancel"),
			fields: [
				{
					name: "info_type",
					label: t("items.info_compl.field_type") + ":",
					type: "radio",
					defaultValue: "texto",
					row: true,
					options: [
						{ value: "lista", label: t("items.info_compl.types.select") },
						{ value: "texto", label: t("items.info_compl.types.text") },
					],
				},
				{
					name: "info_name",
					formLabel: t("items.info_compl.field_name"),
					type: "text",
					defaultValue: name || "",
				},
			],
			onConfirm: (data) => {
				const { info_type, info_name } = data;

				// Update the infoDetails array
				const updatedDetails = [...infoDetails];
				updatedDetails[targetIndex] = {
					...updatedDetails[targetIndex],
					show: true,
					label: info_name || updatedDetails[targetIndex].label,
					modo: info_type === "lista" ? "lista" : updatedDetails[targetIndex].modo,
				};

				setInfoDetails(updatedDetails);

				// If no name is provided, skip the API call
				if (!info_name) return;

				setLoading(true);

				// Prepare the update payload
				const updatePayload = {
					_id: config._id,
					id_conta: sessionStorage.getItem("idConta"),
					[`info_compl_${targetIndex + 1}`]: info_name,
				};

				// API call to update the configuration
				api
					.post("/conta_config", [updatePayload], {
						headers: { "Content-Type": "application/json" },
					})
					.then((response) => {
						// Extract and update the labels from the response
						const newLabels = Array.from({ length: 10 }, (_, i) => response.data[0][`info_compl_${i + 1}`]);
						setInfoComplLabels(newLabels);
					})
					.finally(() => {
						setLoading(false);
					});
			},
			onCancel: () => console.log("Formulário cancelado"),
		});
	}

	// Função adicionar info complementar na lista de info complementar
	function handleInfoList(index) {
		function handleSaveInfoCompl(index, newValue) {
			const accountId = sessionStorage.getItem("idConta");
			const updatedInfoComplList = [...infoComplOptions];

			let infoComplItem = updatedInfoComplList.find((item) => item?.destino === `inf_compl_${index + 1}`);

			if (!infoComplItem) {
				// Create a new item if it doesn't exist
				infoComplItem = {
					_id: "0",
					id_conta: accountId,
					label: `Inf. Complementar ${index + 1}`,
					valores: [newValue],
					destino: `inf_compl_${index + 1}`,
				};
			} else {
				// Append the new value to the existing item
				infoComplItem = {
					...infoComplItem,
					valores: [...infoComplItem.valores, newValue],
				};
			}

			// Update the list
			updatedInfoComplList[index] = infoComplItem;

			// Update the state and trigger API call
			setInfoComplOptions(updatedInfoComplList);
			setLoading(true);

			api
				.post(`/select_box`, [infoComplItem])
				.then((response) => {
					console.log("Update successful", response);
				})
				.catch((error) => {
					console.error("Error updating info", error);
				})
				.finally(() => {
					setLoading(false);
				});
		}

		AlertDialog.fire({
			title: `Nova Inf. Complementar ${index + 1}`,
			confirmText: t("actions.continue"),
			cancelText: t("actions.cancel"),
			fields: [{ name: "info_name", formLabel: "Nome do campo (opcional)", type: "text" }],
			onConfirm: (data) => {
				const { info_name } = data;
				if (info_name) {
					handleSaveInfoCompl(index, info_name);
				}
			},
			onCancel: () => console.log("Formulário cancelado"),
		});
	}

	// Função para editar label de niveis de localização
	function handleNivelLabel(index, name) {
		AlertDialog.fire({
			title: `${t("common.level")} ${index}`,
			message: t("settings.change_label_description.nivel"),
			confirmText: t("actions.save"),
			cancelText: t("actions.cancel"),
			fields: [
				{
					name: "info_name",
					formLabel: t("common.description"),
					type: "text",
					defaultValue: name || "",
				},
			],
			onConfirm: (data) => {
				const { info_name } = data;

				setLoading(true);

				const updatePayload = {
					_id: config._id,
					id_conta: sessionStorage.getItem("idConta"),
					[`nivel_${index}`]: info_name || "",
				};

				api
					.post("/conta_config", [updatePayload], {
						headers: { "Content-Type": "application/json" },
					})
					.then((response) => {
						const newLabels = Array.from({ length: 4 }, (_, i) => response.data[0][`nivel_${i + 1}`]);
						setNivelLabels(newLabels);
					})
					.finally(() => {
						setLoading(false);
					});
			},
			onCancel: () => {},
		});
	}

	// Função para criar nivel
	function handleCreateNivel(index, preValue, updateNivelFunction) {
		AlertDialog.fire({
			title: `Novo Nivel ${index}`,
			confirmText: t("actions.continue"),
			cancelText: t("actions.cancel"),
			fields: [
				{ name: "input_nivel", label: t("items.insert_name"), type: "text" },
				{ name: "cor", formLabel: t("common.color"), type: "color" },
			],
			onConfirm: (data) => {
				const { input_nivel, cor } = data;
				if (!input_nivel?.length) {
					setMessage({
						type: "error",
						title: t("messages.errors.error"),
						message: t("messages.errors.missing_field"),
					});
					return;
				}
				if (input_nivel) {
					const reg = {
						ativo: 1,
						descricao: input_nivel,
						id_conta: sessionStorage.getItem("idConta"),
						id_externo: "",
						cor: cor,
						observacao: "",
						tag: "",
					};
					if (index !== 1) {
						if (!reg[`id_nivel_loc${index - 1}`]) {
							return;
						}
						reg[`id_nivel_loc${index - 1}`] = preValue;
					}
					let option = {
						headers: { "Content-Type": ["application/json"] },
					};

					api.post(`/nivel_loc${index}`, [reg], option).then(
						(response) => {
							updateNivelFunction((prev) => [...prev, response.data[0]]);
							setMessage({
								type: "success",
								message: t("messages.success_save"),
								title: t("messages.success"),
							});
						},
						(error) => {
							const message = error?.response?.data;
							if (message) {
								if (message.includes("Nivel com descrição já cadastrado")) {
									setMessage({
										type: "error",
										title: t("messages.errors.error"),
										message: t("messages.errors.duplicated"),
									});
									return;
								}
								console.log("Error: " + message);
							}
							setMessage({
								type: "error",
								title: t("messages.errors.error"),
								message: t("messages.errors.500"),
							});
						}
					);
				}
			},
			onCancel: () => console.log("Formulário cancelado"),
		});
	}

	// # Função para renderizar imagem (principal e complementares)
	const renderImageBox = (image, index, type) => (
		<Box
			component="label"
			role={undefined}
			tabIndex={-1}
			sx={{
				aspectRatio: "1/1", // Garante proporção quadrada
				width: "100%", // Ou qualquer tamanho desejado (pode usar "10rem", "100%", etc.)
				border: 3,
				borderColor: "#fff",
				boxShadow: 5,
				background: image ? `url(${image})` : "",
				backgroundColor: image ? "" : "grey.300",
				backgroundSize: "cover",
				backgroundPosition: "center",
				color: image ? "transparent" : "grey.600",
				display: "flex",
				flexDirection: "column",
				alignItems: "center",
				justifyContent: "center",
				borderRadius: 3,
				position: "relative",
			}}
		>
			{/* Restante do código permanece igual */}
			{!image && (
				<>
					<Icon icon="fluent:camera-add-24-regular" width="32" />
					<Typography textAlign="center" textTransform="capitalize">
						Upload imagem
					</Typography>
				</>
			)}
			{image && (
				<IconButton
					onClick={(e) => handleRemoveFoto(type === "principal" ? image : index, type)}
					sx={{
						position: "absolute",
						top: 5,
						right: 5,
						backgroundColor: "rgba(0,0,0,0.1)",
						boxShadow: 2,
						zIndex: 2,
					}}
				>
					<Icon icon="mingcute:close-fill" width="16" />
				</IconButton>
			)}
			<input
				type="file"
				accept="image/*"
				style={{ display: "none" }}
				onChange={(e) => {
					const files = e.target.files[0];
					handleImageInput(files, type);
				}}
			/>
		</Box>
	);

	// # Função para fazer o upload de imagem
	const renderInputImage = (type) => (
		<Box
			component="label"
			role={undefined}
			variant="standard"
			tabIndex={-1}
			onDragOver={(e) => {
				e.preventDefault();
				e.stopPropagation();
				console.log("Drag Over", e);
			}}
			onDragEnter={(e) => {
				e.preventDefault();
				e.stopPropagation();
				console.log("Drag Enter", e);
			}}
			onDrop={(e) => {
				e.preventDefault();
				e.stopPropagation();
				if (e.dataTransfer && e.dataTransfer.files) {
					console.log("Files dropped:", e.dataTransfer.files);
					handleImageInput(e.dataTransfer.files, type);
				} else {
					console.log("No files found in dataTransfer");
				}
			}}
			sx={{
				border: 1,
				borderStyle: "dashed",
				borderColor: "grey.400",
				borderRadius: 3,
				color: "grey.500",
				display: "flex",
				flexDirection: "column",
				alignItems: "center",
				justifyContent: "center",
				aspectRatio: "1/1", // Garante proporção quadrada
				width: "100%",
				cursor: "pointer",
				":hover": {
					backgroundColor: "grey.200",
				},
			}}
		>
			<Icon icon="mage:plus" width="32" />
			<Typography variant="subtitle" textAlign="center" sx={{ mt: 1 }}>
				Upload <br /> {t(`items.${type === "principal" ? "main_image" : "adicional_image"}`)}
			</Typography>
			<VisuallyHiddenInput
				multiple
				type="file"
				accept="image/*"
				style={{ display: "none" }}
				onChange={(e) => {
					const files = e.target.files;
					handleImageInput(files, type);
				}}
			/>
		</Box>
	);

	// # Função para fazer o upload de imagem
	const renderHelper = (text) => {
		return (
			<>
				<Tooltip
					title={text}
					arrow
					componentsProps={{
						tooltip: {
							sx: {
								backgroundColor: "white",
								color: "text.primary",
								boxShadow: 3,
								zIndex: 1300,
								fontFamily: "Inter, sans-serif",
								fontSize: "0.9rem",
								border: 1,
								borderColor: "grey.300",
								fontWeight: 400,
							},
						},
					}}
				>
					<IconButton sx={{ color: "grey.400", p: 0 }}>
						<Icon icon="mage:question-mark-circle-fill" width={18} />
					</IconButton>
				</Tooltip>
			</>
		);
	};

	// # Função para fazer o upload de imagem
	const renderInfoCompl = (index, value, handleChange = () => {}) => {
		return (
			<Grid2 item size={{ xs: 12, md: 6, lg: 3 }} sx={{ display: infoDetails[index]?.show ? "" : "none" }}>
				<FormControl fullWidth>
					<Stack direction="row" alignItems="center" spacing={1}>
						<Typography>{infoDetails[index]?.label}</Typography>
						<Tooltip title={t("actions.edit")} placement="bottom">
							<IconButton
								onClick={() => {
									handleInfoLabels(index, infoDetails[index]?.label);
								}}
							>
								<Icon width={16} icon="cuida:edit-outline" />
							</IconButton>
						</Tooltip>
					</Stack>
					{infoDetails[index]?.modo == "texto" ? (
						<TextField
							size="small"
							value={value}
							onChange={(e) => {
								handleChange(e.target.value);
							}}
						/>
					) : (
						<>
							<Select
								size="small"
								value={value}
								onChange={(e) => {
									handleChange(e.target.value);
								}}
							>
								{infoComplOptions[index]?.valores?.map((info, index) => (
									<MenuItem key={index} value={info}>
										{info}
									</MenuItem>
								))}
								<MenuItem
									onClick={(e) => {
										handleInfoList(index);
									}}
								>
									<ListItemIcon>
										<Icon icon="mage:plus" />
									</ListItemIcon>
									<ListItemText>{t("actions.new")}</ListItemText>
								</MenuItem>
							</Select>
						</>
					)}
				</FormControl>
			</Grid2>
		);
	};

	// # Função para configurar visualização externa
	function handleExternView() {
		const defaultValues = contaConfig?.campos_visiveis?.length
			? contaConfig?.campos_visiveis
			: [
					"id_categoria",
					"id_marca",
					"id_modelo",
					"observacao",
					"foto_compl",
					"foto",
					"valor",
					"inf_compl_1",
					"inf_compl_2",
				];
		AlertDialog.fire({
			title: "Visualização de Itens",
			message: "Selecione os dados que aparecerão no link de visualização externa",
			confirmText: t("actions.continue"),
			cancelText: t("actions.cancel"),
			fields: [
				{
					type: "multiselect",
					name: "campos_visiveis",
					label: "Campos",
					options: [
						{ value: "id_categoria", label: t("common.categories") },
						{ value: "valor", label: "Valor" },
						{ value: "foto", label: t("items.main_image") },
						{ value: "foto_compl", label: t("items.adicional_image") },
						{ value: "id_marca", label: t("common.brand") },
						{ value: "id_modelo", label: t("common.model") },
						{ value: "ean", label: "Ean" },
						{ value: "observacao", label: t("common.observation") },
						{ value: "inf_compl_1", label: infoDetails[0]?.label },
						{ value: "inf_compl_2", label: infoDetails[1]?.label },
						{ value: "inf_compl_3", label: infoDetails[2]?.label },
						{ value: "inf_compl_4", label: infoDetails[3]?.label },
						{ value: "inf_compl_5", label: infoDetails[4]?.label },
						{ value: "inf_compl_6", label: infoDetails[5]?.label },
						{ value: "inf_compl_7", label: infoDetails[6]?.label },
						{ value: "inf_compl_8", label: infoDetails[7]?.label },
						{ value: "inf_compl_9", label: infoDetails[8]?.label },
						{ value: "inf_compl_10", label: infoDetails[9]?.label },
					],
					defaultValue: defaultValues,
				},
			],
			onConfirm: (res) => {
				const { campos_visiveis } = res;
				const data = [{ ...contaConfig, campos_visiveis: campos_visiveis }];

				const option = {
					headers: { "Content-Type": ["application/json"] },
				};
				api
					.post("/conta_config", data, option)
					.then((response) => {
						const data = response?.data?.find((i) => i?._id);
						setContaConfig(data);
						setMessage({
							type: "success",
							message: t("messages.success_edit"),
							title: t("messages.success"),
						});
					})
					.catch((error) => {
						setMessage({
							type: "error",
							title: t("messages.errors.error"),
							message: t("messages.errors.500"),
						});
					});
			},
			onCancel: () => console.log("Formulário cancelado"),
		});
	}

	return (
		<>
			<Loading show={loading} />

			{message && (
				<Alert
					type={message.type}
					title={message.title}
					message={message.message}
					callout={message?.callout}
					onClose={() => {
						if (message?.function) {
							message.function(); // Executa a função definida
						}
						setMessage(null); // Reseta a mensagem
					}}
				/>
			)}

			<Container maxWidth="xl">
				<Stack spacing={3}>
					{/* Header - Funções*/}
					<Stack direction="row" justifyContent="space-between" alignItems="center">
						<Typography variant="h1">Item</Typography>
						<Stack direction="row" spacing={1}>
							<Button
								variant="outlined"
								color="dark"
								onClick={() => {
									navigate("/items");
								}}
							>
								{t("actions.exit")}
							</Button>
							{IDItem && (
								<Button
									variant="outlined"
									color="dark"
									onClick={() => {
										handleDelete();
									}}
								>
									{t("actions.delete")}
								</Button>
							)}

							<Button
								variant="contained"
								color="primary"
								onClick={() => {
									handleSave();
								}}
							>
								{t("actions.save")}
							</Button>
						</Stack>
					</Stack>

					{IDItem && (
						<Stack direction="row" spacing={1}>
							{/* Ver Mapa*/}
							{latitude && longitude && IDItem && (
								<Button
									variant="outlined"
									color="dark"
									startIcon={<Icon icon="uiw:map" />}
									onClick={() => {
										navigate("/items/map", { state: { mapItems: [itemDetails] } });
									}}
								>
									{t("common.map")}
								</Button>
							)}
							{IDItem && (
								<Button
									variant="outlined"
									color="dark"
									startIcon={<Icon icon="fluent:content-settings-24-regular" />}
									onClick={(e) => navigate("/registers/" + IDItem, { state: { redirect: -1 } })}
								>
									{t("forms.forms")}
								</Button>
							)}
							{IDItem && (
								<Button
									variant="outlined"
									color="dark"
									startIcon={<Icon icon="hugeicons:date-time" />}
									onClick={(e) => navigate("/items/activity/" + tag)}
								>
									{t("common.history")}
								</Button>
							)}
						</Stack>
					)}

					{/* Informações Basicas do item */}
					<Stack as={Paper} sx={{ p: 3 }} spacing={3}>
						<Typography variant="h3">{t("common.basic_info")}</Typography>
						<Grid2 container spacing={3}>
							{/* Tag */}
							<Grid2 item size={{ xs: 12, md: 9 }}>
								<FormControl fullWidth>
									<FormLabel>Tag</FormLabel>
									<TextField
										size="small"
										value={tag}
										onChange={(e) => {
											setTag(e.target.value);
										}}
										onBlur={(e) => {
											setTag(e.target.value.padStart(24, "0"));
										}}
										inputProps={{ maxLength: 24 }}
										helperText={tag && !/^[0-9A-Fa-f]+$/.test(tag) ? t("messages.error_tag_incorrect") : ""}
									/>
								</FormControl>
							</Grid2>

							{/* Status */}
							<Grid2 item size={{ xs: 12, md: 3 }}>
								<FormControl fullWidth>
									<FormLabel>Status</FormLabel>
									<Select
										size="small"
										value={ativo}
										onChange={(e) => {
											setAtivo(e.target.value);
										}}
									>
										<MenuItem value={"0"}>{t("common.inactive")}</MenuItem>
										<MenuItem value={"1"}>{t("common.active")}</MenuItem>
										<MenuItem value={"2"}>{t("common.disposal")}</MenuItem>
										<MenuItem value={"3"}>{t("common.deprecated")}</MenuItem>
									</Select>
								</FormControl>
							</Grid2>

							{/* Categoria */}
							<Grid2 item size={{ xs: 12, md: 6 }}>
								<FormControl fullWidth>
									<FormLabel>{t("common.categories")}</FormLabel>
									<Stack direction="row" spacing={1}>
										<Autocomplete
											fullWidth
											size="small"
											options={categoriaList}
											getOptionLabel={(option) => option.descricao}
											value={categoriaList.find((cat) => cat._id === categoria) || null}
											onChange={(event, newValue) => {
												setCategoria(newValue ? newValue._id : null);
											}}
											renderInput={(params) => <TextField {...params} />}
											noOptionsText={"Não encontrado"}
										/>
										<Button
											variant="contained"
											startIcon={<Icon icon="mage:plus" />}
											sx={{ textWrap: "nowrap", minWidth: "auto" }}
											onClick={() => {
												handleCreateCategory();
											}}
										>
											{t("common.category")}
										</Button>
									</Stack>
								</FormControl>
							</Grid2>

							{/* Marca */}
							<Grid2 item size={{ xs: 12, md: 3 }}>
								<FormControl fullWidth>
									<FormLabel>{t("common.brand")}</FormLabel>
									<Select
										size="small"
										value={marca}
										onChange={(e) => {
											setMarca(e.target.value);
										}}
										renderValue={(selected) => marcaList.find((m) => m._id == selected)?.marca}
									>
										{marcaList.map((marcas) => (
											<MenuItem
												value={marcas?._id}
												onClick={() => {
													handleModelo(marcas?.modelo);
												}}
											>
												<ListItemText>{marcas?.marca}</ListItemText>
												<IconButton
													onClick={(e) => {
														e.stopPropagation();
														handleDeleteMarca(marcas?._id);
													}}
												>
													<Icon icon="mynaui:trash" />
												</IconButton>
											</MenuItem>
										))}
										<MenuItem
											onClick={(e) => {
												handleCreateMarca();
											}}
										>
											<ListItemIcon>
												<Icon icon="mage:plus" />
											</ListItemIcon>
											<ListItemText>{t("actions.new")}</ListItemText>
										</MenuItem>
									</Select>
								</FormControl>
							</Grid2>

							{/* Modelo */}
							<Grid2 item size={{ xs: 12, md: 3 }}>
								<FormControl fullWidth>
									<FormLabel>{t("common.model")}</FormLabel>
									<Select
										size="small"
										value={modelo}
										disabled={!marca}
										onChange={(e) => {
											setModelo(e.target.value);
										}}
										renderValue={(selected) => modeloList.find((m) => m._id == selected)?.descricao}
									>
										{modeloList.map((modelos) => (
											<MenuItem value={modelos?._id}>
												<ListItemText>{modelos?.descricao}</ListItemText>
												<IconButton
													onClick={(e) => {
														e.stopPropagation();
														handleDeleteModelo(modelos?._id);
													}}
												>
													<Icon icon="mynaui:trash" />
												</IconButton>
											</MenuItem>
										))}
										<MenuItem
											onClick={(e) => {
												handleCreateModelo();
											}}
										>
											<ListItemIcon>
												<Icon icon="mage:plus" />
											</ListItemIcon>
											<ListItemText>{t("actions.new")}</ListItemText>
										</MenuItem>
									</Select>
								</FormControl>
							</Grid2>

							{/* Parceiro */}
							<Grid2 item size={{ xs: 12, md: 6 }}>
								<FormControl fullWidth>
									<FormLabel>{t("common.partners")}</FormLabel>
									<Select
										size="small"
										value={parceiro}
										onChange={(e) => {
											setParceiro(e.target.value);
										}}
									>
										{parceiroList.map((parceiros) => (
											<MenuItem value={parceiros._id}>{parceiros.nome}</MenuItem>
										))}
										<MenuItem
											onClick={(e) => {
												CreatePartner();
											}}
										>
											<ListItemIcon>
												<Icon icon="mage:plus" />
											</ListItemIcon>
											<ListItemText>{t("actions.new")}</ListItemText>
										</MenuItem>
									</Select>
								</FormControl>
							</Grid2>

							{/* Valor */}
							<Grid2 item size={{ xs: 12, md: 6 }}>
								<FormControl fullWidth>
									<FormLabel>{t("common.value")}</FormLabel>
									<OutlinedInput
										type="number"
										size="small"
										value={valor}
										onChange={(e) => {
											setValor(e.target.value);
										}}
										endAdornment={
											<InputAdornment position="end" sx={{ color: "grey.600" }}>
												<Icon icon="material-symbols:attach-money-rounded" />
											</InputAdornment>
										}
										inputProps={{
											"aria-label": "weight",
											min: 0,
										}}
									/>
								</FormControl>
							</Grid2>

							{/* Quantidade */}
							<Grid2 item size={12}>
								<FormControl fullWidth>
									<FormLabel>{t("common.quantity")}</FormLabel>
									<TextField
										type="number"
										size="small"
										value={quantidade}
										onChange={(e) => {
											setQuantidade(e.target.value);
										}}
									/>
								</FormControl>
							</Grid2>

							{/* Observação */}
							<Grid2 item size={12}>
								<FormControl fullWidth>
									<FormLabel>{t("common.observation")}</FormLabel>
									<TextField
										size="small"
										value={observacao}
										onChange={(e) => {
											setObservacao(e.target.value);
										}}
										rows={1}
										multiline
									/>
								</FormControl>
							</Grid2>

							{/* Visualização Externa */}
							<Grid2 item size={12}>
								<Stack direction="row" alignItems="center" spacing={1}>
									<Typography variant="h5">{t("items.extern_view")}</Typography>
									<Dropdown iconBtn="mingcute:down-line">
										<MenuItem
											onClick={() => {
												handleExternView();
											}}
										>
											{t("menu.settings")}
										</MenuItem>
										<MenuItem
											disabled={!publico}
											onClick={() => {
												navigator.clipboard.writeText(`https://www.inovacode.app.br/items/extern/${IDItem}`);
												setMessage({
													type: "success",
													message: t("messages.success_copy"),
													title: t("messages.success"),
												});
											}}
										>
											{t("actions.copy")} Link
										</MenuItem>
										<MenuItem
											disabled={!publico}
											onClick={() => {
												!itemDetails?.public
													? handleSave(`/items/extern/${IDItem}`)
													: navigate(`/items/extern/${IDItem}`);
											}}
										>
											{t("actions.view")} Item
										</MenuItem>
									</Dropdown>
								</Stack>
								<FormGroup>
									<Stack direction="row" spacing={1}>
										<FormControlLabel
											control={
												<Checkbox
													defaultChecked
													checked={publico}
													onChange={(e) => {
														setPublico(e.target.checked);
													}}
													inputProps={{ "aria-label": "controlled" }}
												/>
											}
											label={t("items.public_item")}
										/>
										{renderHelper(t("items.helper.public"))}
									</Stack>
								</FormGroup>
							</Grid2>
						</Grid2>
					</Stack>

					{/* Informações Complementares */}
					<Stack as={Paper} sx={{ p: 3 }} spacing={3}>
						<Stack direction="row" justifyContent="space-between">
							<Typography variant="h3">{t("common.info")}</Typography>
							<FormControlLabel
								control={
									<Switch
										checked={showAllInfoCompl}
										onChange={(e) => {
											setShowAllInfoCompl(e.target.checked);
										}}
										inputProps={{ "aria-label": "controlled" }}
									/>
								}
								label="Mostrar Todas"
							/>
						</Stack>
						<Grid2 container spacing={3}>
							{renderInfoCompl(0, infoCompl1, setInfoCompl1)}
							{renderInfoCompl(1, infoCompl2, setInfoCompl2)}
							{renderInfoCompl(2, infoCompl3, setInfoCompl3)}
							{renderInfoCompl(3, infoCompl4, setInfoCompl4)}
							{renderInfoCompl(4, infoCompl5, setInfoCompl5)}
							{renderInfoCompl(5, infoCompl6, setInfoCompl6)}
							{renderInfoCompl(6, infoCompl7, setInfoCompl7)}
							{renderInfoCompl(7, infoCompl8, setInfoCompl8)}
							{renderInfoCompl(8, infoCompl9, setInfoCompl9)}
							{renderInfoCompl(9, infoCompl10, setInfoCompl10)}
						</Grid2>
						<Grid2 item size={12}>
							<Button
								variant="contained"
								startIcon={<Icon icon="mage:plus" />}
								disabled={!infoDetails.find((i) => i.show === false)}
								onClick={() => {
									handleInfoLabels();
								}}
							>
								{t("common.info_label")}
							</Button>
						</Grid2>
					</Stack>

					{/* Localizações */}
					<Stack as={Paper} sx={{ p: 3 }} spacing={3}>
						<Typography variant="h3">{t("common.locate")}</Typography>
						<Grid2 container spacing={3}>
							<Grid2 item size={{ xs: 12, md: "grow" }}>
								<FormControl fullWidth>
									<Stack direction="row" justifyContent="space-between">
										<FormLabel>{nivelLabels[0] || `${t("common.level")} 1`}</FormLabel>
										<Tooltip title={t("actions.edit")} placement="bottom">
											<IconButton
												onClick={() => {
													handleNivelLabel(1, nivelLabels[0]);
												}}
											>
												<Icon width={16} icon="cuida:edit-outline" />
											</IconButton>
										</Tooltip>
									</Stack>
									<Select
										size="small"
										value={nivel1}
										onChange={(e) => {
											setNivel1(e.target.value);
										}}
										renderValue={(selected) => nivel1List.find((m) => m._id == selected)?.descricao}
									>
										{nivel1List.map((nivel) => (
											<MenuItem value={nivel._id}>
												<ListItemText>{nivel.descricao}</ListItemText>
												{nivel?.cor && (
													<ListItemIcon sx={{ color: nivel?.cor }}>
														<Icon icon="mdi:dots-circle" />
													</ListItemIcon>
												)}
											</MenuItem>
										))}
										<MenuItem
											disabled={!nivel1}
											onClick={(e) => {
												handleCreateNivel(1, "", setNivel1List);
											}}
										>
											<ListItemIcon>
												<Icon icon="mage:plus" />
											</ListItemIcon>
											<ListItemText>{t("actions.new")}</ListItemText>
										</MenuItem>
									</Select>
								</FormControl>
							</Grid2>
							<Grid2 item size={{ xs: 12, md: "grow" }}>
								<FormControl fullWidth>
									<Stack direction="row" justifyContent="space-between">
										<FormLabel> {nivelLabels[1] || `${t("common.level")} 2`}</FormLabel>
										<Tooltip title={t("actions.edit")} placement="bottom">
											<IconButton
												onClick={() => {
													handleNivelLabel(2, nivelLabels[1]);
												}}
											>
												<Icon width={16} icon="cuida:edit-outline" />
											</IconButton>
										</Tooltip>
									</Stack>
									<Select
										size="small"
										value={nivel2}
										onChange={(e) => {
											setNivel2(e.target.value);
										}}
										renderValue={(selected) => nivel2List.find((m) => m._id == selected)?.descricao}
									>
										{nivel2List.map((nivel) => (
											<MenuItem value={nivel._id}>
												<ListItemText>{nivel.descricao}</ListItemText>
												{nivel?.cor && (
													<ListItemIcon sx={{ color: nivel?.cor }}>
														<Icon icon="mdi:dots-circle" />
													</ListItemIcon>
												)}
											</MenuItem>
										))}
										<MenuItem
											disabled={!nivel2}
											onClick={(e) => {
												handleCreateNivel(2, nivel1, setNivel2List);
											}}
										>
											<ListItemIcon>
												<Icon icon="mage:plus" />
											</ListItemIcon>
											<ListItemText>{t("actions.new")}</ListItemText>
										</MenuItem>
									</Select>
								</FormControl>
							</Grid2>
							<Grid2 item size={{ xs: 12, md: "grow" }}>
								<FormControl fullWidth>
									<Stack direction="row" justifyContent="space-between">
										<FormLabel> {nivelLabels[2] || `${t("common.level")} 3`}</FormLabel>
										<Tooltip title={t("actions.edit")} placement="bottom">
											<IconButton
												onClick={() => {
													handleNivelLabel(3, nivelLabels[2]);
												}}
											>
												<Icon width={16} icon="cuida:edit-outline" />
											</IconButton>
										</Tooltip>
									</Stack>
									<Select
										size="small"
										value={nivel3}
										onChange={(e) => {
											setNivel3(e.target.value);
										}}
										renderValue={(selected) => nivel3List.find((m) => m._id == selected)?.descricao}
									>
										{nivel3List.map((nivel) => (
											<MenuItem value={nivel._id}>
												<ListItemText>{nivel.descricao}</ListItemText>
												{nivel?.cor && (
													<ListItemIcon sx={{ color: nivel?.cor }}>
														<Icon icon="mdi:dots-circle" />
													</ListItemIcon>
												)}
											</MenuItem>
										))}
										<MenuItem
											disabled={!nivel2}
											onClick={(e) => {
												handleCreateNivel(3, nivel2, setNivel3List);
											}}
										>
											<ListItemIcon>
												<Icon icon="mage:plus" />
											</ListItemIcon>
											<ListItemText>{t("actions.new")}</ListItemText>
										</MenuItem>
									</Select>
								</FormControl>
							</Grid2>
							<Grid2 item size={{ xs: 12, md: "grow" }}>
								<FormControl fullWidth>
									<Stack direction="row" justifyContent="space-between">
										<FormLabel> {nivelLabels[3] || `${t("common.level")} 4`}</FormLabel>
										<Tooltip title={t("actions.edit")} placement="bottom">
											<IconButton
												onClick={() => {
													handleNivelLabel(4, nivelLabels[3]);
												}}
											>
												<Icon width={16} icon="cuida:edit-outline" />
											</IconButton>
										</Tooltip>
									</Stack>
									<Select
										size="small"
										value={nivel4}
										onChange={(e) => {
											setNivel4(e.target.value);
										}}
										renderValue={(selected) => nivel4List.find((m) => m._id == selected)?.descricao}
									>
										{nivel4List.map((nivel) => (
											<MenuItem value={nivel._id}>
												<ListItemText>{nivel.descricao}</ListItemText>
												{nivel?.cor && (
													<ListItemIcon color={nivel?.cor}>
														<Icon icon="mdi:dots-circle" />
													</ListItemIcon>
												)}
											</MenuItem>
										))}
										<MenuItem
											disabled={!nivel3}
											onClick={() => {
												handleCreateNivel(4, nivel3, setNivel4List);
											}}
										>
											<ListItemIcon>
												<Icon icon="mage:plus" />
											</ListItemIcon>
											<ListItemText>{t("actions.new")}</ListItemText>
										</MenuItem>
									</Select>
								</FormControl>
							</Grid2>
						</Grid2>
						<Grid2 container spacing={3}>
							<Grid2 item size={{ xs: 12, md: 3 }}>
								<FormControl fullWidth>
									<FormLabel>{t("common.latitude")}</FormLabel>
									<TextField
										size="small"
										value={latitude}
										onChange={(e) => {
											setLatitude(e.target.value);
										}}
									/>
								</FormControl>
							</Grid2>
							<Grid2 item size={{ xs: 12, md: 3 }}>
								<FormControl fullWidth>
									<FormLabel>{t("common.longitude")}</FormLabel>
									<TextField
										size="small"
										value={longitude}
										onChange={(e) => {
											setLongitude(e.target.value);
										}}
									/>
								</FormControl>
							</Grid2>
							<Grid2 item size={{ xs: 12, md: 3 }}>
								<FormControl fullWidth>
									<FormLabel>{t("common.radius")}</FormLabel>
									<TextField
										size="small"
										value={radius}
										onChange={(e) => {
											setRadius(e.target.value);
										}}
									/>
								</FormControl>
							</Grid2>
						</Grid2>
					</Stack>

					{/* Imagens - imagem principal e imagens complementares */}
					<Stack as={Paper} sx={{ p: 3 }} spacing={3}>
						<Stack direction="row" alignItems={"center"} spacing={1}>
							<Typography variant="h3">{t("common.images")}</Typography>
							{renderHelper(t("items.helper.images"))}
						</Stack>

						<Stack spacing={1}>
							<FormLabel>{t("items.main_image")}</FormLabel>
							<Grid2 container>
								<Grid2 size={{ xs: 12, md: 2 }}>
									{fotoPrincipal ? renderImageBox(fotoPrincipal, null, "principal") : renderInputImage("principal")}
								</Grid2>
							</Grid2>
						</Stack>
						<Stack spacing={1}>
							<FormLabel>{t("items.adicional_image")}</FormLabel>
							<Grid2 container spacing={1}>
								<Grid2 size={{ xs: 6, md: 2 }}>{renderInputImage("complementar")}</Grid2>
								{fotosComplementares.map((foto, index) => (
									<Grid2 size={{ xs: 6, md: 2 }} key={index}>
										{renderImageBox(foto, index, "complementar")}
									</Grid2>
								))}
							</Grid2>
						</Stack>
					</Stack>

					{/* UP */}
					<Stack as={Paper} sx={{ p: 3 }} spacing={3}>
						<Typography variant="h3">UP</Typography>
						<Grid2 container spacing={3}>
							<Grid2 item size={{ xs: 12, md: 3 }}>
								<FormControl fullWidth>
									<FormLabel>UP 1</FormLabel>
									<TextField
										size="small"
										value={UP1}
										onChange={(e) => {
											setUP1(e.target.value);
										}}
									/>
								</FormControl>
							</Grid2>
							<Grid2 item size={{ xs: 12, md: 3 }}>
								<FormControl fullWidth>
									<FormLabel>UP 2</FormLabel>
									<TextField
										size="small"
										value={UP2}
										onChange={(e) => {
											setUP2(e.target.value);
										}}
									/>
								</FormControl>
							</Grid2>
							<Grid2 item size={{ xs: 12, md: 3 }}>
								<FormControl fullWidth>
									<FormLabel>UP 3</FormLabel>
									<TextField
										size="small"
										value={UP3}
										onChange={(e) => {
											setUP3(e.target.value);
										}}
									/>
								</FormControl>
							</Grid2>
							<Grid2 item size={{ xs: 12, md: 3 }}>
								<FormControl fullWidth>
									<FormLabel>UP 4</FormLabel>
									<TextField
										size="small"
										value={UP4}
										onChange={(e) => {
											setUP4(e.target.value);
										}}
									/>
								</FormControl>
							</Grid2>
							<Grid2 item size={{ xs: 12, md: 3 }}>
								<FormControl fullWidth>
									<FormLabel>UP 5</FormLabel>
									<TextField
										size="small"
										value={UP5}
										onChange={(e) => {
											setUP5(e.target.value);
										}}
									/>
								</FormControl>
							</Grid2>
						</Grid2>
					</Stack>

					{/* Informações adicionais */}
					<Stack as={Paper} sx={{ p: 3 }} spacing={3}>
						<Typography variant="h3">Mais Informações</Typography>
						<Grid2 container spacing={3}>
							<Grid2 item size={12}>
								<FormControl fullWidth>
									<FormLabel>{t("common.extern_code")}</FormLabel>
									<TextField
										size="small"
										value={IDExterno}
										onChange={(e) => {
											setIDExterno(e.target.value);
										}}
									/>
								</FormControl>
							</Grid2>
							<Grid2 item size={12}>
								<FormControl fullWidth>
									<FormLabel>{t("common.user")}</FormLabel>
									<TextField disabled size="small" value={id_usuario?.nome} />
								</FormControl>
							</Grid2>
							<Grid2 item size={{ xs: 12, md: 6 }}>
								<FormControl fullWidth>
									<FormLabel>{t("common.created_at")}</FormLabel>
									<TextField
										disabled
										size="small"
										value={dt_criacao ? dayjs(dt_criacao).format("DD/MM/YYYY HH:mm") : ""}
									/>
								</FormControl>
							</Grid2>
							<Grid2 item size={{ xs: 12, md: 6 }}>
								<FormControl fullWidth>
									<FormLabel>{t("common.edited_at")}</FormLabel>
									<TextField
										disabled
										size="small"
										value={dt_alteracao ? dayjs(dt_alteracao).format("DD/MM/YYYY HH:mm") : ""}
									/>
								</FormControl>
							</Grid2>
						</Grid2>
					</Stack>
				</Stack>
			</Container>
		</>
	);
}
